import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { emailWithBgIcon_path, phoneWithBgIcon_path } from 'assets/index';
import { HeadingStyled, NavStyled, ThemeModal } from 'styles/style';
import DetailModal from '../archive/details_modal';
import NotesCanvas from '../archive/notes_modal';
import TimeModal from '../archive/time_modal';

const OrdersNavBar = () => {
  const location = useLocation();
  const [showNotes, setShowNotes] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showPeakTimeModal, setShowPeakTimeModal] = useState(false);

  const handleCloseSupportModal = () => setShowSupportModal(false);

  const handleCloseDetailModal = () => setShowDetailModal(false);

  const handleCloseNotes = () => setShowNotes(false);
  const handleClosePeakTimeModal = () => setShowPeakTimeModal(false);

  return (
    <>
      <NavStyled style={{ paddingBottom: '0px', marginTop: '0px' }}>
        <div className="d-flex">
          <Link
            to="/orders/current-orders"
            className={`nav-link-btn ${
              location.pathname === '/orders/current-orders' ? 'active' : ''
            }`}
          >
            Current Orders
          </Link>
          <Link
            to="/orders/orders-archive"
            className={`nav-link-btn ${
              location.pathname === '/orders/orders-archive' ? 'active' : ''
            }`}
          >
            Orders Archive
          </Link>
        </div>
      </NavStyled>

      <Modal show={showSupportModal} onHide={handleCloseSupportModal} centered>
        <ThemeModal>
          <Modal.Header closeButton>
            <Modal.Title>Customer Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text">
              If you have questions that require our intervention, please
              contact us by mail or phone. Every day until 8 p.m.
            </div>
            <div style={{ margin: '25px 0 0 0' }} />
            <Row>
              <Col xs={6}>
                <div className="d-flex">
                  <img src={emailWithBgIcon_path} alt="icon" />
                  <div>
                    <HeadingStyled
                      className="secondary-font"
                      style={{
                        fontSize: '13px',
                        fontWeight: '800',
                        color: '#002733',
                        margin: '0 0 4px 12px',
                      }}
                    >
                      EMAIL
                    </HeadingStyled>
                    <HeadingStyled
                      className="secondary-font"
                      style={{
                        fontSize: '13px',
                        fontWeight: '500',
                        color: '#4C6870',
                        margin: '0 0 4px 12px',
                      }}
                    >
                      customer.suppor@silal.com
                    </HeadingStyled>
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex">
                  <img src={phoneWithBgIcon_path} alt="icon" />
                  <div>
                    <HeadingStyled
                      className="secondary-font"
                      style={{
                        fontSize: '13px',
                        fontWeight: '800',
                        color: '#002733',
                        margin: '0 0 4px 12px',
                      }}
                    >
                      CONTACT NUMBER
                    </HeadingStyled>
                    <HeadingStyled
                      className="secondary-font"
                      style={{
                        fontSize: '13px',
                        fontWeight: '500',
                        color: '#4C6870',
                        margin: '0 0 4px 12px',
                      }}
                    >
                      (202) 398 0202
                    </HeadingStyled>
                    <HeadingStyled
                      className="secondary-font"
                      style={{
                        fontSize: '13px',
                        fontWeight: '500',
                        color: '#4C6870',
                        margin: '0 0 4px 12px',
                      }}
                    >
                      (202) 398 0202
                    </HeadingStyled>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </ThemeModal>
      </Modal>
      <NotesCanvas handleCloseNotes={handleCloseNotes} showNotes={showNotes} />
      <DetailModal
        handleCloseDetailModal={handleCloseDetailModal}
        showDetailModal={showDetailModal}
      />
      <TimeModal
        handleClosePeakTimeModal={handleClosePeakTimeModal}
        showPeakTimeModal={showPeakTimeModal}
      />
    </>
  );
};

export { OrdersNavBar };
