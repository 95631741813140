import { NavLink } from 'react-router-dom';
import { CategoryStyled } from './index.styled';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import ShowImage from 'silal_app_base_react/src/components/show_image';

type CategoryProps = {
  categoryName: string;
  sub_categories_length?: number; // only for subcategories folders
  items_length: number;
  className?: string;
  link: string;
  state?: any;
  listingType?: boolean;
};

const CategoryComponent = ({
  categoryName,
  sub_categories_length,
  items_length,
  className,
  link,
  state,
  listingType,
}: CategoryProps) => {
  return (
    <CategoryStyled className={className}>
      <NavLink to={link} state={state} />
      <h1>{categoryName}</h1>
      <div className="d-flex justify-content-between">
        <p>{items_length} Items</p>
        {listingType && <p>{sub_categories_length} Categories</p>}
      </div>
    </CategoryStyled>
  );
};

const CategoryComponentWithImage = ({
  categoryName,
  link,
  items_length,
  category_image,
}: {
  categoryName: string;
  link: string;
  items_length: number;
  category_image: ImageType;
}) => {
  return (
    <CategoryStyled>
      <div className="d-flex justify-content-between">
        <div className="flex flex-col align-middle self-center">
          <NavLink to={link} />
          <h1>{categoryName}</h1>
          <p>{items_length} Items</p>
        </div>
        <ShowImage
          image={category_image}
          style={{
            width: '100px',
            height: '100px',
          }}
        />
      </div>
    </CategoryStyled>
  );
};

export { CategoryComponent, CategoryComponentWithImage };
