import { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from 'silal_app_base_react/src/components/spinner';
import {
  DEFAULT_PACKAGE_HEIGHT,
  DEFAULT_PACKAGE_LENGTH,
  DEFAULT_PACKAGE_WEIGHT,
  DEFAULT_PACKAGE_WIDTH,
  determinePackageType,
} from 'core/constants/shipping';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import {
  Package,
  TrackingData,
} from 'silal_app_base_react/src/data/types/shipping';
import OrdersRepository from 'data/repositories/orders_repository';
import { toast } from 'react-toastify';
import { PackageEditingModal } from 'silal_app_base_react/src/components/package_editing_modal';

type BarcodeTrackingModalProps = {
  text: string;
  orderDetails: OrderDetails;
  data: TrackingData[];
  setData: any;
  setStep: (step: number) => void;
  buttonStyle?: any;
};
function BarcodeTrackingModal({
  text,
  orderDetails,
  data,
  setData,
  setStep,
  buttonStyle,
}: BarcodeTrackingModalProps) {
  const [open, setOpen] = useState(false);

  const addPackage = useCallback(
    (pkg: TrackingData) => {
      setData(
        data.map((pkg) => {
          pkg.selected = false;
          return pkg;
        }),
      );
      setData((prevData: TrackingData[]) => [
        ...prevData,
        {
          id: pkg.id ?? prevData.length + 1,
          tracking_code: pkg.tracking_code ?? '',
          weight: 0,
          selected: true,
          package: { ...pkg },
        },
      ]);
    },
    [data, setData],
  );

  useEffect(() => {
    if (orderDetails?.shipments?.length > 0) {
      const newPackages = orderDetails.shipments[0].packages.map(
        (item, index) => {
          const packageType = determinePackageType(
            item.width,
            item.height,
            item.length,
          );
          const pkg = {
            id: index,
            name: packageType.name,
            width: item.width,
            height: item.height,
            length: item.length,
            image: packageType.image,
            tracking_code: item.tracking_code,
            weight: item.weight,
            selected: false,
          };
          return pkg;
        },
      );
      setData(newPackages);
      data.map((item) => addPackage(item));
    }
  }, [orderDetails]);

  const submit = async (packages: Package[]) => {
    // Process the submitted data (if needed) and then proceed to the next step
    if (!packages || packages.length === 0) {
      toast.warn('Please add at least one package.');
      return false;
    }
    if (packages.filter((item) => item.tracking_code === '').length > 0) {
      toast.warn('Please enter tracking code for every package.');
      return false;
    }

    await OrdersRepository.markOrderAsReadyByID(
      orderDetails.store_id,
      orderDetails.id,
      packages.map((item) => {
        return {
          tracking_code: item.tracking_code,
          weight: item.weight || DEFAULT_PACKAGE_WEIGHT,
          height: item.height || DEFAULT_PACKAGE_HEIGHT,
          width: item.width || DEFAULT_PACKAGE_WIDTH,
          length: item.length || DEFAULT_PACKAGE_LENGTH,
        } as Package;
      }),
    ).then((res) => {
      if (!res) return false;
      setStep(2);
    });
  };

  return orderDetails === undefined ? (
    <Spinner />
  ) : (
    <>
      <Button
        style={buttonStyle ?? { width: '100%' }}
        onClick={() => setOpen(true)}
      >
        {text}
      </Button>{' '}
      <PackageEditingModal
        open={open}
        headerTitle={`Connect tracking barcodes to order #${orderDetails.id}`}
        onButtonOneClick={() => {
          setOpen(false);
          return;
        }}
        bodyText="To make it easier for couriers to identitfy the order and for you, use the Labels ID paper we sent you to label this order with a BARCODE so you can easily track it in the future. To add a package enter the label ID manually below or press the camera icon to scan. To add a package enter the label ID manually below or press the camera icon to scan"
        buttonOneText="Cancel"
        onButtonTwoClick={async (packages: Package[]) => {
          const x = await submit(packages);
          if (x) {
            setOpen(false);
            return true;
          }
          return false;
        }}
        buttonTwoText="Submit"
        orderDetails={orderDetails}
      />
    </>
  );
}

export default BarcodeTrackingModal;
