import React from 'react';

export default function LargeLetter({ active, width, height }) {
  return (
    <svg
      width={width || '35'}
      height={height || '40'}
      viewBox="0 0 35 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11.4285V5.71416C7.00003 4.7111 7.3235 3.72572 7.93789 2.85706C8.55228 1.9884 9.43595 1.26705 10.5001 0.765536C11.5642 0.264018 12.7713 -6.37049e-06 14 1.15284e-10C15.2288 6.37072e-06 16.4359 0.264043 17.5 0.765572C18.5641 0.264043 19.7712 6.37072e-06 21 1.15284e-10C22.2287 -6.37049e-06 23.4358 0.264018 24.4999 0.765536C25.5641 1.26705 26.4477 1.9884 27.0621 2.85706C27.6765 3.72572 28 4.7111 28 5.71416V11.4285H29.75C31.1424 11.4285 32.4777 11.88 33.4623 12.6837C34.4469 13.4875 35 14.5776 35 15.7142V34.2857C35 35.8012 34.2625 37.2547 32.9497 38.3263C31.637 39.398 29.8565 40 28 40H7C5.14348 40 3.36301 39.398 2.05025 38.3263C0.737498 37.2547 0 35.8012 0 34.2857V15.7142C0 14.5776 0.553124 13.4875 1.53769 12.6837C2.52226 11.88 3.85761 11.4285 5.25 11.4285H7ZM10.5 5.71416V11.4285H17.5V5.71416C17.5 4.9564 17.1313 4.22967 16.4749 3.69385C15.8185 3.15803 14.9283 2.85701 14 2.85701C13.0717 2.85701 12.1815 3.15803 11.5251 3.69385C10.8687 4.22967 10.5 4.9564 10.5 5.71416ZM28 37.1428C28.9283 37.1428 29.8185 36.8418 30.4749 36.306C31.1313 35.7702 31.5 35.0435 31.5 34.2857V15.7142C31.5 15.3353 31.3156 14.972 30.9874 14.704C30.6593 14.4361 30.2141 14.2856 29.75 14.2856H24.5V34.2857C24.5 35.0435 24.8687 35.7702 25.5251 36.306C26.1815 36.8418 27.0717 37.1428 28 37.1428ZM21 14.2856H5.25C4.78587 14.2856 4.34075 14.4361 4.01256 14.704C3.68437 14.972 3.5 15.3353 3.5 15.7142V34.2857C3.5 35.0435 3.86875 35.7702 4.52513 36.306C5.1815 36.8418 6.07174 37.1428 7 37.1428H21.938C21.322 36.2747 20.9984 35.289 21 34.2857V14.2856ZM21 5.71416V11.4285H24.5V5.71416C24.5004 5.27793 24.3784 4.84741 24.1434 4.45562C23.9085 4.06382 23.5667 3.72116 23.1443 3.45389C22.722 3.18662 22.2303 3.00185 21.7069 2.91374C21.1836 2.82563 20.6424 2.83652 20.125 2.94558C20.685 3.76558 21 4.7113 21 5.71416Z"
        fill={`${active ? '#05AE4B' : '#E8E8E8'}`}
      />
    </svg>
  );
}
