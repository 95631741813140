import {
  StoreDetails,
  StoreRequest,
} from 'silal_app_base_react/src/data/types/stores';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import {
  API_BASE,
  SELLER_REQUEST_TYPES,
} from 'silal_app_base_react/src/config/constants';
import { SellerWorkforce } from 'silal_app_base_react/src/data/types/users';
import { STORES_MESSAGES } from 'data/constants/messages';
class StoreApiRepository extends AuthenticatedApiRepository {
  private static instance: StoreApiRepository | null = null;
  private constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): StoreApiRepository {
    if (!StoreApiRepository.instance) {
      StoreApiRepository.instance = new StoreApiRepository(axiosInstance);
    }
    return StoreApiRepository.instance;
  }

  getStoreDetailsByID = async (store_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/stores/${store_id}?detailed=True`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.store as StoreDetails;
    } catch (error: Error | any) {
      return false;
    }
  };

  getFullStoreRequest = async (store_id: number, request_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/stores/${store_id}/requests/${request_id}`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      const resObj = {
        success: true,
        data: res?.request.store,
      };
      return resObj;
    } catch (error) {
      return false;
    }
  };

  getStoreRequest = async (store_id: number, status: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/requests?page=1&per_page=1000&status=${status}`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      const mngmnt_request = res.data.results.filter(
        (request: StoreRequest) =>
          request.request_type ===
          SELLER_REQUEST_TYPES.seller_management_add_store,
      );
      return mngmnt_request.response.msg;
    } catch (error) {
      return false;
    }
  };

  getManagedStores = async () => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/managed_stores/`),

        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      return res.data.stores as StoreDetails[];
    } catch (error: Error | any) {
      return false;
    }
  };

  listSellersWithAccessToStore = async (store_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/stores/${store_id}/access/`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.sellers as SellerWorkforce[];
    } catch (e: any) {
      return false;
    }
  };

  giveAccessToSeller = async (
    store_id: number,
    seller_id: number,
    access_level: number,
    email?: string,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/stores/${store_id}/access/${seller_id}/`, {
          baseApiUrl: API_BASE,
          data: {
            access_level,
            email,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: STORES_MESSAGES.MerchantAccessGranted,
        },
      );
      return res.data.status as string;
    } catch (e: any) {
      return false;
    }
  };

  revokeAccessToSeller = async (store_id: number, seller_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/stores/${store_id}/access/${seller_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: STORES_MESSAGES.MerchantAccessRevoked,
        },
      );
      return res.data.status as string;
    } catch (e: any) {
      return false;
    }
  };

  deleteStoreRequest = async (store_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/stores/${store_id}`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: STORES_MESSAGES.StoreDeleted,
        },
      );
      return res;
    } catch (error: Error | any) {
      return false;
    }
  };

  createStore = async (data: any) => {
    try {
      const parsedData = {
        // Bank info are strings in the form, so they're parsed into integers to match backend
        ...data,
        bank_number: parseInt(data.bank_number),
        bank_branch_number: parseInt(data.bank_branch_number),
        bank_account_number: parseInt(data.bank_account_number),
      };
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/stores/`, {
          baseApiUrl: API_BASE,
          data: parsedData,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: STORES_MESSAGES.StoreCreated,
        },
      );

      return res.data;
    } catch (error: Error | any) {
      return false;
    }
  };

  updateStore = async (store_id: number, data: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/stores/${store_id}`, {
          baseApiUrl: API_BASE,
          data: data,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: STORES_MESSAGES.StoreUpdated,
        },
      );

      return res.data;
    } catch (e: any) {
      return false;
    }
  };
}

const StoreRepository = StoreApiRepository.getInstance(sellerAxiosInstance);
export default StoreRepository;
