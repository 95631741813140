export const CREATE_LISTING = 'CREATE_LISTING';

export const UPDATE_STOCK_CREATE_LISTING = 'UPDATE_STOCK_CREATE_LISTING';

export const LOGOUT_LISTINGS = 'LOGOUT_LISTINGS';

export const actionCreateListing = (payload: any) => ({
  type: CREATE_LISTING,
  payload,
});

export const actionUpdateListingStockAndPrice = (payload: any) => ({
  type: UPDATE_STOCK_CREATE_LISTING,
  payload,
});

export const actionLogoutListings = () => ({
  type: LOGOUT_LISTINGS,
});
