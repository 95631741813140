import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ModalStyle, SellerIdStyle } from 'styles/style';
import FormInput from '../../auth/screens/signup/components/form_input';
import {
  MerchantPermissionTypes,
  MerchantPermissionTypesMap,
} from 'silal_app_base_react/src/data/types/users';
import { Colors } from 'silal_app_base_react/src/config/theme';
import StoreRepository from 'data/repositories/store_repository';
import { useSelector } from 'react-redux';
import { getCurrentStore } from 'core/hooks/use_selector';

function SellerId({ setWorkForceChanged }: any) {
  // eslint-disable-next-line no-undef
  const { id } = useSelector(getCurrentStore);
  const [grantAccess, setGrantAccess] = useState(false);
  const [sellerId, setSellerId] = useState('');
  const [permissionType, setPermissionType] = useState(0);
  const handleShowAccess = () => setGrantAccess(true);
  const handleCloseAccessPopup = () => setGrantAccess(false);

  async function grantAccessHandler() {
    await StoreRepository.giveAccessToSeller(
      id,
      parseInt(sellerId),
      permissionType,
    ).then((res) => {
      if (!res) return;
      handleCloseAccessPopup();
      setWorkForceChanged(true);
    });
  }

  return (
    <SellerIdStyle>
      <div>
        <h4>Merchant ID:</h4>
        <Col lg={6}>
          <FormInput
            // className="input"
            label={''}
            type={'text'}
            placeholder={'#4983247'}
            value={sellerId}
            onChange={(e: any) => setSellerId(e.target.value)}
          />
          <p className="text">
            Merchant ID is available directly after registering through Silal or
            on your profile, or in the screen where you choose the business to
            manage
          </p>
        </Col>

        <Row className="row d-flex">
          <Col lg={6}>
            <div className="d-flex">
              <h5>Assign Worker as</h5>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value={
                  MerchantPermissionTypesMap[MerchantPermissionTypes.ADMIN]
                }
                checked={permissionType === MerchantPermissionTypes.ADMIN}
                onChange={() =>
                  setPermissionType(MerchantPermissionTypes.ADMIN)
                }
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                {MerchantPermissionTypesMap[MerchantPermissionTypes.ADMIN]}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value={
                  MerchantPermissionTypesMap[
                    MerchantPermissionTypes.MAINTAINANCE
                  ]
                }
                checked={
                  permissionType === MerchantPermissionTypes.MAINTAINANCE
                }
                onChange={() =>
                  setPermissionType(MerchantPermissionTypes.MAINTAINANCE)
                }
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                {
                  MerchantPermissionTypesMap[
                    MerchantPermissionTypes.MAINTAINANCE
                  ]
                }
              </label>
            </div>
          </Col>
          <Col
            lg={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              className="btn2"
              disabled={!sellerId}
              onClick={() => {
                handleShowAccess();
              }}
            >
              Grant Access
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        className="d-flex justify-content-center"
        show={grantAccess}
        onHide={handleCloseAccessPopup}
        centered
      >
        <ModalStyle>
          <Modal.Header closeButton />
          <Modal.Body className="text-center p-4 ">
            <h3>Granting Access</h3>
            <h6>
              Merchant #{sellerId} as{' '}
              {MerchantPermissionTypesMap[permissionType]}
            </h6>
            <div className="text">
              Granting others user access is a way to allow them to help you
              manage your store, access given can be of 2 types, Admins or
              Maintainers:
            </div>
            <div className="admins">
              Admins can: do everything
              <br />
            </div>
            <div className="mainteners">
              Maintainers can: accept orders, update stock, change store profile
              info.
              <br />
              Maintainers cant’: update prices, delete account, update store
              accounting files.
            </div>
            <div className="card">
              <p className="cardText">
                <span className="colored"> Note from Silal: </span>Please make
                sure that the entered merchant ID is the exact one, remember,
                you can revoke access anytime from this page.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex w-100 ">
              <Col lg={12} className="ps-2">
                <Button
                  className="btn2 w-100"
                  onClick={grantAccessHandler}
                  style={{
                    backgroundColor: `${Colors.tomatoRedLight}`,
                    color: `${Colors.tomatoRed}`,
                  }}
                >
                  Grant Access
                </Button>
                <Button className="btn2 w-100" onClick={handleCloseAccessPopup}>
                  No, later
                </Button>
              </Col>
            </div>
          </Modal.Footer>
        </ModalStyle>
      </Modal>
    </SellerIdStyle>
  );
}

export default SellerId;
