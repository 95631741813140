import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Wrapper from './no_business_available_screen.styled';
import { logoutIcon_path, notFoundGhostIllustration_path } from 'assets/index';
import { fullLogout } from 'routes/functions/routes_functions';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { Button } from '@nextui-org/react';

function NoBusinessAvailableSplash() {
  const { user } = useSelector(getCurrentUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    fullLogout(dispatch, navigate);
  };

  return (
    <>
      <Wrapper>
        <div className="d-flex justify-content-end">
          <button className="m-3 logout-btn" onClick={handleLogout}>
            <img className="p-1" src={logoutIcon_path} alt="logout-btn" />
            Logout
          </button>
        </div>
        <div className=" d-flex justify-content-center">
          <div className="col-lg-4 text-center">
            <img
              src={notFoundGhostIllustration_path}
              alt="logo"
              style={{
                width: '300px',
                margin: 'auto',
              }}
            />{' '}
            {/* // TODO add illustration */}
            <div className="content">
              <h6>No businesses were found</h6>
              <p>
                You don’t have any businesses, either create your own or share
                your
                <span className="separate"> Merchant ID </span>
                with a business owner to be added to their business
              </p>
            </div>
            <p className="text">
              Your Merchant ID for invites is:
              <span className="separate"> #{user.id}</span>
            </p>
            <div>
              <Button
                onClick={() => navigate('/questionaire-splash')}
                color="success"
                className="w-full mt-4 h-11"
              >
                Create my own business
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default NoBusinessAvailableSplash;
