import {
  DateTimeFormatDateOnly,
  DateTimeFormat,
  DateTimeFormatHoursMinutesOnly,
} from 'silal_app_base_react/src/config/constants';
import { ThemeAccordion } from 'styles/style';
import { DetailText, DetailTextStyled } from 'components/show_full_elements';

import moment from 'moment-timezone';
import { Accordion, Spinner } from 'react-bootstrap';
import { OrderItemBriefCard } from 'silal_app_base_react/src/pages/orders_archive/components/archived_orders_components';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import {
  OrderPackageQualities,
  OrderTimeline,
} from 'silal_app_base_react/src/pages/orders_archive/components/shared_components';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { useEffect, useState } from 'react';
import OrdersRepository from 'data/repositories/orders_repository';
import { useSelector } from 'react-redux';
import { getCurrentStore } from 'core/hooks/use_selector';

export const OrderDetailsModal = ({
  orderId,
  openListingDetailsFunction,
}: {
  orderId: number;
  openListingDetailsFunction: (listinId: number) => void;
}) => {
  const [orderDetail, setOrderDetail] = useState<OrderDetails | undefined>();
  const currentStore = useSelector(getCurrentStore);

  useEffect(() => {
    getOrderDetails();
  }, [orderId]);

  const getOrderDetails = async () => {
    await OrdersRepository.getOrderDetailsByID({
      order_id: orderId,
      store_id: currentStore.id,
    }).then((res: any) => {
      if (!res) return;
      setOrderDetail(res as OrderDetails);
    });
  };
  function getFromattedShipmentTime(orderDetails: OrderDetails) {
    const shiftAssigned = orderDetails?.shipments[0]?.last_mile_shift;
    if (!shiftAssigned) return 'Not assigned yet';

    return `${moment(
      orderDetails?.shipments[0]?.last_mile_shift.start_datetime,
    ).format(DateTimeFormatHoursMinutesOnly)} - ${moment(
      orderDetails?.shipments[0]?.last_mile_shift.end_datetime,
    ).format(DateTimeFormatHoursMinutesOnly)} on ${moment(
      orderDetails?.shipments[0]?.last_mile_shift.start_datetime,
    ).format(DateTimeFormatDateOnly)}`;
  }
  return !orderDetail ? (
    <Spinner />
  ) : (
    <ThemeAccordion>
      <Accordion alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>customer data</Accordion.Header>
          <Accordion.Body>
            <DetailText title="Customer ID" detail={orderDetail?.customer_id} />
            <DetailText
              title="Address"
              detail={
                orderDetail?.destination_address?.address_line_1 +
                ' ' +
                orderDetail?.destination_address?.town?.name_en +
                ' , ' +
                orderDetail?.destination_address?.city?.name_en +
                '.'
              }
            />
            {/* TODO localize */}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Delivery Data</Accordion.Header>
          <Accordion.Body>
            <DetailText
              title="Shift ID"
              detail={'#' + orderDetail?.last_mile_shift_id}
            />
            <DetailText
              title="Last Mile Shift"
              detail={getFromattedShipmentTime(orderDetail)}
            />
            <DetailTextStyled>
              <div className="row w-100">
                <div className="col-md-4">
                  <span className="title">{'Package Qualities'}</span>
                </div>
                {OrderPackageQualities(orderDetail)}
              </div>
            </DetailTextStyled>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>order details</Accordion.Header>
          <Accordion.Body>
            <DetailText
              title="Order created"
              detail={moment(orderDetail?.placing_date).format(DateTimeFormat)}
            />
            <DetailText
              title="Total"
              detail={formatPrice(orderDetail.customer_pays)}
            />
            {/* <DetailText
              title="Order notes"
              detail={`${orderDetail?.destination_address?.notes || ''}`}
            />  // TODO reconnect if needed for orders*/}
            <hr />
            <p className="order-items-title">Order timeline</p>

            <OrderTimeline orderActions={orderDetail!.order_actions} />
            <p className="order-items-title">
              Order Items ({orderDetail?.items?.length || 0})
            </p>
            {orderDetail?.items?.map((item) => {
              return (
                <OrderItemBriefCard
                  item={item}
                  key={item.id}
                  openListingDetailsFunction={openListingDetailsFunction}
                />
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </ThemeAccordion>
  );
};
