import { useEffect, useState } from 'react';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { useNavigate } from 'react-router-dom';
import { Fonts, Colors } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { Input, Label } from 'styles/style';
import Backbutton from 'pages/auth/components/back_button';
import SellerRepository from 'data/repositories/seller_repository';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { fullLogout } from 'routes/functions/routes_functions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { actionDeleteEmail } from 'store/user/user_actions';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { BackgroundContainer } from 'pages/auth/components/background_container';

function SetupEmailScreen() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { user } = useSelector(getCurrentUserState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !user.verified_email) dispatch(actionDeleteEmail());
  }, [location]);

  const handleAddEmail = async () => {
    if (!email) {
      return setError('Please enter your email.');
    }

    if (!FormValidators.isEmail(email)) {
      return setError('Incorrect Email address.');
    }

    toast.info('Sending confirmation email...');

    await SellerRepository.addEmail(email).then(async (addedEmail: boolean) => {
      if (addedEmail) {
        await AuthenticationRepository.loginSendEmailOTP(email).then((res) => {
          if (!res || res.http_status_code === 429) {
            toast.warning(res.msg);
          }
          toast.success(res.msg);
          navigate('/email-otp-verification', {
            state: {
              previousPage: 'signUpEmail',
              email: email,
              expiration_date: res.expiration_date,
            },
          });
        });
      }
    });
  };

  // const handleSkip = () => {
  //   navigate('/seller-info');
  // };

  return (
    <BackgroundContainer>
      <Wrapper>
        <div className="go-back-button">
          <Backbutton
            primaryIcon={true}
            customBackFunction={() => fullLogout(dispatch, navigate)}
          />
        </div>
        <div className="text-center">
          <div className="top-row">
            <div className="title">
              <h1>Sign Up</h1>
            </div>
            <Button className="btn2">Email</Button>
            <div className="form">
              <Label className="label">Email</Label>
              <Input
                type={'text'}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={'example@example.com'}
                style={{
                  height: '50px',
                  border: `1px solid ${Colors.grey} `,
                }}
              />
            </div>
          </div>

          <div>
            {error !== '' ? <span className="text-danger">{error}</span> : null}
          </div>

          <div className="button-wrapper">
            <div>
              {/* <Button onClick={handleSkip} className="skip-btn skip">
                Skip
              </Button> */}
              <Button
                onClick={handleAddEmail}
                className="send-confirmation-btn next"
              >
                Send Confirmation Email
              </Button>
            </div>

            <div>
              <h6>
                By signing up, you agree to the{' '}
                <span className="colored">Terms of Service</span> and
                <span className="colored"> Privacy Policy </span>
              </h6>
            </div>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default SetupEmailScreen;

const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: auto;
  .button-wrapper {
    margin-top: 100px;
  }
  .top-row {
    .title {
      padding: 25px 0;
      h1 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      .label {
        font-family: ${Fonts.secondary};
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 130%;
        color: #4c7061;
      }
      .form-control-mobile {
        margin-top: 20px;
        label {
          font-size: 15px;
          font-weight: 700;
          color: #4c6870;
        }
        .row {
          border: 1px solid;
          border-radius: 10px;
          margin-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .col1 {
            max-width: 130px;
            width: 100%;
            .value-container {
              border-right: none;
              border-radius: 5px 0px 0px 5px;
            }
          }
          .col2 {
            width: 100%;
            input {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }
      }

      /* email form design  */
      .form-control-email {
        .row {
          margin-top: 20px;
          label {
            font-size: 15px;
            font-weight: 700;
            color: #4c6870;
          }
        }
      }
    }
  }

  .btn2 {
    background: ${Colors.greenMain};
    pointer-events: none;
    color: ${Colors.white};
    border: none;
    margin: 5px;
    height: 45px;
    width: 60%;
  }
  .skip-btn {
    background: ${Colors.greenSecondary};
    color: ${Colors.greenMain};
    border: none;
    margin: 5px;
    height: 45px;
    width: 100%;
  }
  .send-confirmation-btn {
    background: ${Colors.greenMain};
    color: ${Colors.white};
    border: none;
    margin: 5px;
    height: 45px;
    width: 100%;
  }
  h6 {
    margin: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #4c7061;
  }
  .colored {
    font-weight: 500;
    color: ${Colors.greenMain};
  }
`;
