import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import { plusIcon_path } from 'assets';
import { HeadingStyled } from 'styles/style';
import {
  AddNoteButton,
  CheckNote,
} from '../components/full_order_components.styled';
// import { NotesDataMock } from 'data/mock/notes.data';
import { backArrowIcon_path } from 'assets/index';
import Button from 'silal_app_base_react/src/components/buttons/index';

const NotesCanvas = ({ handleCloseNotes, showNotes }) => {
  const [addNote, setAddNote] = useState(false);
  const [noteDetails, setNoteDetails] = useState(false);

  // const noteDetail = () => {
  //   setNoteDetails(true);
  // };
  const notesBack = () => {
    setNoteDetails(false);
    setAddNote(false);
  };

  return (
    <Offcanvas show={showNotes} onHide={handleCloseNotes} placement="end">
      <Offcanvas.Header closeButton>
        <div className="d-flex align-items-center">
          {addNote || noteDetails ? (
            <div style={{ margin: '0 14px 0 0' }}>
              <img src={backArrowIcon_path} onClick={notesBack} />
            </div>
          ) : null}
          <Offcanvas.Title>Notes</Offcanvas.Title>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="position-relative">
        {addNote ? (
          <div className="h-100 d-flex flex-column justify-content-between">
            <div>
              <HeadingStyled
                style={{ fontFamily: Fonts.secondary }}
                color="#05AE4B"
                size="25px"
                weight="500"
                margin="0 0 25px 0"
              >
                21 November 2021
              </HeadingStyled>
              <CheckNote>
                <div className="checkbox-main">
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <textarea className="note" spellCheck="false" autoFocus />
              </CheckNote>
            </div>
            <Button
              className="w-100 save-note-hover"
              color="#05AE4B"
              bg="rgba(90, 179, 168, 0.2)"
              onClick={() => setAddNote(false)}
            >
              Save note
            </Button>
          </div>
        ) : (
          <>
            {noteDetails ? (
              <div className="h-100 d-flex flex-column justify-content-between">
                <div>
                  <HeadingStyled
                    style={{ fontFamily: Fonts.secondary }}
                    color="#05AE4B"
                    size="25px"
                    weight="500"
                    margin="0 0 25px 0"
                  >
                    21 November 2021
                  </HeadingStyled>
                  <CheckNote>
                    <div className="checkbox-main">
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <HeadingStyled
                      style={{ fontFamily: Fonts.secondary }}
                      color="#002733"
                      size="15px"
                      weight="400"
                      margin="0 0 8px 0"
                    >
                      Conduct an inventory of ingredients
                    </HeadingStyled>
                  </CheckNote>
                  <CheckNote>
                    <div className="checkbox-main">
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <HeadingStyled
                      style={{ fontFamily: Fonts.secondary }}
                      color="#002733"
                      size="15px"
                      weight="400"
                      margin="0 0 8px 0"
                    >
                      Conduct an inventory of ingredients
                    </HeadingStyled>
                  </CheckNote>
                </div>
                <Button
                  className="w-100 save-note-hover"
                  color="#05AE4B"
                  bg="rgba(90, 179, 168, 0.2)"
                  onClick={() => setNoteDetails(false)}
                >
                  Save note
                </Button>
              </div>
            ) : (
              <>
                <div>
                  {/* {NotesDataMock.map((note, index) => ({
                     <Notes
                      noteDetail={noteDetail}
                      key={index}
                      date={note.date}
                      note={note.note}
                    /> 
                  }))} */}
                </div>
                <AddNoteButton
                  className="center"
                  onClick={() => setAddNote(true)}
                >
                  <img src={plusIcon_path} />
                </AddNoteButton>
              </>
            )}
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NotesCanvas;
