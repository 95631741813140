import { PermissionTypes } from 'silal_app_base_react/src/data/types/users';
import {
  dashboardIcon_path,
  imageLightIcon_path,
  offersIcon_path,
  ordersIcon_path,
  productsIcon_path,
  profileIcon_path,
  settingsIcon_path,
  arrowDownIcon_path,
} from 'assets/index';

export type MerchantAvailableRoutes = {
  dashboard: boolean;
  orders: boolean;
  products: boolean;
  profile: boolean;
  create_listing: boolean;
  image_library: boolean;
  settings: boolean;
};

export function getRoutingPermissionsMap(): MerchantAvailableRoutes {
  const admin = true;
  const maintainer = true;

  // The global permissions for the sidebar using the flags above
  const sidebarPermissions = {
    dashboard: true,
    orders: true,
    products: true,
    profile: true,
    create_listing: true,
    image_library: true,
    settings: true,
  };
  return sidebarPermissions;
}

export const getAppropriateSidebar = () => {
  const routingPermissions = getRoutingPermissionsMap();

  const menu = [
    routingPermissions.dashboard && {
      id: '01',
      icon: dashboardIcon_path,
      title: 'Dashboard',
      path: '/',
    },
    routingPermissions.orders && {
      id: '02',
      icon: ordersIcon_path,
      title: 'Orders',
      path: '/orders/current-orders',
    },
    routingPermissions.products && {
      id: '03',
      icon: productsIcon_path,
      title: 'Products',
      path: '/products',
    },
    routingPermissions.create_listing && {
      id: '04',
      icon: offersIcon_path,
      title: 'Create Listing',
      path: '/products/templates',
    },
    routingPermissions.profile && {
      id: '05',
      icon: profileIcon_path,
      title: 'Profile',
      path: '/profile',
    },
    routingPermissions.image_library && {
      id: '06',
      icon: imageLightIcon_path,
      title: 'Image Library',
      path: '/image-library',
    },
    routingPermissions.settings && {
      id: '07',
      icon: settingsIcon_path,
      title: 'Settings',
      path: '/settings',
    },
  ];

  return menu.filter((item) => item !== false);
};
