import { messsageIcon_path } from '../../assets';

const notificationsData = [
  {
    icon: messsageIcon_path,
    clr: 'blue',
    status: 'new',
    title: 'Silal Management',
    descp: 'The refund on the order #247hw9 has been completed.',
    time: '10m',
  },
  {
    icon: messsageIcon_path,
    clr: 'blue',
    status: 'new',
    title: 'Silal Management',
    descp: 'The refund on the order #247hw9 has been completed.',
    time: '10m',
  },
  {
    icon: messsageIcon_path,
    clr: 'blue',
    status: 'new',
    title: 'Silal Management',
    descp: 'The refund on the order #247hw9 has been completed.',
    time: '10m',
  },
];

export { notificationsData };
