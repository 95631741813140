import { Route, Routes } from 'react-router-dom';
import Form from 'pages/auth/screens/signup/questionaire_form_screen';
import SubmitRequests from 'pages/auth/screens/signup/components/submit_requests';
import QuestionaireSplash from 'pages/auth/screens/signup/components/form_splash';
import ChooseBusinessScreen from 'pages/auth/screens/choose_store/choose_business_screen';
import NoBusinessAvailableSplashScreen from 'pages/auth/screens/no_business_available/no_business_available_screen';
// import SignUpPassword from 'pages/auth/screens/signup/setup_password_form_screen';
import { Page404, NotFoundPage } from 'pages/404/page_404_screen';
import { AuhtneticationPagesHandler } from 'routes/handlers/authentication_pages_handler';

const SemiauthenticatedViewRouter = () => {
  return (
    <AuhtneticationPagesHandler>
      <Routes>
        {/* Choose Store */}
        <Route path="/">
          <Route index element={<ChooseBusinessScreen />} />
          <Route path="choose-business" element={<ChooseBusinessScreen />} />
        </Route>

        {/* <Route path="/setup-password" element={<SignUpPassword />} /> */}

        {/* Questionnaire Form */}
        <Route path="/questionaire-form" element={<Form />} />

        {/* Submit Questionnaire Form */}
        <Route path="/submit-questionaire-form" element={<SubmitRequests />} />

        {/* Questionnaire Splash */}
        <Route path="/questionaire-splash" element={<QuestionaireSplash />} />

        {/* No Business Available */}
        <Route
          path="/new-business"
          element={<NoBusinessAvailableSplashScreen />}
        />

        {/* 404 */}
        <Route path="/404" element={<Page404 time={3} />} />
        <Route path="*" element={<NotFoundPage path="choose-business" />} />
      </Routes>
    </AuhtneticationPagesHandler>
  );
};

export default SemiauthenticatedViewRouter;
