import { ListingContextProvider } from 'silal_app_base_react/src/pages/create_listings/functions/data_formatting_functions';
import CreateItem from 'silal_app_base_react/src/pages/create_listings/flows/create_item_flow';
import { useSelector } from 'react-redux';
import { actionCreateListing } from 'store/products/products_actions';
import { actionGetStoreImages } from 'store/images/images_actions';
import { getCurrentStore } from 'core/hooks/use_selector';
import { S3ApiRepository } from 'silal_app_base_react/src/data/repositories/s3_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { SellerFilesRepository } from 'data/repositories/files_repository';

export default function CreateListing() {
  const currentStore = useSelector(getCurrentStore);
  return (
    <ListingContextProvider>
      <CreateItem
        isManagement={false}
        currentStore={currentStore}
        actionCreateListing={actionCreateListing}
        actionGetStoreImages={actionGetStoreImages}
        s3Repository={S3ApiRepository.getInstance(
          sellerAxiosInstance,
          currentStore.id,
        )} // TODO move to a global context
        filesRepository={SellerFilesRepository}
      />
    </ListingContextProvider>
  );
}
