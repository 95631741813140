import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from 'components/sidebar';
import TopNav from 'pages/profile/components/profile_navbar';

import { actionGetStoreImages } from 'store/images/images_actions';

import { SellerFilesRepository } from 'data/repositories/files_repository';
import transactionsRepository from 'data/repositories/transactions_repository';
import { S3ApiRepository } from 'silal_app_base_react/src/data/repositories/s3_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';

import { Page404, NotFoundPage } from 'pages/404/page_404_screen';
import {
  getCurrentOrdersState,
  getCurrentStore,
} from 'core/hooks/use_selector';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';

import HomeScreen from 'pages/home/screens/home_screen';
import ReviewsScreen from 'pages/home/screens/reviews_screen';
import OutOfStockScreen from 'pages/home/screens/out_of_stock_screen';

import AboutUsPage from 'pages/about_us/about_us_page';
import ContactUsPage from 'pages/contact_us/contact_us_page';
import DocumentsPage from 'pages/documents/documents_page';

import CurrentOrder from 'pages/orders/screens/current_orders_screen';
import OrderDetails from 'pages/orders/screens/full_order_screen';
import { OrdersArchive } from 'silal_app_base_react/src/pages/orders_archive/archived_orders_screen';

import ProductsTemplates from 'pages/products/screens/templates_screen';
import Products from 'pages/products/products_page';
import SubcategoryScreen from 'pages/products/screens/subcategory_screen';
import ProductsSubmitted from 'pages/products/screens/submitted_products_screen';

import OpeningHours from 'pages/profile/screens/opening_hours_screen';
import ProfilePage from 'pages/profile/profile_page';

import SettingsPage from 'pages/settings/settings_page';
import CreateNewItem from 'pages/listing/index';
import ImageLibraryPage from 'silal_app_base_react/src/pages/image_library/image_library_page';
// import ClientsDatabasePage from 'pages/clients/clients_database_page';
// import PreOrders from 'pages/orders/archive/pre_orders_screen';

import ListingDetails from 'silal_app_base_react/src/pages/listing_profile/listing_profile_page';
import { AuthenticatedPagesHandler } from 'routes/handlers/authenticated_pages_handler';
import { LedgerPage } from 'silal_app_base_react/src/pages/ledger/ledger_screen';
import { LedgerHistory } from 'pages/profile/archive/ledger_screen';
import { Suspense, useEffect, useState } from 'react';
import { OrdersNavBar } from 'pages/orders/components/navbar';
import { OrderDetailsModal } from 'pages/orders/modals/order_details_modal';
import OrdersRepository from 'data/repositories/orders_repository';
import { Transaction } from 'silal_app_base_react/src/data/types/transactions';
import ListingsRepository from 'data/repositories/listings_repository';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { Content, GlobalStyle } from 'styles/main_content';
import { RootState } from 'data/types/state';
import { actionGetAllOrders } from 'store/orders/orders_actions';

const AuthenticatedViewRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionGetCategoriesMinimalList());
  }, []);
  return (
    <AuthenticatedPagesHandler>
      <GlobalStyle />
      <Content>
        <DisplaySidebar />
        <DisplayContent />
      </Content>
    </AuthenticatedPagesHandler>
  );
};

const DisplaySidebar = () => {
  return <Sidebar />;
};

const DisplayContent = () => {
  return (
    <main>
      <Routes>
        {/* Home/Main */}
        <Route path="/">
          <Route index element={<HomeScreen />} />
          <Route path="reviews" element={<ReviewsScreen />} />
          <Route path="out-of-stock" element={<OutOfStockScreen />} />
        </Route>

        {/* Image Library */}
        <Route path="/image-library" element={<ImageLibraryPageWrapper />} />

        {/* Orders */}
        <Route
          path="/orders"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          }
        >
          <Route
            path="current-orders"
            element={
              <>
                <OrdersNavBar />
                <CurrentOrder />
              </>
            }
          />
          <Route
            path="orders-archive"
            element={
              <>
                <OrdersNavBar />
                <OrdersArchivePageWrapper />
              </>
            }
          />
          <Route path="order-details/:orderId" element={<OrderDetails />} />
        </Route>

        {/* <Route path="pre-order" element={<PreOrders />} /> */}

        {/* Clients */}
        {/* <Route path="/clients" element={<ClientsDatabasePage />} /> */}

        {/* Create Listing */}
        <Route path="/create-new-item" element={<CreateNewItem />} />

        {/* Products */}
        <Route path="/products">
          <Route index element={<Products />} />
          <Route path="templates" element={<ProductsTemplates />} />
          <Route path="submitted" element={<ProductsSubmitted />} />
          <Route path=":categoryId">
            <Route path="subcategory" element={<SubcategoryScreen />} />
            <Route path="templates" element={<ProductsTemplates />} />
            <Route path="submitted" element={<ProductsSubmitted />} />
          </Route>
          <Route
            path="listing-details/:listingId"
            element={<ListingPageWrapper />}
          />
        </Route>

        {/* Profile */}
        <Route path="/profile">
          <Route element={<ProfilePage />} index />
          <Route path="opening-hours" element={<OpeningHours />} />
          <Route
            path="transactions"
            element={<TransactionsPageWrapper TopNav={TopNav()} />}
          />
          <Route path="ledger" element={<LedgerHistory />} />
        </Route>

        {/* Other */}
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/documents" element={<DocumentsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />

        {/* 404 */}
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </main>
  );
};

const ImageLibraryPageWrapper = () => {
  const currentStoreId: number = useSelector(getCurrentStore).id;
  return (
    <ImageLibraryPage
      actionGetStoreImages={actionGetStoreImages}
      store_id={currentStoreId.toString()}
      filesRepository={SellerFilesRepository}
      s3Repository={S3ApiRepository.getInstance(
        sellerAxiosInstance,
        currentStoreId,
      )} // TODO move to a global context or remove the note as it's a singelton
      is_private={false}
    />
  );
};

const OrdersArchivePageWrapper = () => {
  const { orders } = useSelector(getCurrentOrdersState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openOrderDetailsFunction = async (orderId: number) => {
    navigate(`/orders/order-details/${orderId}`);
  };
  const openListingDetailsFunction = async (listingId: number) => {
    navigate(`/products/listing-details/${listingId}`);
  };

  return (
    <OrdersArchive
      orders={orders}
      openOrderDetailsFunction={openOrderDetailsFunction}
      openListingDetailsFunction={openListingDetailsFunction}
      ordersRepository={OrdersRepository}
      SideModal={OrderDetailsModal}
      isManagement={false}
      refreshOrders={() => {
        dispatch(actionGetAllOrders([]));
      }}
    />
  );
};

const TransactionsPageWrapper = ({ TopNav }: { TopNav: JSX.Element }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { id } = useSelector(getCurrentStore);
  useEffect(() => {
    const fetchTransactions = async () => {
      transactionsRepository.getStoreLedger(id.toString()).then((res) => {
        if (!res) return;
        setTransactions(res);
      });
    };
    fetchTransactions();
  }, []);

  return (
    <LedgerPage
      TopNav={TopNav}
      transactions={transactions}
      isSellerTransactions={true}
    />
  );
};

const ListingPageWrapper = () => {
  const { id } = useSelector(getCurrentStore);
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );
  return (
    <ListingDetails
      store_id={id}
      actionUpdateListingStockAndPrice={actionUpdateListingStockAndPrice}
      ListingsRepository={ListingsRepository}
      categoryNamesMap={minimalCategoriesList?.reduce((acc: any, category) => {
        acc[category.id] = category.name_en + ' | ' + category.name_ar;
        return acc;
      }, {})}
    />
  );
};

export default AuthenticatedViewRouter;
