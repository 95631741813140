import { Layout } from 'antd';
import {
  getCurrentUserState,
  getCurrentStoreState,
} from 'core/hooks/use_selector';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fullLogout } from 'routes/functions/routes_functions';
import {
  actionGetManagedStores,
  actionGetStoreInfoByID,
} from 'store/store/store_actions';
import { actionGetSetUserProfile } from 'store/user/user_actions';

type IntermediateViewsHandlerProps = {
  children: React.ReactNode;
};

export function AuthenticatedPagesHandler({
  children,
}: IntermediateViewsHandlerProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userState = useSelector(getCurrentUserState);
  const { user, loading } = userState;
  const { currentStore } = useSelector(getCurrentStoreState);

  useEffect(() => {
    const payload = {
      success: () => {},
      failure: (error: any) => {
        // TODO fix any
        if (error?.code === 401) {
          fullLogout(dispatch, navigate);
        }
      },
    };
    if (!loading) {
      dispatch(actionGetSetUserProfile(payload));
      dispatch(
        actionGetManagedStores({
          success: () => {
            if (currentStore?.id) {
              const store_payload = {
                data: {
                  store_id: currentStore.id,
                },
              };
              dispatch(actionGetStoreInfoByID(store_payload));
            }
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    // if (
    //   !currentStore.logo_img ||
    //   !currentStore.cover_image ||
    //   !currentStore.business_bank_statement ||
    //   !currentStore.business_owner_image
    // ) {
    //   navigate('/profile');
    // } else {
    navigate(location.pathname);
    // }
    return;
  }, [user, currentStore]);

  return loading ? (
    <Spinner />
  ) : (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {children}
    </Layout>
  );
}
