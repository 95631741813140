import { all, fork } from '@redux-saga/core/effects';
import * as CategoriesBase from 'silal_app_base_react/src/store/categories/categories_saga';
import * as images from './images/images_saga';
import * as Images from 'silal_app_base_react/src/store/images/image_library_saga';
import * as orders from './orders/orders_saga';
import * as product from './products/products_saga';
import * as store from './store/store_saga';
import * as user from './user/user_saga';

export default function* rootSaga() {
  yield all([
    // Categories
    fork(CategoriesBase.sagaGetCategoriesMinimalList),

    // User
    fork(user.sagaSignupPhoneVerifyOtp),
    fork(user.sagaSignupEmailVerifyOtp),
    fork(user.sagaDeleteEmail),
    fork(user.sagaLoginEmailPassword),
    fork(user.sagaLoginOrVerifyEmailOtp),
    fork(user.sagaLoginOrVerifyPhoneOtp),
    fork(user.sagaGetSetUserProfile),

    // Store
    fork(store.sagaUpdateStore),
    fork(store.sagaGetManagedStores),
    fork(store.sagaGetStoreInfoByID),

    // Products
    fork(product.sagaCreateListing),
    fork(product.sagaUpdateListingStockAndPrice),

    // Images
    fork(Images.sagaGetSilalImages),
    fork(images.sagaGetStoreImages),

    //Orders
    fork(orders.sagaGetAllOrders),
  ]);
}
