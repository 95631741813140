import { Col, Row } from 'react-bootstrap';
import { HeadingStyled, WorkStyle } from 'styles/style';
import SellerId from '../components/invite_workforce';
import SellerWorkforce from '../components/seller_workers';
import { SellerWorkforce as SellerWorkforceType } from 'silal_app_base_react/src/data/types/users';
import { backArrowIcon_path } from 'assets/index';
import { useEffect, useState } from 'react';
import StoreRepository from 'data/repositories/store_repository';
import { getCurrentStore } from 'core/hooks/use_selector';
import { useSelector } from 'react-redux';

type InviteWorkForceProps = {
  back: (value: boolean) => void;
};

const InviteWorkForce = ({ back }: InviteWorkForceProps) => {
  const { id } = useSelector(getCurrentStore);

  const [sellersWorkforce, setSellersWorkforce] = useState<
    SellerWorkforceType[]
  >([]);
  const [workForceChanged, setWorkForceChanged] = useState(false);

  useEffect(() => {
    const fetchSellersWorkforce = async () => {
      await StoreRepository.listSellersWithAccessToStore(id).then((res) => {
        if (res) {
          setSellersWorkforce(res);
        }
      });
    };
    setWorkForceChanged(false);
    fetchSellersWorkforce();
  }, [id, workForceChanged]);
  return (
    <WorkStyle>
      <>
        <div className="d-flex mb-3">
          <button
            type="button"
            className="back-arrow"
            onClick={() => back(false)}
          >
            <img src={backArrowIcon_path} />
          </button>
          <HeadingStyled
            className="primary-font mb-0"
            style={{
              fontSize: '19px',
              fontWeight: '700',
            }}
          >
            Add new workforce
          </HeadingStyled>
        </div>

        <Row className="row">
          <Col lg={8} className="left">
            <SellerId setWorkForceChanged={setWorkForceChanged} />
          </Col>
          <Col lg={4} className="right">
            <h4>Current workforce</h4>
            {sellersWorkforce &&
              sellersWorkforce.map((sellerWorkforce) => (
                <SellerWorkforce
                  key={sellerWorkforce.seller_id}
                  worker={sellerWorkforce}
                  setWorkForceChanged={setWorkForceChanged}
                />
              ))}
          </Col>
        </Row>
      </>
    </WorkStyle>
  );
};

export default InviteWorkForce;
