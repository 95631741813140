import Button from 'silal_app_base_react/src/components/buttons/index';
import styled from 'styled-components';

type FormFooterProps = {
  // setState: (value: number) => void;
  currentStep: number;
  onClickNext: () => void;
  onClickBack: () => void;
};

function FormFooter({
  currentStep,
  onClickNext,
  onClickBack,
}: FormFooterProps) {
  return (
    <FooterStyle>
      <div className="d-flex justify-content-center">
        <div className="d-flex col-lg-8 justify-content-end">
          {/* <div className="col-lg-6 justify-content-end"> */}
          <div className="butns">
            <Button className="btn1 disable" onClick={onClickBack}>
              Back
            </Button>

            <Button onClick={onClickNext} className="btn2">
              {currentStep === 3 ? 'Submit' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </FooterStyle>
  );
}

export default FormFooter;

const FooterStyle = styled.div`
  .butns {
    float: right;
    // margin-right: 15px;
    margin: 25px 0px;
  }
  .btn1 {
    background: #ccd4d6;
    color: #4c7061;
    border: none;
    margin: 5px;
    padding: 13px 49px;
  }
  .btn2 {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    padding: 13px 79px;
  }
`;
