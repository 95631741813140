import { useState } from 'react';
import styled from 'styled-components';
import FormControl from 'silal_app_base_react/src/components/form_control';
import FormInput from './form_input';
import Error from 'silal_app_base_react/src/components/error';
import CountrySelectForm from 'components/select_country_form';
import FormFooter from './form_footer';
import { StoreSignUpForm } from 'data/types/store';
import { CurrentContries } from 'core/constants/constants';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { Country } from 'data/types/general';
import { FILL_SIGNUP_FORM, OVERRIDE_FOR_DEBUG } from 'core/debug/debug';
import { SIGNUP_FORM_DEBUG_DATA } from 'core/debug/signup_form.data';
import { toast } from 'react-toastify';
import { Radio } from 'styles/style';
import { CURRENT_VAT } from 'silal_app_base_react/src/config/constants';
import { BusinessTaxType } from 'silal_app_base_react/src/data/types/stores';
import { Form3Styled } from './form3';
import { Colors } from 'silal_app_base_react/src/config/theme';

type Form1Props = {
  step: number;
  setCurrentStep: (step: number) => void;
  setModalState: (state: boolean) => void;
  handleUserInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: StoreSignUpForm;
  setSelectedCountry: (country: Country) => void;
};

function Form1({
  step,
  setCurrentStep,
  setModalState,
  handleUserInput,
  formData,
  setSelectedCountry,
}: Form1Props) {
  const [errors, setErrors] = useState({
    name_en: '',
    name_ar: '',
    business_owner_full_name: '',
    countryCode: '',
    business_phone: '',
    business_email: '',
    business_website_url: '',
    business_social_media_url: '',
    tax_id: '',
    tax_type: '',
    bank_number: '',
    bank_branch_number: '',
    bank_account_number: '',
  });

  const hasValidationErrors = () => {
    const errorsFields = {
      name_en: '',
      name_ar: '',
      business_owner_full_name: '',
      countryCode: '972',
      business_phone: '',
      business_email: '',
      business_website_url: '',
      business_social_media_url: '',
      tax_id: '',
      tax_type: '',
      bank_number: '',
      bank_branch_number: '',
      bank_account_number: '',
    };
    let error = false;

    Object.keys(formData).map(function (keyName) {
      const value =
        OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
          ? (SIGNUP_FORM_DEBUG_DATA as any)[keyName]
          : formData[keyName as keyof StoreSignUpForm];

      if (keyName === 'name_en') {
        if (!value) {
          errorsFields.name_en = 'ⓘ Business name is required.';
          error = true;
        }
        if (
          !FormValidators.isEnglish(value as string) ||
          (value as string).length > 64
        ) {
          errorsFields.name_en =
            'ⓘ Business name must be in English and less than 64 charachters.';

          error = true;
        }
      } else if (keyName === 'name_ar') {
        if (
          !FormValidators.isArabic(value as string) ||
          (value as string).length > 64
        ) {
          errorsFields.name_ar =
            'ⓘ Business name in Arabic must be in Arabic and less than 64 charachters.';
          error = true;
        }
        if (!value) {
          errorsFields.name_ar = 'ⓘ Business name in Arabic is required.';
          error = true;
        }
      } else if (keyName === 'business_owner_full_name') {
        if (!value) {
          errorsFields.business_owner_full_name =
            'ⓘ Business owner name is required.';
        }

        if ((value as string).length > 32) {
          errorsFields.business_owner_full_name =
            'ⓘ Business owner name must be less then 32 characters.';

          error = true;
        }
      } else if (keyName === 'business_phone') {
        if (!value) {
          errorsFields.business_phone = 'ⓘ Phone number is required.';
          error = true;
        }
        if (!FormValidators.isPhoneNumber(value as string)) {
          errorsFields.business_phone =
            'ⓘ Phone number must be 9-10 digits, e.g: 0523456789 or 027654321';

          error = true;
        }
      } else if (keyName === 'business_email') {
        if (!value) {
          errorsFields.business_email = 'ⓘ Business email is required.';
          error = true;
        } else if (!FormValidators.isEmail(value as string)) {
          errorsFields.business_email =
            'ⓘ Email is Incorrect, e.g: example@example.com';
          error = true;
        }
      } else if (keyName === 'tax_id') {
        if (!value) {
          errorsFields.tax_id = 'ⓘ Business tax-ID is required.';
          error = true;
        } else if (!FormValidators.isValidTaxID(value as string)) {
          errorsFields.tax_id =
            'ⓘ Business tax-ID must be 9 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'tax_type') {
        if (!value) {
          errorsFields.tax_type = 'ⓘ Business tax type is required.';
          error = true;
        }
      } else if (keyName === 'bank_account_number') {
        if (!value) {
          errorsFields.bank_account_number =
            'ⓘ Business bank account number is required.';
          error = true;
        } else if (
          !FormValidators.isValidBankAccountNumber(value?.toString() ?? '')
        ) {
          errorsFields.bank_account_number =
            'ⓘ Business bank account number must be 5-7 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'bank_branch_number') {
        if (!value) {
          errorsFields.bank_branch_number =
            'ⓘ Business bank branch number is required.';
          error = true;
        } else if (
          !FormValidators.isValidBankBranchNumber(value?.toString() ?? '')
        ) {
          errorsFields.bank_branch_number =
            'ⓘ Business bank branch number must be 3 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'bank_number') {
        if (!value) {
          errorsFields.bank_number = 'ⓘ Business bank number is required.';
          error = true;
        } else if (!FormValidators.isValidBankNumber(value?.toString() ?? '')) {
          errorsFields.bank_number =
            'ⓘ Business bank number must be 2 characters and using numbers 0-9.';
          error = true;
        }
      } else if (keyName === 'business_website_url') {
        if ((value as string)?.trim().length > 0) {
          if (!FormValidators.isURL(value as string)) {
            errorsFields.business_website_url =
              'ⓘ Business social-media/website link should be valid URL.';
            error = true;
          }
        }
      } else if (keyName === 'business_social_media_url') {
        if ((value as string)?.trim().length > 0) {
          if (!FormValidators.isURL(value as string)) {
            errorsFields.business_social_media_url =
              'ⓘ Business social-media/website link should be valid URL.';
            error = true;
          }
        }
      }
    });
    if (error) {
      toast.warn('Please check the form for errors.');
    }
    setErrors(errorsFields);
    return error;
  };

  const handelNext = () => {
    if (!hasValidationErrors()) {
      setCurrentStep(step + 1);
    }
    return;
  };

  return (
    <>
      <Form1Styled>
        <div className="d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="col-lg-5 bar">
              <FormInput
                name={'name_en'}
                onChange={handleUserInput}
                label={'Business name'}
                type={'text'}
                placeholder={'Enter business name'}
                asterik={'*'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.name_en
                    : formData.name_en
                }
              />
              {errors.name_en ? <Error text={errors.name_en} /> : ''}
              <FormInput
                name={'name_ar'}
                onChange={handleUserInput}
                label={'Business name (عربي)'}
                type={'text'}
                placeholder={'Enter business name (عربي)'}
                asterik={'*'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.name_ar
                    : formData.name_ar
                }
                style={{
                  direction: 'rtl',
                }}
              />

              {errors.name_ar ? <Error text={errors.name_ar} /> : ''}
            </div>
            <div className="col-lg-12 bar">
              <FormInput
                name={'business_owner_full_name'}
                onChange={handleUserInput}
                label={'Business owner/manager name'}
                type={'text'}
                placeholder={'Enter full name'}
                asterik={'*'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.business_owner_full_name
                    : formData.business_owner_full_name
                }
              />
              {errors.business_owner_full_name ? (
                <Error text={errors.business_owner_full_name} />
              ) : (
                ''
              )}
            </div>
            <div className="col-lg-12 bar">
              <div
                className="form-control-mobile m-0"
                style={{
                  background: '#ffffff',
                  borderRadius: '10px',
                }}
              >
                <div className="row w-100 text-center">
                  <div className="col1 col">
                    <CountrySelectForm
                      setSelectedCountry={setSelectedCountry}
                      value={CurrentContries[0]}
                    />
                  </div>
                  <div className="col mx-0 px-0">
                    <FormControl
                      name={'business_phone'}
                      style={{
                        border: 'none',
                        marginTop: '0',
                        boxShadow:
                          ' 0 0 0 .25rem rgb(5, 174, 75,.25) !important',
                      }}
                      input={true}
                      value={
                        OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                          ? SIGNUP_FORM_DEBUG_DATA.business_phone
                          : formData.business_phone
                      }
                      type="text"
                      htmlFor="business_phone"
                      maxLength={13}
                      placeholder="05XXXXXXXX"
                      onChange={handleUserInput}
                    />
                  </div>
                </div>
              </div>
              {errors.business_phone ? (
                <Error text={errors.business_phone} />
              ) : (
                ''
              )}
            </div>

            <div className="col-lg-12 bar">
              <FormInput
                onChange={handleUserInput}
                label={'Business email'}
                name={'business_email'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.business_email
                    : formData.business_email
                }
                placeholder={'Email Address'}
                asterik={'*'}
              />
              {errors.business_email ? (
                <Error text={errors.business_email} />
              ) : (
                ''
              )}
            </div>
            <div className="col-lg-12 bar">
              <FormInput
                onChange={handleUserInput}
                label={'Business website or profile on social media'}
                name={'business_website_url'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.business_social_media_url
                    : formData.business_website_url
                }
                placeholder={'https://www.business_website.com'}
                optional={' (optional)'}
              />
              {errors.business_website_url ? (
                <Error text={errors.business_website_url} />
              ) : (
                ''
              )}
            </div>
            <div className="col-lg-12 bar">
              <FormInput
                onChange={handleUserInput}
                name={'business_social_media_url'}
                label={'Another business website or profile on social media'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.business_social_media_url
                    : formData.business_social_media_url
                }
                placeholder={'https://www.instagram.com/business_profile'}
                optional={' (optional)'}
              />
              {errors.business_social_media_url ? (
                <Error text={errors.business_social_media_url} />
              ) : (
                ''
              )}
            </div>
            <div className="col-lg-12 bar">
              <FormInput
                name={'tax_id'}
                onChange={handleUserInput}
                label={'Business tax ID'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.tax_id
                    : formData.tax_id
                }
                placeholder={'000-000-000'}
                asterik={'*'}
              />
              {errors.tax_id ? <Error text={errors.tax_id} /> : ''}
              <Form3Styled>
                <div className="category col-lg-12 d-flex justify-content">
                  <div className="col-lg-4 card1">
                    <div
                      className="content light"
                      style={{
                        background:
                          formData.tax_type === BusinessTaxType.licensed_il
                            ? ''
                            : 'transparent',
                      }}
                    >
                      {' '}
                      <Radio>
                        Licensed/LTD
                        <br /> עסק מורשה · مرخّص
                        <input
                          type="radio"
                          name="tax_type"
                          checked={
                            formData.tax_type === BusinessTaxType.licensed_il
                          }
                          value={BusinessTaxType.licensed_il}
                          onChange={handleUserInput}
                        />
                        <span
                          className="checkmark"
                          style={{
                            border: `1px solid ${Colors.greenMain}`,
                            borderRadius: '50%',
                          }}
                        />
                      </Radio>
                      <p>
                        You'll be charged {CURRENT_VAT * 100}% VAT on your
                        sales, and the deduction-at-source (if applicable).
                      </p>
                    </div>
                  </div>{' '}
                  <div className="col-lg-4 card1">
                    <div
                      className="content light"
                      style={{
                        background:
                          formData.tax_type === BusinessTaxType.exempt
                            ? ''
                            : 'transparent',
                      }}
                    >
                      {' '}
                      <Radio>
                        Exempt
                        <br />
                        עסק פטור · معفي
                        <input
                          checked={formData.tax_type === BusinessTaxType.exempt}
                          type="radio"
                          name="tax_type"
                          value={BusinessTaxType.exempt}
                          onChange={handleUserInput}
                        />
                        <span
                          className="checkmark"
                          style={{
                            border: `1px solid ${Colors.greenMain}`,
                            borderRadius: '50%',
                          }}
                        />
                      </Radio>
                      <p>
                        You'll be charged 0% VAT on your sales, and the
                        deduction-at-source (if applicable).{' '}
                      </p>
                    </div>
                  </div>
                </div>
                {errors.tax_type ? <Error text={errors.tax_type} /> : ''}
              </Form3Styled>
            </div>
            <div className="col-lg-12 bar">
              <FormInput
                name={'bank_account_number'}
                onChange={handleUserInput}
                label={'Bank account number'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.bank_account_number.toString()
                    : formData.bank_account_number?.toString()
                }
                placeholder={'0000000'}
                asterik={'*'}
              />{' '}
              {errors.bank_account_number ? (
                <Error text={errors.bank_account_number} />
              ) : (
                ''
              )}
              <FormInput
                name={'bank_branch_number'}
                onChange={handleUserInput}
                label={'Bank branch number'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.bank_branch_number.toString()
                    : formData.bank_branch_number?.toString()
                }
                placeholder={'000'}
                asterik={'*'}
              />{' '}
              {errors.bank_branch_number ? (
                <Error text={errors.bank_branch_number} />
              ) : (
                ''
              )}
              <FormInput
                name={'bank_number'}
                onChange={handleUserInput}
                label={'Bank number'}
                type={'text'}
                value={
                  OVERRIDE_FOR_DEBUG && FILL_SIGNUP_FORM
                    ? SIGNUP_FORM_DEBUG_DATA.bank_number.toString()
                    : formData.bank_number?.toString()
                }
                placeholder={'00'}
                asterik={'*'}
              />{' '}
              {errors.bank_number ? <Error text={errors.bank_number} /> : ''}
            </div>
          </div>
        </div>
      </Form1Styled>
      <FormFooter
        onClickNext={() => handelNext()}
        onClickBack={() => setModalState(true)}
        currentStep={step}
      />
    </>
  );
}
export default Form1;
const Form1Styled = styled.div`
  padding: 20px;
  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(5, 174, 75, 0.25) !important;
  }
  .row {
    border: 1px solid #e8e8e8;
    margin-left: 0px;
  }
  .row {
    border: 1px solid #ededed;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .col1 {
      max-width: 157px;
      width: 100%;
      .value-container {
        border-right: none;
        border-radius: 5px 0px 0px 5px;
      }
    }
    .col2 {
      width: 100%;
      input {
        border-radius: 0px 5px 5px 0px;
      }
    }
  }

  .bar {
    margin: 10px 0px;
  }
`;
