import momentZ from 'moment-timezone';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  AcceptedOrderStyled,
  PickupOrderStyled,
} from './current_orders_components.styled';
import {
  getLatestStatusDate,
  getOrderTimestamp,
} from '../functions/orders_functions';
import { locationIcon_path, walkIcon_path, xIcon_path } from 'assets/index';
import {
  DateTimeFormat,
  ISRAEL_TIMEZONE,
} from 'silal_app_base_react/src/config/constants';
import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from '@nextui-org/react';
import { shiftTypeFormatStartAndEndDateTime } from 'silal_app_base_react/src/data/types/shifts';

const ReceivedOrder = ({ orderDetail }: { orderDetail: OrderBrief }) => {
  const navigate = useNavigate();

  return (
    <Card className="m-3" shadow="sm">
      <CardHeader className="flex justify-between">
        <Link to={`/orders/order-details/${orderDetail.id}`}>
          <h6 className="font-bold text-gray-400">
            Order ID{' '}
            <span
              style={{
                color: Colors.primaryText,
              }}
            >
              #{orderDetail.id}
            </span>
          </h6>
        </Link>
        <h2 className="secondary-font f-bold" style={{ fontSize: '14px' }}>
          {getOrderTimestamp(getLatestStatusDate(orderDetail.order_actions)!)}
        </h2>
      </CardHeader>
      <CardBody className="pb-1">
        {orderDetail.items && (
          <>
            {orderDetail.items.map((item, index) => {
              if (index == 3) {
                // Calculate total quantity minus the first 3 items
                let totalQuantity = 0;
                orderDetail.items.forEach((item) => {
                  totalQuantity += item.quantity;
                });
                totalQuantity -=
                  orderDetail.items[0].quantity +
                  orderDetail.items[1].quantity +
                  orderDetail.items[2].quantity;

                return (
                  <div
                    key={index}
                    className="flex  order-details lato f-medium"
                    style={{
                      marginTop: '14px',
                      marginLeft: '8px',
                      color: Colors.greenMain,
                      backgroundColor: Colors.greenSecondary,
                      borderRadius: '5px',
                      width: 'fit-content',
                      padding: '3px 6px',
                    }}
                  >
                    {totalQuantity} more
                  </div>
                );
              }
              if (index > 2) {
                return null;
              }
              return (
                <h3
                  className="mt-1 ml-2 flex"
                  style={{ fontSize: '16px' }}
                  key={index}
                >
                  <span className="secondary-font mr-2">{item.quantity} </span>{' '}
                  <img src={xIcon_path} alt="multiply" className="mr-2" />
                  <span className="secondary-font"> {item.item.name_en}</span>
                </h3> // TODO localize
              );
            })}
          </>
        )}
      </CardBody>
      <CardFooter className="justify-end pt-0">
        <Button
          className="text-center"
          color="success"
          onClick={() => navigate(`/orders/order-details/${orderDetail.id}`)}
        >
          Accept order
        </Button>
      </CardFooter>
    </Card>
  );
};

const AcceptedOrder = ({ order }: { order: OrderBrief }) => {
  const [orderDetail] = useState(order);
  const navigate = useNavigate();
  return (
    <Card className="m-3" shadow="sm">
      <CardHeader className="flex justify-between">
        <Link to={`/orders/order-details/${orderDetail.id}`}>
          <h6 className="font-bold text-gray-400">
            Order ID{' '}
            <span
              style={{
                color: Colors.primaryText,
              }}
            >
              #{orderDetail.id}
            </span>
          </h6>
        </Link>
        <h2 className="secondary-font f-bold" style={{ fontSize: '14px' }}>
          {getOrderTimestamp(getLatestStatusDate(orderDetail.order_actions)!)}
        </h2>{' '}
      </CardHeader>
      <CardBody>
        {' '}
        <div className="d-flex mb-10 secondary-font f-medium">
          <div className="icon-div blue center">
            <img src={walkIcon_path} alt="icon" />
          </div>
          <div>
            <h3 className="mt-1 ml-2" style={{ fontSize: '16px' }}>
              Customer ID #{orderDetail.customer_id}
            </h3>
          </div>
        </div>
        <div className="d-flex secondary-font f-medium">
          <div className="icon-div purple center">
            <img src={locationIcon_path} alt="icon" />
          </div>
          <h3 className="mt-1 ml-2" style={{ fontSize: '16px' }}>
            Delivery shift #{orderDetail.last_mile_shift_id}
            {', '}
            {shiftTypeFormatStartAndEndDateTime(orderDetail.last_mile_shift)}
          </h3>
        </div>
      </CardBody>
      <CardFooter className="pt-0">
        <Button
          style={{ width: '100%' }}
          color="success"
          onClick={() => navigate(`/orders/order-details/${orderDetail.id}`)}
        >
          Ready for pickup
        </Button>
      </CardFooter>
    </Card>
  );
};

const PickupOrder = ({ order }: { order: OrderBrief }) => {
  return (
    <Card className="lato p-3 bg-gray-100 my-1" shadow="sm">
      <Link to={`/orders/order-details/${order.id}`}>
        <h6 className="dark-clr">#{order.id}</h6>
        <p>
          {momentZ
            .utc(getLatestStatusDate(order.order_actions), DateTimeFormat)
            .tz(ISRAEL_TIMEZONE)
            .fromNow()}
        </p>
      </Link>
    </Card>
  );
};

export { AcceptedOrder, ReceivedOrder, PickupOrder };
