import { Col, Row } from 'react-bootstrap';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import GlobalStyle from 'styles/global_styled';
import { CardStyled, Heading } from 'styles/style';
import { DashboardHeading } from 'components/show_full_elements/index';
import { Review, ReviewOverviewText } from '../components';
import Nav from '../components/navbar';
import { useState } from 'react';

const ReviewsScreen = () => {
  const [total] = useState(189);
  const [answered] = useState(160);
  const [rating] = useState(4.8);

  return (
    <>
      <GlobalStyle />
      <div className="d-flex">
        <div className="w-100 px-3">
          <Nav />
          <DashboardHeading
            heading="Dashboard’s name"
            // hours="Peak Hours 12 AM - 2 PM"
            location="8502 Preston Rd. Inglewood"
          />
          <Row>
            <Col md={9} sm={12}>
              <CardStyled
                className="review-card-height"
                style={{
                  padding: '15px',
                }}
              >
                <div className="between">
                  <Heading>Reviews</Heading>
                </div>
                <Review
                  customerIdentifier="Ursula Landerskape"
                  rating={4}
                  time="14:38"
                  review="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
                  orderBrief="Big Tasty Big McCombo, Chicken McNuggets (18 pcs.), Chicken Wings (3 pcs.), Shrimp Roll, Cheese Sauce, Coca-Cola Zero, Cherry Pie"
                  reviewResponse="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
                />
                <Review
                  customerIdentifier="Ursula Landerskape"
                  rating={4}
                  time="14:38"
                  review="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
                  orderBrief="Big Tasty Big McCombo, Chicken McNuggets (18 pcs.), Chicken Wings (3 pcs.), Shrimp Roll, Cheese Sauce, Coca-Cola Zero, Cherry Pie"
                  reviewResponse="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
                />
                <Review
                  customerIdentifier="Ursula Landerskape"
                  rating={4}
                  time="14:38"
                  review="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
                  orderBrief="Big Tasty Big McCombo, Chicken McNuggets (18 pcs.), Chicken Wings (3 pcs.), Shrimp Roll, Cheese Sauce, Coca-Cola Zero, Cherry Pie"
                  reviewResponse="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
                />
              </CardStyled>
            </Col>
            <Col md={3} sm={12}>
              <CardStyled
                style={{
                  padding: '15px',
                }}
              >
                <Heading
                  style={{
                    fontFamily: Fonts.primary,
                  }}
                >
                  Reviews overview
                </Heading>
                <ReviewOverviewText>
                  <h2>Total </h2>
                  <h3>{total}</h3>
                </ReviewOverviewText>
                <ReviewOverviewText>
                  <h2>Unanswered </h2>
                  <h3>{answered}</h3>
                </ReviewOverviewText>
                <ReviewOverviewText>
                  <h2>Total raiting </h2>
                  <h3>{rating}</h3>
                </ReviewOverviewText>
              </CardStyled>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ReviewsScreen;
