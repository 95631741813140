import ListingsRepository from 'data/repositories/listings_repository';
import {
  Listing,
  ListingItem,
} from 'silal_app_base_react/src/data/types/listings';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import { AttributeAssociation } from 'silal_app_base_react/src/data/types/listings';
import { formatDecimals } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { productPlaceholder_path } from 'assets/index';
import { useState } from 'react';

export const getMinMaxPrice = (items: ListingItem[]) => {
  const minParsed = Math.min(
    ...items.map((item) => {
      if (typeof item.price === 'number') {
        return item.price;
      }
      return parseInt(item.price);
    }),
  );
  const maxParsed = Math.max(
    ...items.map((item) => {
      if (typeof item.price === 'number') {
        return item.price;
      }
      return parseInt(item.price);
    }),
  );
  return minParsed === maxParsed ? maxParsed : minParsed + '-' + maxParsed;
};

export async function fetchCategoryListings(
  setListings: (arg0: any) => void,
  store_id: number,
  categoryId: string | undefined,
  setLoading: (arg0: boolean) => void,
) {
  setLoading(true);
  if (categoryId) {
    await ListingsRepository.paginateListingsOfCategoryID({
      store_id: store_id,
      category_id: parseInt(categoryId!),
      page: 1,
      per_page: 10000,
    }).then((res) => {
      if (!res) return;
      setListings(res!);
    });
  } else {
    await ListingsRepository.paginateListingsDetails({
      store_id: store_id,
      page: 1,
      per_page: 10000,
    }).then((res) => {
      if (!res) return;
      setListings(res!);
    });
  }
  setLoading(false);
}

export const getAttributeValue = (attribute: AttributeAssociation) => {
  return typeof attribute.attr_value_en === 'boolean'
    ? JSON.stringify(attribute.attr_value_en)
    : attribute.attribute_type === AttributeType.NUMERIC
      ? formatDecimals(attribute.attr_value_en)
      : attribute.attr_value_en + ' - ' + attribute.attr_value_ar;
};

export const getExpandableTableHeaders = (data: Listing) => {
  const attributeNames = data?.items[0]?.attributes.map(
    (attr: AttributeAssociation) =>
      attr.attr_name_en + ' - ' + attr.attr_name_ar,
  );
  return (
    <thead className="table-head">
      <tr>
        <th>Item Id</th>
        {attributeNames?.map((name: string, i: any) => {
          return (
            <th key={i}>
              {name}{' '}
              {data.items[0].attributes[i].unit
                ? `(${data.items[0].attributes[i].unit})`
                : null}
            </th>
          );
        })}
        <th>Price</th>
        <th>Image</th>
        <th>In Stock</th>
      </tr>
    </thead>
  );
};

export const getExpandableTableBody = (
  data: Listing,
  handleChangeInStock: any,
) => {
  const [prices, setPrices] = useState(
    data?.items?.map((item: ListingItem) => item.price),
  );
  return (
    <tbody style={{ width: '10%' }}>
      {data?.items?.map((item: ListingItem, i) => (
        <tr key={item.id}>
          <td>#{item.id}</td>
          {item.attributes?.map((attribute: AttributeAssociation) => (
            <td key={attribute.attr_id}>{getAttributeValue(attribute)}</td>
          ))}
          <td>
            <input
              type="number"
              className="form-control"
              style={{
                width: '100px',
                height: '30px',
              }}
              defaultValue={item.price}
              onBlur={(e) => {
                const newPrices = [...prices];
                newPrices[i] = e.target.value;
                setPrices(newPrices);
                handleChangeInStock(
                  {
                    ...item,
                    in_stock: !item.in_stock,
                    price: e.target.value,
                  },
                  data,
                );
              }}
            />
          </td>
          <td>
            {item.image?.id ? (
              <div style={{ width: '60px', height: '60px' }}>
                <ShowImage
                  image={item.image}
                  style={{
                    width: '50px',
                    height: '50px',
                    objectFit: 'scale-down',
                  }}
                />
              </div>
            ) : (
              <img src={productPlaceholder_path} alt="Product Placeholder" />
            )}
          </td>
          <td
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input
              type="checkbox"
              checked={item.in_stock}
              onChange={() => handleChangeInStock(item, data)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
