import { silal_logo_green } from 'assets/index';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
// import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

// type ContextProp = {
//   type: string;
//   mode: string;
//   dataIndex: number;
//   datasetIndex: number;
// };
export default function Graph() {
  // let delayed = false;
  // const options = {
  //   type: 'bar',
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false, // show/hide grid line in x-axis
  //       },
  //     },
  //     y: {
  //       grid: {},
  //     },
  //   },
  //   legend: {
  //     display: false,
  //   },
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     title: {
  //       display: true,
  //       text: '',
  //     },
  //   },
  //   animation: {
  //     onComplete: () => {
  //       delayed = true;
  //     },
  //     delay: (context: ContextProp) => {
  //       let delay = 0;
  //       if (context.type === 'data' && context.mode === 'default' && !delayed) {
  //         delay = context.dataIndex * 300 + context.datasetIndex * 100;
  //       }
  //       return delay;
  //     },
  //   },
  // };

  // const labels = [
  //   '8 AM',
  //   '10 AM',
  //   '12 AM',
  //   '14 PM',
  //   '16 PM',
  //   '18 PM',
  //   '20 PM',
  //   '22 PM',
  // ];

  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: '',
  //       data: [22, 99, 33, 45, 55, 66, 77, 88, 99, 12, 55],
  //       backgroundColor: '#05AE4B',
  //       borderRadius: 15,
  //     },
  //   ],
  // };
  return (
    <div
      style={{
        height: '380px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <Bar options={options} data={data} /> */}
      <img
        src={silal_logo_green}
        alt="silal_logo_green"
        height={150}
        width={150}
      />
      <br />
      <h3>Coming Soon</h3>
    </div>
  );
}
