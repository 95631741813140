import { useEffect, useState, Fragment } from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { Radio } from 'styles/style';
import QuestionaireWorkingHours from './working_hours';
import FormInput from './form_input';
import Error from 'silal_app_base_react/src/components/error';
import FormFooter from './form_footer';
import { weekDays } from 'core/constants/working_hours';
import { StoreSignUpForm } from 'data/types/store';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import { DropDownOption } from 'data/types/general';
import { toast } from 'react-toastify';

type Form3Props = {
  setCurrentStep: (step: number) => void;
  step: number;
  handleUserInput: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleSelectInput: (
    option: SingleValue<{
      label: string;
      value: string;
    }>,
    action: ActionMeta<{
      label: string;
      value: string;
    }>,
  ) => void;
  formData: StoreSignUpForm;
  setFormData: (formData: StoreSignUpForm) => void;
  createStore: () => void;
  handleWorkingTimes: any;
};

function Form3({
  setCurrentStep,
  step,
  handleUserInput,
  handleSelectInput,
  formData,
  setFormData,
  createStore,
  handleWorkingTimes,
}: Form3Props) {
  const [enabledWorkDaysCount, setEnabledWorkDaysCount] = useState(7);
  const [errors, setErrors] = useState({
    slogan: '',
    main_category_id: '',
    bio: '',
    paymentMethods: '',
    workingTimes: '',
  });

  const [formattedCategories, setFormattedCategories] = useState<
    DropDownOption[]
  >([]);

  useEffect(() => {
    async function fetchCategories() {
      await SharedRepository.getCategoryBrief(0).then((res) => {
        // if the type of res isnt boolean
        if (!res) return;
        const formatCategories = res?.sub_categories?.map((category) => {
          return {
            label: category.name_en + ' | ' + category.name_ar,
            value: category.id,
          };
        });
        setFormattedCategories(formatCategories || []);
      });
    }
    fetchCategories();
  }, []);

  const handleValidations = () => {
    const errorsFields = {
      slogan: '',
      main_category_id: '',
      bio: '',
      paymentMethods: '',
      workingTimes: '',
    };
    let error = false;
    Object.keys(formData).map(function (keyName) {
      const value = formData[keyName as keyof StoreSignUpForm];
      if (keyName === 'slogan') {
        if ((value as string)?.length > 40) {
          errorsFields.slogan = 'Slogan must be less than 40 characters';
          error = true;
        }
      } else if (
        !formData.accepts_cash_payments &&
        !formData.accepts_credit_card_payments &&
        !formData.accepts_mobile_payments &&
        !formData.accepts_cheques_payments
      ) {
        errorsFields.paymentMethods =
          'ⓘ At least one payment method is required.';
        error = true;
      } else if (enabledWorkDaysCount < 1) {
        errorsFields.workingTimes = 'ⓘ At least one working day is required.';
        error = true;
      } else if (keyName === 'main_category_id') {
        if (!value) {
          errorsFields.main_category_id = 'ⓘ Catergory is required.';

          error = true;
        }
      } else if (keyName === 'bio') {
        if ((value as string)?.length > 256) {
          errorsFields.bio =
            'ⓘ Store description must be less than 256 characters';

          error = true;
        }
      }
      return null;
    });
    if (error) {
      toast.warn('Please check the form for errors.');
    }
    setErrors(errorsFields);
    return error;
  };

  const handelNext = () => {
    if (!handleValidations()) {
      createStore();
    }
  };

  const handlePaymentMethods = (type: string, value: boolean) => {
    if (value) errors.paymentMethods = '';
    setFormData({ ...formData, [type]: value });
  };

  return (
    <>
      <Form3Styled>
        <div className="d-flex justify-content-center">
          <div className="col-lg-8">
            <h6 className="title">
              Select type <span className="asterik">*</span>
            </h6>
            <div className="category col-lg-12 d-flex justify-content-between">
              <div className="col-lg-4 card1">
                <div className="content light">
                  {' '}
                  <Radio>
                    Store
                    <input
                      checked
                      type="radio"
                      name="pre-orders"
                      onChange={handleUserInput}
                    />
                    <span className="checkmark" />
                  </Radio>
                  <p>Some text for clarification to distinguish the concepts</p>
                </div>
              </div>
            </div>
            {/* below dropdown is fully custom not a bootstrap one */}
            <div>
              <h6 className="title">
                Category <span className="asterik">*</span>
              </h6>
              <Select
                defaultValue={{
                  label:
                    formattedCategories.length > 0
                      ? formattedCategories[0]?.label
                      : 'Select Category',
                  value: formattedCategories
                    ? formattedCategories[0]?.value.toString()
                    : '',
                }}
                placeholder="Select store category"
                options={formattedCategories?.map((category) => ({
                  label: category.label,
                  value: category.value.toString(),
                }))}
                onChange={handleSelectInput}
                name="main_category_id"
              />
              {errors.main_category_id ? (
                <Error text={errors.main_category_id} />
              ) : (
                ''
              )}
            </div>
            <h6 className="title">
              Accepted payment methods <span className="asterik">*</span>
            </h6>

            <div className=" row d-flex justify-content-between">
              <div className=" col-lg-6 my-1">
                <div
                  className="one form-check"
                  style={{
                    outline: formData.accepts_cash_payments
                      ? `1px solid ${Colors.greenMain}`
                      : 'none',
                  }}
                >
                  <input
                    className="padd form-check-input"
                    type="checkbox"
                    checked={formData.accepts_cash_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_cash_payments',
                        e.target.checked,
                      )
                    }
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">Cash Payment</label>
                </div>
              </div>

              <div className=" col-lg-6 my-1">
                <div
                  className="one form-check"
                  style={{
                    outline: formData.accepts_credit_card_payments
                      ? `1px solid ${Colors.greenMain}`
                      : 'none',
                  }}
                >
                  <input
                    className="padd form-check-input"
                    type="checkbox"
                    checked={formData.accepts_credit_card_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_credit_card_payments',
                        e.target.checked,
                      )
                    }
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">
                    Credit Card Payment
                  </label>
                </div>
              </div>

              <div className=" col-lg-6 my-1">
                <div
                  className="one form-check"
                  style={{
                    outline: formData.accepts_mobile_payments
                      ? `1px solid ${Colors.greenMain}`
                      : 'none',
                  }}
                >
                  <input
                    className="padd form-check-input"
                    type="checkbox"
                    checked={formData.accepts_mobile_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_mobile_payments',
                        e.target.checked,
                      )
                    }
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">
                    Mobile Payment (Apple Pay / Google Pay)
                  </label>
                </div>
              </div>

              <div className=" col-lg-6 my-1">
                <div
                  className="one form-check"
                  style={{
                    outline: formData.accepts_cheques_payments
                      ? `1px solid ${Colors.greenMain}`
                      : 'none',
                  }}
                >
                  <input
                    className="padd form-check-input"
                    type="checkbox"
                    checked={formData.accepts_cheques_payments}
                    onChange={(e) =>
                      handlePaymentMethods(
                        'accepts_cheques_payments',
                        e.target.checked,
                      )
                    }
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">Cheques</label>
                </div>
              </div>
              {errors.paymentMethods ? (
                <Error text={errors.paymentMethods} />
              ) : (
                ' '
              )}
            </div>

            <div className="slogan">
              <FormInput
                label={'Store Slogan'}
                name={'slogan'}
                onChange={handleUserInput}
                type={'text'}
                value={formData.slogan}
                placeholder={'What’s a slogan that describes your business?'}
              />
            </div>

            <div className="slogan">
              <h6>Store description</h6>
              <textarea
                value={formData.bio}
                placeholder="Briefly describe what service / product you are posting. This information will be visible on your business profile and available to customers. Max 180 characters."
                className="form-control"
                rows={5}
                id="comment"
                name="bio"
                onChange={handleUserInput}
              />
            </div>

            <h6 className="title">
              Working hours<span className="asterik">*</span>
            </h6>

            <div className="d-flex flex-column">
              {weekDays.map((dayWithKeys) => {
                return (
                  <Fragment key={dayWithKeys.name_en}>
                    <QuestionaireWorkingHours
                      setEnabledWorkDaysCount={setEnabledWorkDaysCount}
                      enabledWorkDaysCount={enabledWorkDaysCount}
                      day={dayWithKeys}
                      formData={formData}
                      handleWorkingTimes={handleWorkingTimes}
                    />
                  </Fragment>
                );
              })}
            </div>
            {errors.workingTimes ? <Error text={errors.workingTimes} /> : ' '}
          </div>
        </div>
      </Form3Styled>
      <FormFooter
        currentStep={step}
        onClickNext={handelNext}
        onClickBack={() => setCurrentStep(step - 1)}
      />
    </>
  );
}

export default Form3;

export const Form3Styled = styled.div`
  font-family: ${Fonts.secondary};
  font-style: normal;
  color: #002733;
  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(5, 174, 75, 0.25) !important;
  }
  .card1,
  .card2,
  .card3 {
    padding: 0px 10px 10px 10px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .img {
    background-image: url('src/assets/images/svg/ribbon.svg');
    background-repeat: no-repeat;
    background-position: top right;
  }
  .light {
    background-color: #cdefdb;
    border-radius: 5px;
    padding: 5px;
    padding: 13px;
    border: 1px solid rgb(5, 174, 75);
  }
  .dull {
    background-color: rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: 5px;
    position: relative;
    padding: 13px;
  }

  .light:hover {
    border: 1px solid #05ae4b;
  }
  .dull:hover {
    border: 1px solid #cdcdd0;
  }

  h6 {
    padding-top: 19px;
    /* padding-top: 25px; */
    font-weight: 500;
    font-size: 15px;
    color: #002733;
  }
  .asterik {
    color: red;
  }
  .CheckBox {
    margin-top: -9px;
  }
  .one {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 13px 0px 13px 28px;
    background: white;
  }
  .padd {
    padding-bottom: 7px;
  }
  .form-check-input:checked {
    background-color: #05ae4b;
  }
  .form-check-label {
    font-weight: 500;
    font-size: 17px;
  }
  .slogan {
    padding-top: 20px;
  }
  .area {
    width: 100%;
    padding: 8px;
  }
`;
