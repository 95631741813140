import { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import GlobalStyle from 'styles/global_styled';
import TopNav from '../components/profile_navbar';
import { Wrapper } from './opening_hours.styled';

import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateStore } from 'store/store/store_actions';
import { CardStyled, ThemeTabs } from 'styles/style';
import ProfileWorkingHour from '../components/working_hours';
import { getCurrentStore } from 'core/hooks/use_selector';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { businessTaxTypeMap } from 'silal_app_base_react/src/data/types/stores';
const OpeningHours = () => {
  const dispatch = useDispatch();
  const currentStore = useSelector(getCurrentStore);
  const [store, setStore] = useState(currentStore);

  const weekDays = [
    {
      name: 'Sunday',
      values: ['working_time_sun_start', 'working_time_sun_end'],
    },
    {
      name: 'Monday',
      values: ['working_time_mon_start', 'working_time_mon_end'],
    },
    {
      name: 'Tuesday',
      values: ['working_time_tue_start', 'working_time_tue_end'],
    },
    {
      name: 'Wedensday',
      values: ['working_time_wed_start', 'working_time_wed_end'],
    },
    {
      name: 'Thursday',
      values: ['working_time_thu_start', 'working_time_thu_end'],
    },
    {
      name: 'Friday',
      values: ['working_time_fri_start', 'working_time_fri_end'],
    },
    {
      name: 'Saturday',
      values: ['working_time_sat_start', 'working_time_sat_end'],
    },
  ];

  const handleWorkingTimes = (value: any, name: any) => {
    setStore({ ...store, [name]: value });
  };

  const handleWorkingTimesMultiple = (data: any) => {
    setStore({
      ...store,
      [data[0].name]: data[0].value,
      [data[1].name]: data[1].value,
    });
  };

  const submitHours = () => {
    const payload = {
      data: {
        ...store,
        tax_type:
          businessTaxTypeMap[store.tax_type as keyof typeof businessTaxTypeMap],
      },
    };
    dispatch(actionUpdateStore(payload));
  };

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="d-flex">
        <div className="w-100">
          <TopNav />
          <Tab.Container id="create-item-tabs" defaultActiveKey="first">
            <ThemeTabs className="mb-26">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first">Standard hours</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item> // TODO connect when Special hours implemented
                                    <Nav.Link eventKey="second">
                                        Special hours
                                    </Nav.Link>
                                </Nav.Item> */}
              </Nav>
            </ThemeTabs>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Row>
                  <Col lg={8} xs={12}>
                    <CardStyled className="p-4">
                      {weekDays.map((item) => {
                        return (
                          <ProfileWorkingHour
                            key={item.name}
                            item={item}
                            formData={store}
                            handleWorkingTimes={handleWorkingTimes}
                            handleWorkingTimesMultiple={
                              handleWorkingTimesMultiple
                            }
                          />
                        );
                      })}
                    </CardStyled>
                    <div className="end">
                      <Button onClick={submitHours}>Save standard hours</Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </Wrapper>
  );
};

export default OpeningHours;
