import {
  ListingItem,
  Listing,
  ListingBrief,
} from 'silal_app_base_react/src/data/types/listings';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { LISTINGS_MESSAGES } from 'data/constants/messages';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
class ListingsApiRepository extends AuthenticatedApiRepository {
  private static instance: ListingsApiRepository | null = null;
  private constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): ListingsApiRepository {
    if (!ListingsApiRepository.instance) {
      ListingsApiRepository.instance = new ListingsApiRepository(axiosInstance);
    }
    return ListingsApiRepository.instance;
  }

  paginateListingsOfCategoryID = async ({
    store_id,
    category_id,
    page,
    per_page,
  }: {
    store_id: number;
    category_id: number;
    page: number;
    per_page: number;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/paginate_listings_by_category/${category_id}/full?page=${page}&per_page=${per_page}`,

          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noSuccessMsg: true,
          noPendingMsg: true,
        },
      );
      return res.data.result as Listing[];
    } catch (error: any) {
      return false;
    }
  };

  paginateListingsDetails = async ({
    store_id,
    page,
    per_page,
    getPublicListings,
    getDrafts,
    getTemplates,
  }: {
    store_id: number;
    page: number;
    per_page: number;
    getPublicListings?: boolean;
    getDrafts?: boolean;
    getTemplates?: boolean;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/paginate_listings/?page=${page}&per_page=${per_page}&detailed=True${getPublicListings ? '&listing_type=1' : ''}${getDrafts ? '&listing_type=2' : ''}${getTemplates ? '&listing_type=3' : ''}`,
        ),
        {
          noSuccessMsg: true,
          noPendingMsg: true,
        },
      );
      return res.data.listings as Listing[];
    } catch (error: any) {
      return false;
    }
  };

  paginateListingsBrief = async ({
    store_id,
    page,
    per_page,
    getPublicListings,
    getDrafts,
    getTemplates,
  }: {
    store_id: number;
    page: number;
    per_page: number;
    getPublicListings?: boolean;
    getDrafts?: boolean;
    getTemplates?: boolean;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/paginate_listings/?page=${page}&per_page=${per_page}${getPublicListings ? '&listing_type=1' : ''}${getDrafts ? '&listing_type=2' : ''}${getTemplates ? '&listing_type=3' : ''}`,
        ),
        {
          noSuccessMsg: true,
          noPendingMsg: true,
        },
      );
      return res.data.listings as ListingBrief[];
    } catch (error: any) {
      return false;
    }
  };

  getCategoryCountsOfListings = async (
    listings: ListingBrief[] | Listing[],
  ) => {
    const categoryCounts: {
      [key: number]: {
        category: any;
        count: number;
        listings: (ListingBrief | Listing)[];
      };
    } = {};

    for (const listing of listings) {
      if (categoryCounts[listing.category_id]) {
        const listings = categoryCounts[listing.category_id].listings;
        listings.push(listing);
        categoryCounts[listing.category_id] = {
          category: categoryCounts[listing.category_id].category,
          count: categoryCounts[listing.category_id].count + 1,
          listings: listings,
        };
      } else {
        const category = await SharedRepository.getCategoryDetails(
          listing.category_id,
          false,
        );
        categoryCounts[listing.category_id] = {
          category: category,
          count: 1,
          listings: [listing],
        };
      }
    }
    return categoryCounts;
  };

  getListingWithID = async (store_id: number, listing_id: number) => {
    const res = await FeedbackToastHandler(
      this.sendRequest('GET', `/stores/${store_id}/listings/${listing_id}`),
      {
        noSuccessMsg: true,
      },
    );
    return res.data.resouce as Listing;
  };

  deleteListingWithID = async (store_id: number, listing_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'DELETE',
          `/stores/${store_id}/listings/${listing_id}/`,
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingDeleted,
        },
      );

      return res.status === 204;
    } catch (error: any) {
      return false;
    }
  };

  updateListingItemStockAndPrice = async (
    store_id: number,
    items: ListingItem[],
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/listing_items/update_stock_price/`,
          {
            baseApiUrl: API_BASE,
            data: items,
          },
        ),
        {
          customSuccessMsg: 'Stock & price updated successfully!',
        },
      );
      if (res.status !== 202) {
        throw new Error('Something went wrong');
      }
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  createListing = async (store_id: number, listing: Listing) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/stores/${store_id}/listings/`, {
          baseApiUrl: API_BASE,
          data: listing,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingCreated,
        },
      );

      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  freezeListing = async (store_id: number, listing_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'DELETE',
          `/stores/${store_id}/listings/freeze/${listing_id}/`,
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingFrozen,
        },
      );
      return res.status === 200;
    } catch (e: any) {
      return false;
    }
  };

  unfreezeListing = async (store_id: number, listing_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/listings/unfreeze/${listing_id}/`,
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingUnfrozen,
        },
      );

      return res.status === 200;
    } catch (e: any) {
      return false;
    }
  };

  deleteListing = async (store_id: number, listing_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'DELETE',
          `/stores/${store_id}/listings/${listing_id}/`,
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingDeleted,
        },
      );

      return res.status === 204;
    } catch (e: any) {
      return false;
    }
  };
}

const ListingsRepository =
  ListingsApiRepository.getInstance(sellerAxiosInstance);
export default ListingsRepository;
