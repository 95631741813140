import { useEffect, useState } from 'react';
import Button from 'silal_app_base_react/src/components/buttons/index';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import FormInput from './form_input';
import Error from 'silal_app_base_react/src/components/error';
import MapModal from 'components/maps/map_modal';
import FormFooter from './form_footer';
import { StoreSignUpForm } from 'data/types/store';
import { DropDownOption } from 'data/types/general';
import { Town } from 'silal_app_base_react/src/data/types/address';
import { CurrentRegions } from 'core/constants/constants';
import { toast } from 'react-toastify';
import { DefaultLocationCoords } from 'silal_app_base_react/src/config/constants';
type Form2Props = {
  setCurrentStep: (value: number) => void;
  step: number;
  formData: StoreSignUpForm;
  setFormData: (value: StoreSignUpForm) => void;
  handleUserInput: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleSelectInput: (
    option: SingleValue<{
      label: string;
      value: string;
    }>,
    action: ActionMeta<{
      label: string;
      value: string;
    }>,
  ) => void;
};

function Form2({
  setCurrentStep,
  step,
  formData,
  setFormData,
  handleUserInput,
  handleSelectInput,
}: Form2Props) {
  const [townsObjs, setTownsObjs] = useState<Town[]>();
  const [towns, setTowns] = useState<DropDownOption[]>();
  const [cities, setCities] = useState<DropDownOption[]>([]);

  const [showMapModal, setMapModal] = useState(false);
  const [coords, setCoords] = useState(DefaultLocationCoords);

  const [errors, setErrors] = useState({
    address_line_1: '',
    address2: '',
    town_id: '',
    notes: '',
    lat: '',
    lng: '',
    region_id: '',
    city_id: '',
  });

  useEffect(() => {
    fetchCities();
    /* eslint-disable */
  }, [formData.address.region_id, formData.address.city_id]);

  useEffect(() => {
    fetchTowns();
  }, [formData.address.city_id]);

  useEffect(() => {
    if (formData.address.city_id != null && formData.address.city_id !== '') {
      fetchTowns();
    } else {
      fetchCities();
      fetchTowns();
    }
  }, []);

  const fetchCities = async () => {
    const region_id =
      formData.address.region_id != null && formData.address.region_id !== ''
        ? formData.address.region_id
        : CurrentRegions[0].value;
    if (region_id) {
      const cities = (await SharedRepository.getCities(region_id)) ?? [];
      if (!cities) {
        return;
      }
      setCities(
        cities?.map((city) => {
          return {
            label: city.name_en + ' | ' + city.name_ar,
            value: city.id,
          };
        }),
      );
    }
  };

  const fetchTowns = async () => {
    const city_id = formData.address.city_id;
    if (city_id) {
      const towns = (await SharedRepository.getTowns(city_id)) ?? [];
      if (!towns) {
        return;
      }
      setTownsObjs(towns);
      setTowns(
        towns?.map((town) => {
          return {
            label: town.name_en + ' | ' + town.name_ar,
            value: town.id,
          };
        }),
      );
    }
  };

  useEffect(() => {
    if (formData.address.town_id) {
      towns?.forEach((town) => {
        if (town.value.toString() === formData.address.town_id.toString()) {
          setInitialCoords({ value: town.value });
        }
      });
    }
  }, [towns]);

  const handleValidations = () => {
    const errorsFields = {
      address_line_1: '',
      address2: '',
      town_id: '',
      notes: '',
      lat: '',
      lng: '',
      region_id: '',
      city_id: '',
    };
    let error = false;
    const address = { ...formData.address };
    Object.keys(address).map(function (keyName) {
      const value = address[keyName as keyof typeof address];
      if (keyName === 'address_line_1') {
        if (!value || value === '') {
          errorsFields.address_line_1 = 'ⓘ Address details is required.';
          error = true;
        }

        if ((value as string).length > 64) {
          errorsFields.address_line_1 =
            'ⓘ Address details length must be less then 64 characters.';
          error = true;
        }
      } else if (keyName === 'town_id') {
        if (!value) {
          errorsFields.town_id = 'ⓘ Town is required.';
          error = true;
        }
      } else if (keyName === 'notes') {
        if ((value as string).length > 0) {
          if ((value as string).length > 128) {
            errorsFields.notes =
              'ⓘ Notes length must be less then 128 characters.';
            error = true;
          }
        }
      } else if (keyName === 'lat' || keyName === 'lng') {
        if (!value) {
          errorsFields.lat = 'ⓘ Latitude & Longitude are required.';
          error = true;
        }
      } else if (keyName === 'region_id') {
        if (!value) {
          errorsFields.region_id = 'ⓘ Region is Required.';
          error = true;
        }
      } else if (keyName === 'city_id') {
        if (!value) {
          errorsFields.city_id = 'ⓘ City is Required.';
          error = true;
        }
      }
    });
    if (error) {
      toast.warn('Please check the form for errors.');
    }
    setErrors(errorsFields);

    return error;
  };

  const handelNext = () => {
    if (!handleValidations()) {
      return setCurrentStep(step + 1);
    }
  };

  const setInitialCoords = (option: any) => {
    const value = option.value;
    const location = townsObjs?.filter((town) => town.id.toString() === value);
    if (
      !location ||
      location.length === 0 ||
      !location[0].lat ||
      !location[0].lng
    ) {
      setCoords(DefaultLocationCoords);
    } else setCoords({ lat: location[0].lat, lng: location[0].lng });
  };

  return (
    <>
      <Form2Styled>
        <MapModal
          show={showMapModal}
          onHide={() => setMapModal(false)}
          setFormData={setFormData}
          formData={formData}
          initial_coords={
            formData?.address?.lat && formData?.address?.lng
              ? formData.address
              : coords
          }
        />
        <div className="d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="address">
              <div className="col-lg-12 bar">
                <FormInput
                  label={'عنوان المتجر (عربي)'}
                  name={'address_line_1'}
                  onChange={handleUserInput}
                  type={'text'}
                  value={formData.address.address_line_1}
                  placeholder={'عنوان المتجر'}
                  asterik={'*'}
                  style={{
                    direction: 'rtl',
                  }}
                />
                {errors.address_line_1 ? (
                  <Error text={errors.address_line_1} />
                ) : (
                  ''
                )}
              </div>
              <div className="col-lg-12 bar">
                <FormInput
                  type={'text'}
                  label="مزيد من التفاصيل حول عنوان المتجر (عربي)"
                  name={'address_line_2'}
                  value={formData.address.address_line_2}
                  onChange={handleUserInput}
                  placeholder={'تفاصيل العنوان'}
                />
                {errors.address2 ? <Error text={errors.address2} /> : ''}
              </div>
            </div>

            <div className="business">
              <h6>
                موقع المتجر<span>*</span>
              </h6>
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="col-lg-4 text-center input">
                  <Select
                    defaultValue={{
                      label: 'اختر المنطقة',
                      value: CurrentRegions[0].value.toString(),
                    }}
                    options={CurrentRegions.map((region) => ({
                      label: region.label,
                      value: region.value.toString(),
                    }))}
                    placeholder="القدس"
                    name={'region_id'}
                    onChange={handleSelectInput}
                  />

                  {errors.region_id ? <Error text={errors.region_id} /> : ''}
                </div>
                <div className="col-lg-4 text-center input">
                  <Select
                    defaultValue={{
                      label: cities[0]?.label ?? 'اختر المدينة',
                      value: cities[0]?.value.toString() ?? '',
                    }}
                    defaultInputValue={
                      formData.address?.city_id?.toString() ??
                      cities?.filter(
                        (city) =>
                          city.value.toString() === formData.address.city_id,
                      )[0].label
                    }
                    options={cities?.map((city) => ({
                      label: city.label,
                      value: city.value.toString(),
                    }))}
                    placeholder="المدينة"
                    name={'city_id'}
                    onChange={handleSelectInput}
                  />
                  {errors.city_id ? <Error text={errors.city_id} /> : ''}
                </div>
                <div className="col-lg-4 text-center input">
                  <Select
                    defaultValue={{
                      label: towns ? towns[0]?.label : 'اختر البلدة',
                      value: towns ? towns[0]?.value.toString() : '',
                    }}
                    defaultInputValue={
                      formData.address.town_id.toString() ??
                      towns?.filter(
                        (town) =>
                          town.value.toString() === formData.address.town_id,
                      )[0].label
                    }
                    options={towns?.map((town) => ({
                      label: town.label,
                      value: town.value.toString(),
                    }))}
                    placeholder="البلدة"
                    name={'town_id'}
                    onChange={(option, name) => {
                      handleSelectInput(option, name);
                      setInitialCoords(option);
                    }}
                  />
                  {errors.town_id ? <Error text={errors.town_id} /> : ''}
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="col-lg-4 text-center input">
                  <input
                    type="number"
                    className="w-100"
                    placeholder="إحداثيات الطول"
                    name={'lng'}
                    value={formData.address.lng}
                    disabled={true}
                  />
                  {errors.lng ? <Error text={errors.lng} /> : ''}
                </div>
                <div className="col-lg-4 text-center input">
                  <input
                    type="number"
                    placeholder="إحداثيات العرض"
                    className="w-100"
                    name={'lat'}
                    value={formData.address.lat}
                    disabled={true}
                  />
                  {errors.lat ? <Error text={errors.lat} /> : ''}
                </div>
                <div className="col-lg-4 text-center input">
                  <Button
                    onClick={() => setMapModal(true)}
                    primary
                    style={{
                      width: '100%',
                    }}
                    bg={Colors.greenMain}
                    disabled={coords.lat ? false : true}
                  >
                    Show Map
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 notes">
              <h6>Notes for delivery drivers</h6>
              <textarea
                name="notes"
                onChange={handleUserInput}
                placeholder="بجانب البنك العربي ..."
                className="form-control"
                rows={10}
                value={formData.address.notes}
                id="comment"
              ></textarea>
            </div>
          </div>
        </div>
      </Form2Styled>
      <FormFooter
        currentStep={step}
        onClickNext={handelNext}
        onClickBack={() => setCurrentStep(step - 1)}
      />
    </>
  );
}

export default Form2;

const Form2Styled = styled.div`
  box-sizing: border-box;
  .form-control:focus {
    box-shadow: 0 0 0 .25rem rgb(5, 174, 75,.25) !important;
  }import MapModal from '../../../../../Questionaire/Common/MapModal';

  .input {
    padding: 0px 10px 10px 0px;
  }
  input {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 13px;
  }
  input::placeholder {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #ccd4d6;
  }
  textarea::placeholder {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #ccd4d6;
  }
  .notes {
    padding-top: 30px;
  }
  .business {
    padding-top: 30px;
  }
  .address {
    padding-top: 30px;
  }
  h6 {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #002733;
  }
  span {
    color: red;
  }
  .svgIcon {
    background-image: url('src/assets/images/svg/location.svg');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: right;
  }
`;
