import styled from 'styled-components';
import { Fonts } from 'silal_app_base_react/src/config/theme';

export const CustomerDetailsStyled = styled.div`
  h4 {
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    color: #ccd4d6;
    margin-bottom: 5px;
  }
  h2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 120%;
    color: #000000;
  }
  .icon-div {
    border-radius: 5px;
    height: 44px;
    min-width: 44px;
    width: 44px;
    margin-right: 10px;
    &.blue {
      background: #deebff;
    }
    &.purple {
      background: #eae6ff;
    }
  }
  h3 {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.408px;
    color: #002733;
    margin-bottom: 4px;
  }
`;

export const OrderListMain = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
  border-radius: 12px;
  padding: 15px;
  padding-bottom: 24px;
  h1 {
    font-weight: 600;
    font-size: 19px;
    margin: 0 10px 20px 0;
    color: #002733;
    span {
      color: #4c6870;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 15px;
    text-align: right;
    letter-spacing: -0.408px;
    color: #002733;
    margin-bottom: 25px;
  }
  .note {
    background: #f2f2f2;
    border-radius: 5px;
    padding: 10px 14px;
    margin-top: 15px;
    font-weight: 500;
    font-size: 13px;
    color: #002733;
    margin: 10px 0px;
    span {
      color: #9fadb1;
    }
  }
  .bottom {
    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px;
    margin: 0 -20px -20px -20px;
    h5 {
      font-weight: 600;
      font-size: 13px;
      line-height: 125%;
      margin: 0 0 5px 0;
    }
    h4 {
      font-weight: 600;
      font-size: 19px;
      line-height: 23px;
      margin: 0;
    }
  }
`;

export const OrderIdMain = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 11px;
  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  svg {
    margin-right: 15px;
  }
  h1 {
    font-weight: bold;
    font-size: 25px;
    line-height: 38px;
    color: #002733;
    margin: 0;
  }
`;

export const NotesStyled = styled.div`
  font-family: ${Fonts.secondary};
  border-bottom: 1px solid #ebeeef;
  background-color: #fafafa;
  padding: 14px 0;
  cursor: pointer;
  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 5px 5px;
  }
  &:first-of-type {
    border-radius: 5px 5px 0 0;
  }
  h1 {
    font-weight: 400;
    font-size: 17px;
    line-height: 120%;
    letter-spacing: -0.408px;
    color: #002733;
    margin: 0 20px 4px 20px;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: -0.408px;
    color: rgba(0, 39, 51, 0.5);
    margin: 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AddNoteButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 16px;
  background: #05ae4b;
  border: 0;
  box-shadow: 0px 15px 20px -8px rgba(115, 213, 201, 0.4);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  svg {
    width: 25px;
    height: 25px;
  }
`;

export const CheckNote = styled.div`
  display: flex;
  .checkbox-main {
    margin: 2px 8px 0 0;
    .checkmark {
      width: 16px;
      height: 16px;
      border: 2px solid rgba(76, 104, 112, 0.5);
      &::after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
      }
    }
    input:checked ~ .checkmark {
      border-color: #05ae4b;
      background-color: #05ae4b;
      &::after {
        border-color: white;
      }
    }
  }
  .note {
    border: none;
    flex: 1;
    caret-color: var(--theme-clr);
    resize: none;
    height: 200px;
  }
`;

export const ModalNotes = styled.div`
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 39, 51, 0.08);
  &:last-of-type {
    border-bottom: none;
  }
  .checkbox-main {
    margin: 0 8px 0 0;
    .checkmark {
      width: 16px;
      height: 16px;
      border: 2px solid rgba(76, 104, 112, 0.5);
      &::after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
      }
    }
    input:checked ~ .checkmark {
      border-color: #05ae4b;
      background-color: #05ae4b;
      &::after {
        border-color: white;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 105%;
    color: #002733;
  }
`;
