import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { exportIcon_path } from 'assets/index';
import GlobalStyle from 'styles/global_styled';
import { CardStyled, Input, Label, SimpleNav } from 'styles/style';
import Button from 'silal_app_base_react/src/components/buttons/index';
const DocumentsPage = () => {
  const [itin, setItin] = useState('314-23-N1348');
  const [acc, setAcc] = useState('2345 5445 7922 2054');

  return (
    <>
      <div className="d-flex">
        <GlobalStyle />

        <div className="flex-1 px-3">
          <SimpleNav>
            <h1>Documents</h1>
          </SimpleNav>
          <Row className="mt-5">
            <Col xl={4} lg={5} md={6} xs={12}>
              <CardStyled>
                <Label>ITIN</Label>
                <Input
                  type="text"
                  value={itin}
                  onChange={(e) => setItin(e.target.value)}
                />
                <Label>Checking account</Label>
                <Input
                  type="text"
                  value={acc}
                  onChange={(e) => setAcc(e.target.value)}
                />
              </CardStyled>
            </Col>
            <Col>
              <Button className="center px-4">
                <img src={exportIcon_path} />
                Export
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DocumentsPage;
