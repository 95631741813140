import { Col, Row } from 'react-bootstrap';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import { CustomerDetailsStyled } from './full_order_components.styled';
import { locationIcon_path, walkIcon_path } from 'assets/index';
import {
  FaBox,
  FaUserClock,
  FaMotorcycle,
  FaLocationPin,
  FaUser,
} from 'react-icons/fa6';
type CustomerDetailsProps = {
  number1: number;
  address: string;
};

export const CustomerDetails = ({ number1, address }: CustomerDetailsProps) => {
  return (
    <CustomerDetailsStyled style={{ fontFamily: Fonts.secondary }}>
      <Row>
        <Col md={9}>
          {/* <h2>WHAT TO PUT HERE</h2> // TODO add something */}
          <div className="d-flex align-items-center mb-10">
            <div className="icon-div purple center">
              <FaUser
                style={{
                  fontSize: 20,
                  borderRadius: 5,
                }}
                color="#AA9AFF"
              />
            </div>
            <h3>Customer ID #{number1}</h3>
          </div>
          <div className="d-flex align-items-center">
            <div className="icon-div purple center">
              <FaLocationPin
                style={{
                  fontSize: 20,
                  borderRadius: 5,
                }}
                color="#AA9AFF"
              />
            </div>
            <h3>{address}</h3>
          </div>
        </Col>
      </Row>
    </CustomerDetailsStyled>
  );
};

export const ShiftDetails = ({
  orderPlacement,
  orderPickup,
  shipmentDelivery,
}: {
  orderPlacement: string;
  orderPickup: string;
  shipmentDelivery: string;
}) => {
  return (
    <CustomerDetailsStyled style={{ fontFamily: Fonts.secondary }}>
      <Row>
        <Col md={9}>
          <div className="d-flex align-items-center mb-10">
            <div className="icon-div blue center">
              <FaBox
                style={{
                  fontSize: 20,
                  borderRadius: 5,
                }}
                color="#5F9EFF"
              />
            </div>
            <h3>Placed: {orderPlacement}</h3>
          </div>
          <div className="d-flex align-items-center mb-10">
            <div className="icon-div blue center">
              <FaUserClock
                style={{
                  fontSize: 20,
                  borderRadius: 5,
                }}
                color="#5F9EFF"
              />
            </div>
            <h3>Pickup: {orderPickup}</h3>
          </div>
          <div className="d-flex align-items-center">
            <div className="icon-div blue center">
              <FaMotorcycle
                style={{
                  fontSize: 20,
                  borderRadius: 5,
                }}
                color="#5F9EFF"
              />
            </div>
            <h3>Delivery: {shipmentDelivery}</h3>
          </div>
        </Col>
      </Row>
    </CustomerDetailsStyled>
  );
};
