import { put, select, takeLatest } from '@redux-saga/core/effects';
import { getCurrentStore } from 'core/hooks/use_selector';

import {
  GET_MANAGED_STORES,
  UPDATE_STORE,
  GET_STORE_INFO_BY_ID,
  actionGetStoreInfoByID,
  actionSetManagedStores,
  actionGetStoreInfoByIDSuccess,
} from './store_actions';
import StoreRepository from 'data/repositories/store_repository';

export function* sagaGetStoreInfoByID() {
  function* getStoreInfoByID({ payload }) {
    const currentStore = yield select(getCurrentStore);
    const res = yield StoreRepository.getStoreDetailsByID(
      payload?.data?.store_id || currentStore.id,
    );
    if (!res) return false;
    yield put(actionGetStoreInfoByIDSuccess(res));
    if (payload?.success) payload?.success(res);
  }
  yield takeLatest(GET_STORE_INFO_BY_ID, getStoreInfoByID);
}

export function* sagaGetManagedStores() {
  function* getManagedStores({ action }) {
    const res = yield StoreRepository.getManagedStores();
    if (!res) return false;
    yield put(actionSetManagedStores(res));
    action?.payload?.success(true);
  }
  yield takeLatest(GET_MANAGED_STORES, getManagedStores);
}

export function* sagaUpdateStore() {
  function* updateStore({ payload }) {
    const currentStore = yield select(getCurrentStore);
    const res = yield StoreRepository.updateStore(
      currentStore.id,
      payload.data,
    );
    if (!res) return false;
    yield put(actionGetStoreInfoByID(currentStore));
    if (payload?.success) payload?.success();
  }
  yield takeLatest(UPDATE_STORE, updateStore);
}
