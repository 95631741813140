import { useNavigate } from 'react-router-dom';
import { Wrapper } from './login_signup_switch.styled';
import { useDispatch } from 'react-redux';
import { fullLogout } from 'routes/functions/routes_functions';
import { useEffect } from 'react';
import { silal_logo_green } from 'assets/index';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button } from '@nextui-org/react';
function LoginSignupSwitch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    fullLogout(dispatch, navigate);
  }, []);
  return (
    <BackgroundContainer>
      <Wrapper>
        <div className=" d-flex justify-content-center inner-content">
          <div className="col-lg-7 text-center content">
            <img
              src={silal_logo_green}
              alt="logo"
              style={{
                margin: 'auto',
                width: '250px',
              }}
            />{' '}
            {/* // TODO add illustration */}
            <h6
              style={{
                paddingTop: '20px',
              }}
            >
              Welcome to your new business Eco-System
            </h6>
            <p>
              Silal helps you manage your business in the online world and
              connect with your customers to achieve more in everyway.
            </p>
            <div className="d-flex flex-col">
              <Button
                onClick={() => {
                  navigate('/signup');
                }}
                color="success"
                className="min-h-12 mb-3"
              >
                Sign up
              </Button>
              <Button
                onClick={() => {
                  navigate('/login');
                }}
                color="success"
                variant="flat"
                className="min-h-12"
              >
                Log In
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default LoginSignupSwitch;
