import Button from 'silal_app_base_react/src/components/buttons/index';
import { Link } from 'react-router-dom';
import { Fonts, Colors } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { silal_logo_white } from 'assets/index';
function QuestionaireSplash() {
  return (
    <QuestionaireStyled>
      <div className="d-flex justify-content-center">
        <div className="col-lg-4 text-center">
          <div className="content">
            <h6>Registration</h6>
          </div>
          <div
            className="splash"
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <img
              src={silal_logo_white}
              alt="logo"
              style={{
                width: '240px',
                margin: 'auto',
              }}
            />
          </div>
          <h3>Waiting for the information to be verified</h3>
          <p>
            Verification usually takes up to 3 days. We suggest you familiarize
            yourself with the instructions for working with the application.
          </p>
          <div
            className="butn"
            style={{
              marginTop: '40px',
            }}
          >
            {/* <Button className="btn2">How It Works</Button> {/* TODO add link */}
            <Link to="choose-business" replace={true}>
              <Button className="btn3">Back To Business Selection</Button>
            </Link>
          </div>
        </div>
      </div>
    </QuestionaireStyled>
  );
}

export default QuestionaireSplash;

const QuestionaireStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  h6 {
    font-weight: 700;
    font-size: 17px;
    color: #002733;
    padding: 15px 0px;
  }
  h3 {
    font-family: ${Fonts.primary};
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #002733;
    padding: 5px 0px;
  }
  p {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #002733;
  }
  .splash {
    display: flex;
    height: 350px;
    width: 350px;
    background: ${Colors.greenMain};
    border-radius: 30px;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 100px;
  }

  .btn2 {
    background: ${Colors.greenMain};
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 100%;
  }
  .btn3 {
    background: #cdefdb;
    color: ${Colors.greenMain};
    border: none;
    margin: 5px;
    width: 100%;
  }
  .butn {
    margin-top: 70px;
  }
`;
