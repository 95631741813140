import { Link, useLocation } from 'react-router-dom';
import { messsageIcon_path } from 'assets/index';
import { NavStyled } from 'styles/style';
import { Notification, NotificationMenu } from './index';
import { notificationsData } from 'data/mock/notifications.data';

const Nav = () => {
  const location = useLocation();

  return (
    <NavStyled
      style={{
        marginTop: '0px',
        marginLeft: '0px',
        paddingBottom: '0px',
      }}
    >
      <div className="d-flex">
        <Link
          to="/"
          className={`nav-link-btn ${
            location.pathname === '/' ? 'active' : ''
          }`}
          style={{
            marginBottom: '0px',
          }}
        >
          Statistics
        </Link>
        {/* <Link // TODO - add this back in when we have the reviews page
          to="/reviews"
          className={`nav-link-btn ${
            location.pathname === '/reviews' ? 'active' : ''
          }`}
        >
          Reviews
        </Link> */}
        <Link
          to="/out-of-stock"
          className={`nav-link-btn ${
            location.pathname === '/out-of-stock' ? 'active' : ''
          }`}
        >
          Out-of-stock
        </Link>
      </div>
      <div className="position-relative">
        <NotificationMenu>
          <div className="head">
            <h6 className="f-medium mb-0">Your notifications</h6>
            <span className="text-white">3 New</span>
          </div>
          <div>
            {notificationsData.map((notif, i) => (
              <Notification
                key={i}
                icon={notif.icon}
                iconClr={notif.clr}
                status={notif.status}
                title={notif.title}
                descp={notif.descp}
                time={notif.time}
              />
            ))}
          </div>
          <h6 className="f-medium mb-0">Previous notifications</h6>
          <div>
            <Notification
              icon={messsageIcon_path}
              iconClr="blue"
              status="old"
              title="Silal Management"
              descp="The refund on the order #247hw9 has been completed."
              time="10m"
            />
          </div>
        </NotificationMenu>
      </div>
    </NavStyled>
  );
};

export default Nav;
