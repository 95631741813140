import { Col, Row } from 'react-bootstrap';
import { oldLogoSIcon_path, rightArrowIcon_path } from 'assets/index';
import GlobalStyle from 'styles/global_styled';
import { SimpleNav } from 'styles/style';
import { Wrapper } from './about_us_page.styled';
import Button from 'silal_app_base_react/src/components/buttons/index';
const AboutUsPage = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <div className="d-flex">
        <div className="px-3">
          <SimpleNav>
            <h1>About app</h1>
          </SimpleNav>
          <Row>
            <Col lg={4} md={8} xs={12}>
              <div className="text-center center flex-column">
                <div className="row">
                  <div className="logo center col-lg-2 offset-10">
                    <img src={oldLogoSIcon_path} />
                  </div>
                </div>

                <h1>Silal App</h1>
                <h6>Ver. 1.0</h6>
              </div>
              <Button className="between">
                Terms of use
                <img src={rightArrowIcon_path} className="arrow" />
              </Button>
              <Button className="between">
                Privacy policy
                <img src={rightArrowIcon_path} className="arrow" />
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

export default AboutUsPage;
