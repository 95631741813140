import { WeekDay } from 'data/types/general';

export const weekDays: WeekDay[] = [
  {
    name_en: 'Sunday',
    name_ar: 'الأحد',
    start_key: 'working_time_sun_start',
    end_key: 'working_time_sun_end',
  },
  {
    name_en: 'Monday',
    name_ar: 'الأثنين',
    start_key: 'working_time_mon_start',
    end_key: 'working_time_mon_end',
  },
  {
    name_en: 'Tuesday',
    name_ar: 'الثلاثاء',
    start_key: 'working_time_tue_start',
    end_key: 'working_time_tue_end',
  },
  {
    name_en: 'Wedensday',
    name_ar: 'الأربعاء',
    start_key: 'working_time_wed_start',
    end_key: 'working_time_wed_end',
  },
  {
    name_en: 'Thursday',
    name_ar: 'الخميس',
    start_key: 'working_time_thu_start',
    end_key: 'working_time_thu_end',
  },
  {
    name_en: 'Friday',
    name_ar: 'الجمعة',
    start_key: 'working_time_fri_start',
    end_key: 'working_time_fri_end',
  },
  {
    name_en: 'Saturday',
    name_ar: 'السبت',
    start_key: 'working_time_sat_start',
    end_key: 'working_time_sat_end',
  },
];

export const timeOptions = [
  { value: '00:00', label: '00:00', default: true },
  { value: '00:30', label: '00:30', default: true },
  { value: '01:00', label: '01:00', default: false },
  { value: '01:30', label: '01:30', default: false },
  { value: '02:00', label: '02:00', default: false },
  { value: '02:30', label: '02:30', default: false },
  { value: '03:00', label: '03:00', default: false },
  { value: '03:30', label: '03:30', default: false },
  { value: '04:00', label: '04:00', default: false },
  { value: '04:30', label: '04:30', default: false },
  { value: '05:00', label: '05:00', default: false },
  { value: '05:30', label: '05:30', default: false },
  { value: '06:00', label: '06:00', default: false },
  { value: '06:30', label: '06:30', default: false },
  { value: '07:00', label: '07:00', default: false },
  { value: '07:30', label: '07:30', default: false },
  { value: '08:00', label: '08:00', default: false },
  { value: '08:30', label: '08:30', default: false },
  { value: '09:00', label: '09:00', default: false },
  { value: '09:30', label: '09:30', default: false },
  { value: '10:00', label: '10:00', default: false },
  { value: '10:30', label: '10:30', default: false },
  { value: '11:00', label: '11:00', default: false },
  { value: '11:30', label: '11:30', default: false },
  { value: '12:00', label: '12:00', default: false },
  { value: '12:30', label: '12:30', default: false },
  { value: '13:00', label: '13:00', default: false },
  { value: '13:30', label: '13:30', default: false },
  { value: '14:00', label: '14:00', default: false },
  { value: '14:30', label: '14:30', default: false },
  { value: '15:00', label: '15:00', default: false },
  { value: '15:30', label: '15:30', default: false },
  { value: '16:00', label: '16:00', default: false },
  { value: '16:30', label: '16:30', default: false },
  { value: '17:00', label: '17:00', default: false },
  { value: '17:30', label: '17:30', default: false },
  { value: '18:00', label: '18:00', default: false },
  { value: '18:30', label: '18:30', default: false },
  { value: '19:00', label: '19:00', default: false },
  { value: '19:30', label: '19:30', default: false },
  { value: '20:00', label: '20:00', default: false },
  { value: '20:30', label: '20:30', default: false },
  { value: '21:00', label: '21:00', default: false },
  { value: '21:30', label: '21:30', default: false },
  { value: '22:00', label: '22:00', default: false },
  { value: '22:30', label: '22:30', default: false },
  { value: '23:00', label: '23:00', default: false },
  { value: '23:30', label: '23:30', default: false },
  { value: '23:59', label: '23:59', default: false },
];
