import React from 'react';

export default function SmallLetter({ active, width, height }) {
  return (
    <svg
      width={width || '35'}
      height={height || '40'}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 7.71429V1.33929C23.9996 0.98421 23.8678 0.643794 23.6335 0.392718C23.3991 0.141641 23.0814 0.000407634 22.75 0H7.25C6.9186 0.000407634 6.60087 0.141641 6.36654 0.392718C6.1322 0.643794 6.00038 0.98421 6 1.33929V7.71429L0 12.3058V30H30V12.3058L24 7.71429ZM27.3549 12.9152L24 15.2652V10.3473L27.3549 12.9152ZM8 2.14286H22V16.6663L18.4271 19.1693L15.516 17.5519L12.3433 19.193L8 16.3677V2.14286ZM15.484 19.948L28 26.9014V27.8571H2V26.9225L15.484 19.948ZM2 14.9715L10.1965 20.3036L2 24.543V14.9715ZM20.4654 20.3019L28 15.0232V24.4876L20.4654 20.3019ZM6 10.3473V15.067L2.66663 12.8983L6 10.3473Z"
        fill={`${active ? '#05AE4B' : '#E8E8E8'}`}
      />
    </svg>
  );
}
