import { useNavigate } from 'react-router-dom';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { silal_logo_green } from 'assets/index';
import { Button } from '@nextui-org/react';

function QuestionaireSplash() {
  const navigate = useNavigate();
  return (
    <QuestionaireStyled>
      <div className="d-flex justify-content-center">
        <div className="col-lg-4 text-center content-wrapper">
          <div className="content mt-4">
            <h6>Registration</h6>
            <h3>Fill out business questionnaire</h3>
            <p>
              Silal aims to create a safe, high-quality online space, so in
              order to register on the platform, please tell us in detail about
              your business and wait till we review your application.
            </p>
          </div>
          <img
            src={silal_logo_green}
            alt="logo"
            style={{
              width: '300px',
            }}
          />{' '}
          {/* // TODO add illustration */}
          <Button
            onClick={() => navigate('/questionaire-form')}
            color="success"
            className="w-full h-11 mb-32"
          >
            Start your business on Silal
          </Button>
        </div>
      </div>
    </QuestionaireStyled>
  );
}

export default QuestionaireSplash;

const QuestionaireStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  h6 {
    font-weight: 700;
    font-size: 17px;
    color: #002733;
    padding: 15px 0px;
  }
  h3 {
    font-family: ${Fonts.primary};
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #002733;
    padding: 5px 0px;
  }
  p {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #002733;
  }
  .content-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .btn2 {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 484px;
    font-size: 17px;
    font-family: ${Fonts.secondary};
  }
`;
