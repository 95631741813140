import 'mapbox-gl/dist/mapbox-gl.css';
import { GeoAlt } from 'react-bootstrap-icons';
import Map, { Marker } from 'react-map-gl';

type MapBoxProps = {
  coords: any;
  setCoords: any;
  setFormData: any;
  formData: any;
};
export default function MapBox({
  coords,
  setCoords,
  setFormData,
  formData,
}: MapBoxProps) {
  const selectLocation = (e: any) => {
    // TODO: fix any
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        lat: e?.lngLat.lat,
        lng: e?.lngLat.lng,
      },
    });
    setCoords({ ...coords, lat: e?.lngLat.lat, lng: e?.lngLat.lng });
  };

  return (
    <>
      {coords.lat ? (
        <Map
          initialViewState={{
            longitude: coords.lng,
            latitude: coords.lat,
            zoom: 12,
            pitch: 0,
            // width: '100%',
            // height: '400px',
          }}
          onClick={selectLocation}
          // center={[coords.lng, coords.lat]}
          style={{ width: '100%', minHeight: '500px' }}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken="pk.eyJ1IjoibXJzZWlmMTIzIiwiYSI6ImNsOHR4YWJoZjA4a3czbmxpd3AyZ3BuZ2wifQ.2mPFM1A0sl85l6WedH3wEQ"
          // onViewportChange={(newView) =>
          //   setCoords(newView.latitude, newView.longitude)
          // }
        >
          <Marker longitude={coords.lng} latitude={coords.lat} anchor="center">
            <GeoAlt
              color="red"
              size={14}
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </Marker>
        </Map>
      ) : (
        <>
          <h3 className="text-center m-5">Please Select Town First!</h3>
        </>
      )}
    </>
  );
}
