import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Spinner from 'silal_app_base_react/src/components/spinner';
import 'antd/dist/reset.css';

import App from './App';
import './styles/index.css';
import './index.css';

const rootElement = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </BrowserRouter>,
);
