import Wrapper from './back_button.styled';
import { useNavigate } from 'react-router-dom';

// import icons
import { Icon } from '@iconify/react';

function Backbutton({
  primaryIcon,
  url,
  customBackFunction,
}: {
  primaryIcon: boolean;
  url?: string;
  customBackFunction?: () => void;
}) {
  const navigate = useNavigate();

  function handleClick() {
    if (customBackFunction) {
      customBackFunction();
    } else if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  }

  return (
    <Wrapper onClick={handleClick}>
      {primaryIcon ? (
        <Icon icon="akar-icons:chevron-left" />
      ) : (
        <Icon icon="bi:arrow-left" />
      )}
    </Wrapper>
  );
}

export default Backbutton;
