import { select, takeLatest, call } from 'redux-saga/effects';
import ListingsRepository from 'data/repositories/listings_repository';
import {
  CREATE_LISTING,
  UPDATE_STOCK_CREATE_LISTING,
} from './products_actions';
import { getCurrentStore } from 'core/hooks/use_selector';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';

export function* sagaCreateListing() {
  function* createListing(action: any): Generator<any, any, any> {
    const currentStore: StoreDetails = yield select(getCurrentStore);
    const res: any = yield call(
      ListingsRepository.createListing,
      currentStore.id,
      action.payload.data,
    );
    if (!res) {
      if (action.payload.error) action.payload.error();
      return false;
    }
    action.payload.success();
  }
  yield takeLatest(CREATE_LISTING, createListing);
}

export function* sagaUpdateListingStockAndPrice() {
  function* updateListingStockAndPrice(action: any): Generator<any, any, any> {
    const currentStore: StoreDetails = yield select(getCurrentStore);
    const res: any = yield call(
      ListingsRepository.updateListingItemStockAndPrice,
      currentStore.id,
      action.payload.data,
    );
    if (!res) return false;
    action.payload.success(res);
  }
  yield takeLatest(UPDATE_STOCK_CREATE_LISTING, updateListingStockAndPrice);
}
