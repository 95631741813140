export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';

export const LOGOUT_ORDERS = 'LOGOUT_ORDERS';

export const actionGetAllOrders = (statuses: number[]) => ({
  type: GET_ORDERS,
  statuses,
});

export const actionSetCurrentOrders = (payload: any) => ({
  type: SET_ORDERS,
  payload,
});

export const actionLogoutOrders = () => ({
  type: LOGOUT_ORDERS,
});
