import { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  actionGetStoreInfoByID,
  actionGetManagedStores,
} from 'store/store/store_actions';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { HeadingStyled, ThemeModal } from 'styles/style';
import {
  Wrapper,
  UnderReviewWrapper,
  RejectModalWrapper,
} from './choose_business_screen.styled';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { toast } from 'react-toastify';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { StoreRequestsStatus } from 'silal_app_base_react/src/config/constants';
import StoreRepository from 'data/repositories/store_repository';
import {
  storeProfilePlaceholder_path,
  deleteIcon_path,
  editIcon_path,
  silal_logo_green,
} from 'assets/index';
import { LogoutIconButton } from 'routes/functions/routes_functions';
import {
  getCurrentStoreState,
  getCurrentUserState,
} from 'core/hooks/use_selector';
import { Button as ButtonNext } from '@nextui-org/react';

function ChooseBusiness() {
  const { stores } = useSelector(getCurrentStoreState);
  const { user } = useSelector(getCurrentUserState);
  const [show, setShow] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [store_id, setStoreId] = useState<number>();
  const [selected_store, setStore] = useState<StoreDetails>();
  const [rejection_message, setRejectionMessage] = useState('');
  const [hoveredStoreId, setHoveredStoreId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShow = (store: StoreDetails) => {
    setStore(store);
    setShow(true);
  };

  const handleRejected = (store: StoreDetails) => {
    setStore(store);
    getStoreRequest(store.id, StoreRequestsStatus.Pending);
    setShowRejected(true);
  };

  const getStoreRequest = async (
    store_id: number,
    status: StoreRequestsStatus,
  ) => {
    await StoreRepository.getStoreRequest(store_id, status).then((res) => {
      if (!res) return;
      setRejectionMessage(res.data.message);
    });
  };

  // api calls
  useEffect(() => {
    dispatch(actionGetManagedStores());
  }, []);

  const storeLogin = () => {
    if (store_id) {
      const payload = {
        data: { store_id },
        success: (_: StoreDetails) => {
          setTimeout(() => {
            navigate('/');
          }, 1000);
        },
      };
      dispatch(actionGetStoreInfoByID(payload));
    } else {
      toast.warn('Please select a store to continue!');
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleRejectedClose = () => {
    setShowRejected(false);
  };

  const setStoreFullDetails = async (store_id: number, request_id: number) => {
    await StoreRepository.getFullStoreRequest(store_id, request_id);
  };

  const updateStore = () => {
    if (!selected_store) {
      toast.warn('Please select a store first');
      return;
    }
    setStoreFullDetails(
      selected_store!.id,
      selected_store!.add_store_request.id,
    );
  };

  const handleEdit = async (store: StoreDetails) => {
    navigate(`/questionaire-form`, { state: { store } });
  };

  const handleDelete = async (store: StoreDetails) => {
    if (
      store.add_store_request !== null &&
      store.id === hoveredStoreId &&
      store.id === store.add_store_request.store_id
    ) {
      await StoreRepository.deleteStoreRequest(store.id).then((res) => {
        if (!res) return;
        window.location.reload();
      });
    }
  };

  return (
    <>
      {show && (
        <UnderReviewModal
          handleClose={handleClose}
          show={show}
          store={selected_store!}
        />
      )}
      {showRejected && (
        <RejectedModal
          handleClose={handleRejectedClose}
          show={showRejected}
          store={selected_store!}
          rejection_message={rejection_message}
          updateStore={updateStore}
        />
      )}
      <Wrapper>
        <div className="d-flex justify-content-end">
          <LogoutIconButton />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <h4>Choose a store to log into</h4>
            <h5>Owned Stores</h5>
            {stores?.filter((store) => store.owner_id === user.id).length ===
            0 ? (
              <p>
                You don't have any owned stores yet, <br />
                you may create one by clicking on the button below
              </p>
            ) : (
              <>
                <div className="stores">
                  {stores
                    ?.filter((store) => store.owner_id === user.id)
                    .map((store) => (
                      <Fragment key={store.id}>
                        {store.is_approved && store.owner_id === user.id ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              className={
                                store.id === store_id
                                  ? 'selected-store'
                                  : 'unselected-store'
                              }
                              onClick={() => {
                                setStoreId(store.id);
                                setHoveredStoreId(null);
                              }}
                              onMouseEnter={() => setHoveredStoreId(store.id)}
                              onMouseLeave={() => setHoveredStoreId(null)}
                            >
                              {store.logo_img ? (
                                <ShowImage
                                  image={store.logo_img}
                                  classNames="img1"
                                />
                              ) : (
                                <img
                                  className="img1"
                                  src={storeProfilePlaceholder_path}
                                  alt="store-logo"
                                />
                              )}
                            </div>
                            <h6>{store.name_en}</h6> {/* TODO localize */}
                          </div>
                        ) : !store.is_approved &&
                          store.add_store_request.status_name === 'pending' ? (
                          <div className="unselected-store justify-content-center d-flex">
                            <div
                              className="d-flex justify-content-center flex-column"
                              onClick={() => {
                                setHoveredStoreId(null);
                              }}
                              onMouseEnter={() => setHoveredStoreId(store.id)}
                              onMouseLeave={() => setHoveredStoreId(null)}
                            >
                              <div style={{ position: 'relative' }}>
                                <h6 className="underReview-heading">
                                  {store.name_en}
                                </h6>{' '}
                                {/* TODO localize */}
                                <p>
                                  Silal QA team is reviewing your application,
                                  more info
                                </p>
                                <button
                                  className="underReview-btn"
                                  onClick={() => handleShow(store)}
                                >
                                  Under Review
                                </button>
                                {hoveredStoreId === store.id && (
                                  <div>
                                    <div
                                      className="edit-button"
                                      style={{
                                        position: 'absolute',
                                        top: -10,
                                        left: 0,
                                        transform: 'translate(-80%, -50%)',
                                      }}
                                    >
                                      <div
                                        className="edit-button"
                                        onClick={() => handleEdit(store)}
                                      >
                                        <img src={editIcon_path} alt="edit" />
                                      </div>
                                    </div>
                                    <div
                                      className="delete-button"
                                      style={{
                                        position: 'absolute',
                                        top: -10,
                                        right: 0,
                                        transform: 'translate(50%, -50%)',
                                      }}
                                    >
                                      <div
                                        className="del-button"
                                        onClick={() => handleDelete(store)}
                                      >
                                        <img
                                          src={deleteIcon_path}
                                          alt="delete"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="unselected-store justify-content-center d-flex"
                            onClick={() => {
                              setStoreId(store.id);
                              setHoveredStoreId(null);
                            }}
                            onMouseEnter={() => setHoveredStoreId(store.id)}
                            onMouseLeave={() => setHoveredStoreId(null)}
                          >
                            <div style={{ position: 'relative' }}>
                              <div className="d-flex justify-content-center flex-column">
                                <h6 className="rejected-heading">
                                  {store.name_en}
                                </h6>{' '}
                                {/* TODO localize */}
                                <p>
                                  Silal QA team has rejected your application,
                                  more info
                                </p>
                                <Button
                                  className="rejected-btn"
                                  onClick={() => handleRejected(store)}
                                >
                                  Rejected
                                </Button>
                                {hoveredStoreId === store.id && (
                                  <div
                                    className="delete-button"
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                      transform: 'translate(50%, -50%)',
                                    }}
                                  >
                                    <div
                                      className="del-button"
                                      onClick={() => handleDelete(store)}
                                    >
                                      <img src={deleteIcon_path} alt="edit" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
        {/* Managed stores */}
        {stores?.filter((store) => store.owner_id !== user.id).length > 0 && (
          <div className="d-flex justify-content-around text-center">
            <div>
              <h5>Managed Stores</h5>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {stores?.map(
                  (store) =>
                    store.is_approved &&
                    store.owner_id !== user.id && (
                      <div
                        key={store.id}
                        className={`col-lg-3 box ${
                          store.id === store_id
                            ? 'selected-store'
                            : 'unselected-store'
                        }`}
                        style={{
                          paddingTop: '0px',
                        }}
                        onClick={() => setStoreId(store.id)}
                      >
                        {store.logo_img ? (
                          <ShowImage image={store.logo_img} classNames="img1" />
                        ) : (
                          <img
                            className="img1"
                            src={silal_logo_green}
                            alt={'...'}
                          />
                        )}
                        <h6 className="b6">{store.name_en}</h6>
                        <p>
                          {store.current_user_access_level === 5
                            ? 'Admin'
                            : store.current_user_access_level === 3
                              ? 'Maintainer'
                              : ''}
                        </p>
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        )}
        <p className="text text-center" style={{ paddingTop: '50px' }}>
          Your<span className="separate"> Merchant </span>
          ID for invites is:
          <span className="separate"> #{user?.id}</span>
        </p>
        <div className="butns text-center">
          <Link to="/questionaire-form" style={{ width: '100%' }}>
            <ButtonNext color={'success'} variant="flat" className="btn2">
              Create a new store
            </ButtonNext>
          </Link>
          <ButtonNext
            onClick={storeLogin}
            className="btn2"
            color={'success'}
            isDisabled={store_id === undefined}
          >
            {store_id === undefined ? 'Select a store to continue' : 'Continue'}
          </ButtonNext>
        </div>
      </Wrapper>
    </>
  );
}

type UnderReviewModalProps = {
  handleClose: () => void;
  show: boolean;
  store: StoreDetails;
};
const UnderReviewModal = ({
  handleClose,
  show,
  store,
}: UnderReviewModalProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className=" modal-title-center"
      >
        <ThemeModal>
          <UnderReviewWrapper>
            <Wrapper>
              <Modal.Header closeButton>
                <Modal.Title className="text-center">
                  Business{' '}
                  <span
                    style={{
                      color: '#05AE4B',
                    }}
                  >
                    {store?.name_en} {/* TODO localize */}
                  </span>{' '}
                  is currently Under Review
                  <br />
                  Request ID:
                  <span className="separate">
                    {' '}
                    #{store?.add_store_request.id}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HeadingStyled className="text-center para">
                  Silal focuses on building a trusted, safe and high quality
                  e-commerce platforms, thus we inspect each joining request
                  individually, please be paitient while we review your
                  application
                </HeadingStyled>
                <div className="d-flex justify-content-between row footer mt-3">
                  <div className="col-6">
                    <Button bg="#CCD4D6" color="rgba(0, 39, 51, 0.5)">
                      I need Help!
                    </Button>{' '}
                    {/* TODO connect to Contact us */}
                  </div>
                  <div className="col-6">
                    <Button onClick={handleClose}>Ok, got it</Button>
                  </div>
                </div>
              </Modal.Body>
            </Wrapper>
          </UnderReviewWrapper>
        </ThemeModal>
      </Modal>
    </>
  );
};

type RejectedModalProps = {
  handleClose: () => void;
  show: boolean;
  store: StoreDetails;
  rejection_message: string;
  updateStore: () => void;
};
const RejectedModal = ({
  handleClose,
  show,
  store,
  rejection_message,
  updateStore,
}: RejectedModalProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className=" modal-title-center"
      >
        <ThemeModal>
          <RejectModalWrapper>
            <Wrapper>
              <Modal.Header closeButton>
                <Modal.Title className="text-center">
                  Business{' '}
                  <span
                    style={{
                      color: '#05AE4B',
                    }}
                  >
                    {store.name_en} {/* TODO localize */}
                  </span>{' '}
                  status is Rejected
                  <br />
                  Request ID:
                  <span className="separate">
                    {' '}
                    #{store?.add_store_request.id}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HeadingStyled
                  className="para"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {rejection_message}
                </HeadingStyled>
                <div className="d-flex justify-content-between row footer mt-3">
                  <div className="col-6">
                    <Button
                      bg="#CCD4D6"
                      color="rgba(0, 39, 51, 0.5)"
                      onClick={handleClose}
                    >
                      I'll do it later
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button onClick={updateStore}>Update info now</Button>
                  </div>
                </div>
              </Modal.Body>
            </Wrapper>
          </RejectModalWrapper>
        </ThemeModal>
      </Modal>
    </>
  );
};

export default ChooseBusiness;
