import { getCurrentStore } from 'core/hooks/use_selector';
import StoreRepository from 'data/repositories/store_repository';
import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  MerchantPermissionTypes,
  SellerWorkforce as SellerWorkforceType,
} from 'silal_app_base_react/src/data/types/users';
import { ModalStyle, SellerWorkStyle } from 'styles/style';

function SellerWorkforce({
  worker,
  setWorkForceChanged,
}: {
  worker: SellerWorkforceType;
  setWorkForceChanged: any;
}) {
  const { id } = useSelector(getCurrentStore);
  const [revokeModel, setRevokeModel] = useState(false);

  const handleShowModel = () => setRevokeModel(true);
  const handleCloseModel = () => setRevokeModel(false);

  async function revokeSellerAccess() {
    await StoreRepository.revokeAccessToSeller(id, worker.seller_id).then(
      (res) => {
        if (!res) return;
        handleCloseModel();
        setWorkForceChanged(true);
      },
    );
  }

  return (
    <SellerWorkStyle>
      <h6> Seller ID: {worker.seller_id}</h6>
      <div className="content">
        <p>Seller Name: {worker.seller_name}</p>
        <Row>
          <Col lg={6}>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    worker.access_level === MerchantPermissionTypes.ADMIN
                  }
                />
                <label className="form-check-label">Admin</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    worker.access_level === MerchantPermissionTypes.MAINTAINANCE
                  }
                />
                <label className="form-check-label">Maintainer</label>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Button onClick={handleShowModel} className="btn1">
              Revoke
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        className="d-flex justify-content-center"
        show={revokeModel}
        onHide={handleCloseModel}
        centered
      >
        <ModalStyle>
          <Modal.Header closeButton />
          <Modal.Body className="text-center ">
            <h3>Are you sure you want to remove Seller #{worker.seller_id}?</h3>
            <div className="text">
              This will revoke all permissions given to seller{' '}
              {worker.seller_name} from your store {worker.store_name_en} |{' '}
              {worker.store_name_ar}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex w-100 center ">
              <Col lg={6} className="pe-2">
                <Button
                  className="btn1 w-100"
                  onClick={revokeSellerAccess}
                  style={{
                    backgroundColor: `${Colors.tomatoRedLight}`,
                    color: `${Colors.tomatoRed}`,
                  }}
                >
                  Yes, Revoke
                </Button>
                <Button className="btn1 w-100" onClick={handleCloseModel}>
                  No, later
                </Button>
              </Col>
            </div>
          </Modal.Footer>
        </ModalStyle>
      </Modal>
    </SellerWorkStyle>
  );
}

export default SellerWorkforce;
