import { useEffect, useState } from 'react';
import BarcodeTrackingModal from './barcode_tracking_modal';
import StockTrackingModal from './stock_tracking_modal';
import OrdersRepository from 'data/repositories/orders_repository';
import {
  OrderBrief,
  OrderDetails,
} from 'silal_app_base_react/src/data/types/orders';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { TrackingData } from 'silal_app_base_react/src/data/types/shipping';

type AcceptOrderModalProps = {
  text: string;
  order: OrderBrief;
  style?: any;
};
export const AcceptOrderModal = ({
  text,
  order,
  style,
}: AcceptOrderModalProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetails>();
  const [step, setStep] = useState(1);
  const [trackingData, setTrackingData] = useState<TrackingData[]>([]);

  useEffect(() => {
    const getOrderDetail = async () => {
      const details = await OrdersRepository.getOrderDetailsByID({
        store_id: order.store_id,
        order_id: order.id,
      });
      if (!details) return;
      setOrderDetails(details!);
    };
    getOrderDetail();
  }, [order.id, order.store_id]);

  return orderDetails === null ? (
    <Spinner />
  ) : (
    <div style={style}>
      {step === 1 && (
        <BarcodeTrackingModal
          text={text}
          orderDetails={orderDetails!}
          data={trackingData}
          setData={setTrackingData}
          setStep={setStep}
          buttonStyle={{
            width: '100%',
          }}
        />
      )}
      {step === 2 && (
        <StockTrackingModal text={text} orderDetails={orderDetails!} />
      )}
    </div>
  );
};
