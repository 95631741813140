import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  .logout-btn {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
  }
  h6 {
    padding: 20px;
    padding-top: 30px;
    font-weight: 700;
    font-size: 25px;
    color: #05ae4b;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    color: #002733;
  }

  .text {
    font-weight: 400;
    font-size: 20px;
    color: #002733;
  }
  .separate {
    color: #05ae4b;
  }
  .btn2 {
    background: #05ae4b;
    color: #fff;
    border: none;
    margin: 5px;
    width: 100%;
    font-size: 17px;
    font-family: ${Fonts.secondary};
  }
`;

export default Wrapper;
