import { useSelector } from 'react-redux';
import UnuthenticatedViewRouter from 'routes/views/unauthenticated_view';
import AuthenticatedViewRouter from 'routes/views/authenticated_view';
import SemiauthenticatedViewRouter from 'routes/views/semiauthenticated_view';
import {
  getCurrentStoreState,
  getCurrentUserState,
} from 'core/hooks/use_selector';
import {
  isUserAuhtorized,
  isUserFullyAuthenticated,
  isUserSemiAuthenticated,
} from './functions/routes_functions';
import AuhtorizedViewRouter from './views/authorized_view';
const AppRouter = () => {
  const userState = useSelector(getCurrentUserState);
  const { currentStore } = useSelector(getCurrentStoreState);

  const userFullyAuthenticated = isUserFullyAuthenticated({
    userState,
    currentStore,
  });
  const userSemiAuthenticated = isUserSemiAuthenticated(userState);
  const userAuthorized = isUserAuhtorized(userState);

  return userFullyAuthenticated ? (
    <AuthenticatedViewRouter />
  ) : userSemiAuthenticated ? (
    <SemiauthenticatedViewRouter />
  ) : userAuthorized ? (
    <AuhtorizedViewRouter />
  ) : (
    <UnuthenticatedViewRouter />
  );
};

export default AppRouter;
