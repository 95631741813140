import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import Spinner from 'silal_app_base_react/src/components/spinner';
import {
  ExpandListingInfoInProductsTableWrapper,
  Heading,
  TableStyled,
} from 'styles/style';
import {
  CategoryComponent,
  CategoryComponentWithImage,
} from './components/index';
import Nav from './components/products_header';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import {
  productPlaceholder_path,
  notFoundIllustration_path,
  externalLinkIcon_path,
} from 'assets/index';
import {
  CURRENT_CURRENCY,
  LISTING_STATUS_COLORS,
  LISTING_STATUS_TYPES,
  LISTING_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  getExpandableTableBody,
  getExpandableTableHeaders,
  getMinMaxPrice,
} from './functions/products_functions';
import {
  ListingItem,
  Listing,
  SolrTemplateResponse,
  ListingBrief,
} from 'silal_app_base_react/src/data/types/listings';
import { getCurrentStore } from 'core/hooks/use_selector';
import ListingsRepository from 'data/repositories/listings_repository';
import { Category } from 'silal_app_base_react/src/data/types/categories';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';

const Products = () => {
  const [listings, setListings] = useState<Listing[]>();
  const [categories, setCategories] = useState<{
    [key: number]: {
      category: any;
      count: number;
      listings: (ListingBrief | Listing)[];
    };
  }>();
  const [loading, setLoading] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [publicListings, setPublicListings] = useState<Listing[]>();
  const [publicTemplates, setPublicTemplates] = useState<
    SolrTemplateResponse[]
  >([]);
  const currentStore = useSelector(getCurrentStore);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setPublicListings(
      listings?.filter(
        (x) => x.listing_type_value === LISTING_TYPES.public_listing,
      ),
    );
  }, [listings]);

  useEffect(() => {
    async function fetchManagementTemplates() {
      await SolrRepository.searchManagementSilalOwnedTemplates({
        query: search,
        sortField: 'created_at_dt',
        sortOrder: 'desc',
      }).then((res: any) => {
        if (!res) return;
        setPublicTemplates(res);
      });
      setLoadingTemplates(false);
    }
    fetchManagementTemplates();
  }, [search]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: '13px',
        fontWeight: 700,
      },
    },
  };

  const columns = [
    {
      name: 'Photo',
      width: '120px',
      selector: (row: Listing) =>
        row.images.length > 0 ? (
          <div style={{ width: '100px', height: '100px' }}>
            <ShowImage
              image={row.images.find((img) => img.id === row?.cover_image_id)}
              style={{ width: '75px', height: '75px', objectFit: 'scale-down' }}
            />
          </div>
        ) : (
          <img src={productPlaceholder_path} alt="mobile small" />
        ),
    },
    {
      name: 'Item name',
      width: '200px',
      selector: (row: Listing) => row.name_en,
    },
    {
      name: 'Listing ID',
      width: 'auto',
      selector: (row: Listing) => row.id,
    },
    {
      name: 'Variations',
      width: 'auto',
      selector: (row: Listing) => row.items.length,
    },
    {
      name: 'Date Created',
      sortAble: true,
      width: '10%',
      sortFunction: (a: Listing, b: Listing) =>
        moment(a.date_created).unix() - moment(b.date_created).unix(),
      selector: (row: Listing) =>
        formateServerDateToLocaleDate(row.date_created),
    },
    {
      name: 'Current orders',
      sortAble: true,
      width: '10%',
      sortFunction: (a: Listing, b: Listing) =>
        a.current_orders! - b.current_orders!,
      selector: (row: Listing) => row.current_orders,
    },
    {
      name: 'Price',
      width: '10%',
      selector: (row: Listing) => {
        if (row.items?.length > 0) {
          return CURRENT_CURRENCY + getMinMaxPrice(row.items);
        } else {
          return CURRENT_CURRENCY + 0;
        }
      },
    },
    {
      name: 'Status',
      width: '10%',
      selector: (row: Listing) => {
        return (
          <p
            className="p-1"
            style={{
              fontSize: '12px',
              borderRadius: '5px',
              color: 'white',
              backgroundColor:
                LISTING_STATUS_COLORS[
                  row.listing_status_num as keyof typeof LISTING_STATUS_COLORS
                ],
            }}
          >
            {
              LISTING_STATUS_TYPES[
                row.listing_status_num as keyof typeof LISTING_STATUS_TYPES
              ]
            }
          </p>
        );
      },
    },
    {
      name: 'Edit',
      width: '10%',
      cell: (row: Listing) => (
        <div
          onClick={() => {
            const navigationOptions: NavigateOptions = {
              // TODO removed previousPage: '/products'
              state: row,
            };
            navigate(`/products/listing-details/${row.id}`, navigationOptions);
          }}
        >
          <img src={externalLinkIcon_path} alt="route to listing page" />
        </div>
      ),
    },
  ];

  const isCategoryMatchSearch = (category: Category) => {
    if (!search) return true; // Include all categories if no search query
    const normalizedSearch = search.toLowerCase();
    return (
      category.name_en.toLowerCase().includes(normalizedSearch) ||
      category.name_ar.toLowerCase().includes(normalizedSearch)
    );
  };

  const filteredSearchCategoriesPredicate = (category: Category) => {
    if (search !== '') {
      return isCategoryMatchSearch(category);
    }
    return true; // Include all categories if no search query
  };

  const filteredItems = () => {
    if (search !== '') {
      const normalizedSearch = search.toLowerCase();

      return publicListings?.filter(
        (item) =>
          item.name_en.toLowerCase().includes(normalizedSearch) ||
          item.name_ar.toLowerCase().includes(normalizedSearch) ||
          item.id.toString().includes(normalizedSearch),
      );
    } else {
      return publicListings || []; // Return an empty array if publicListings is falsy
    }
  };

  // % PERF global state to save/cache products
  useEffect(() => {
    async function fetchSubCategories() {
      await ListingsRepository.paginateListingsDetails({
        store_id: currentStore.id,
        page: 1,
        per_page: 10000,
      }).then(async (res) => {
        if (!res) return;
        await ListingsRepository.getCategoryCountsOfListings(res!).then(
          (res) => {
            if (!res) return;
            setCategories(res);
          },
        );
        setListings(res!);
      });
      setLoading(false);
    }
    fetchSubCategories();
  }, []);

  const handleChangeInStockAndPrice = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        // TODO: fix any
        if (!response) return;
        if (response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems: Listing[] | undefined = publicListings?.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem) => dataItem.id === updatedItem.id,
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });
          setPublicListings(newItems?.filter((i) => i));
        }
      },
      failure: () => {},
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  return (
    <div>
      <Nav
        listings={listings}
        publicTemplates={publicTemplates}
        setSearch={setSearch}
      />
      <Row>
        <>
          {categories &&
            Object.keys(categories)
              // filter
              .filter((key) =>
                filteredSearchCategoriesPredicate(
                  categories[key as any].category,
                ),
              )
              .map((key, i) => (
                <Col lg={3} md={4} sm={6} key={i}>
                  <CategoryComponentWithImage
                    link={`/products/${categories[key as any].category.id}/subcategory`}
                    categoryName={categories[key as any].category.name_en}
                    items_length={categories[key as any].count}
                    category_image={
                      categories[key as any].category.category_image
                    }
                  />
                </Col>
              ))}
        </>
        {/* )} */}
      </Row>
      <Row className="pt-3">
        <Col lg={12} md={12} sm={12}>
          <Heading>General</Heading>
        </Col>
        <Col lg={3} md={4} sm={6}>
          <CategoryComponent
            link="/products/templates"
            state={{
              publicTemplates: publicTemplates,
              listings: listings,
            }}
            categoryName="Templates"
            items_length={publicTemplates.length}
            className="grey"
            sub_categories_length={0} // TODO fix
          />
        </Col>
        <Col lg={3} md={4} sm={6}>
          <CategoryComponent
            link="/products/submitted"
            state={listings?.filter(
              (x) => x.listing_type_value === LISTING_TYPES.public_listing,
            )}
            categoryName="Submitted"
            items_length={
              listings?.filter(
                (x) => x.listing_type_value === LISTING_TYPES.public_listing,
              ).length ?? 0
            }
            sub_categories_length={0} // TODO fix
            className="grey"
          />
        </Col>
      </Row>
      <Row className="pt-3" style={{ overflowX: 'auto' }}>
        {loading ? (
          <Spinner />
        ) : (
          <TableStyled>
            <h3 className="h3">All Products</h3>
            {filteredItems()?.length === 0 ? (
              <div className="no-orders">
                <img src={notFoundIllustration_path} alt="no-orders" />
                <h3>No products were found</h3>
              </div>
            ) : (
              <DataTable
                columns={columns as any}
                data={filteredItems() ?? []}
                customStyles={customStyles}
                pagination
                paginationPerPage={200}
                expandableRows
                expandOnRowClicked
                expandableRowsComponent={ExpandedComponent(
                  handleChangeInStockAndPrice,
                )}
                expandableRowsComponentProps={{
                  someTitleProp: 'someTitleProp',
                }}
                // selectableRows
              />
            )}
          </TableStyled>
        )}
      </Row>
    </div>
  );
};

export const ExpandedComponent = (
  handleChangeInStockAndPrice?: (item: ListingItem, data: Listing) => void,
) => {
  const ExpandedComponentWrapper = ({ data }: { data: Listing }) => {
    return (
      <ExpandListingInfoInProductsTableWrapper key={data.id}>
        <Table className="table">
          {getExpandableTableHeaders(data)}
          {getExpandableTableBody(data, handleChangeInStockAndPrice)}
        </Table>
      </ExpandListingInfoInProductsTableWrapper>
    );
  };

  // Add the displayName property to the inner component function
  ExpandedComponentWrapper.displayName = 'ExpandedComponent';

  return ExpandedComponentWrapper;
};

export default Products;
