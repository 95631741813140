import { getCurrentStore } from 'core/hooks/use_selector';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_STORE_IMAGES,
  actionSetLastFetchedStoreImages,
  actionSetStoreImages,
} from 'silal_app_base_react/src/store/images/image_library_actions';
import {
  SellerFilesRepository,
  SellerFilesResponse,
} from 'data/repositories/files_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';

export function* sagaGetStoreImages() {
  function* getStoreImages(action: any) {
    const currentStore: StoreDetails = yield select(getCurrentStore);
    const res: SellerFilesResponse = yield call(
      SellerFilesRepository.getStoreImages,
      currentStore.id,
      action.payload.data.page,
      action.payload.data.limit,
    );
    yield put(actionSetStoreImages(res?.files));
    yield put(actionSetLastFetchedStoreImages(new Date().getTime()));
    action.payload.success(res);
  }
  yield takeLatest(GET_STORE_IMAGES, getStoreImages);
}
