import React from 'react';

export default function Parcel({ active, width, height }) {
  return (
    <svg
      width={width || '35'}
      height={height || '40'}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5812 3.25392C25.2082 3.10368 24.7918 3.10368 24.4188 3.25392L5.76875 10.7493L13.2812 13.7669L32.5125 6.03917L25.5812 3.25392ZM36.7188 7.73168L17.4875 15.4594L25 18.4771L44.2313 10.7493L36.7188 7.73168ZM46.875 13.0698L26.5625 21.234V46.1098L46.875 37.9456V13.073V13.0698ZM23.4375 46.1129V21.2309L3.125 13.0698V37.9487L23.4375 46.1129ZM23.2594 0.336776C24.3768 -0.112259 25.6232 -0.112259 26.7406 0.336776L49.0187 9.2923C49.3084 9.40894 49.5567 9.61005 49.7315 9.86973C49.9064 10.1294 49.9999 10.4358 50 10.7493V37.9487C49.9996 38.5762 49.8121 39.1891 49.4618 39.7085C49.1114 40.2279 48.6143 40.6299 48.0344 40.8627L25.5812 49.8873C25.2082 50.0376 24.7918 50.0376 24.4188 49.8873L1.96875 40.8627C1.38828 40.6304 0.890445 40.2286 0.539506 39.7092C0.188568 39.1898 0.000644779 38.5765 0 37.9487L0 10.7493C7.26248e-05 10.4358 0.0935742 10.1294 0.268459 9.86973C0.443345 9.61005 0.691602 9.40894 0.98125 9.2923L23.2594 0.336776Z"
        fill={`${active ? '#05AE4B' : '#E8E8E8'}`}
      />
    </svg>
  );
}
