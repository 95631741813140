import React from 'react';

export default function SmallParcel({ active, width, height }) {
  return (
    <svg
      width={width || '35'}
      height={height || '40'}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 0H3.5C2.57174 0 1.6815 0.409721 1.02513 1.13903C0.368749 1.86834 0 2.85749 0 3.88889V7.77778C0.0036868 8.45644 0.167156 9.12222 0.474161 9.70894C0.781166 10.2957 1.22102 10.7829 1.75 11.1222V31.1111C1.75 32.1425 2.11875 33.1317 2.77513 33.861C3.4315 34.5903 4.32174 35 5.25 35H29.75C30.6783 35 31.5685 34.5903 32.2249 33.861C32.8813 33.1317 33.25 32.1425 33.25 31.1111V11.1222C33.779 10.7829 34.2188 10.2957 34.5258 9.70894C34.8328 9.12222 34.9963 8.45644 35 7.77778V3.88889C35 2.85749 34.6313 1.86834 33.9749 1.13903C33.3185 0.409721 32.4283 0 31.5 0ZM3.5 3.88889H31.5V7.77778H3.5V3.88889ZM5.25 31.1111V11.6667H29.75V31.1111H5.25Z"
        fill={`${active ? '#05AE4B' : '#E8E8E8'}`}
      />
    </svg>
  );
}
