import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { successIcon_path } from 'assets/index';
import { Table } from 'react-bootstrap';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';

type StockTrackingModalProps = {
  text: string;
  orderDetails: OrderDetails;
};

type StockTrackingItem = {
  item_id: number;
  itemName: string;
  attributes: string;
  price: string;
  in_stock: boolean;
};
function StockTrackingModal({ text, orderDetails }: StockTrackingModalProps) {
  const [modal, setModal] = useState(true);
  const [orderItems, setOrderItems] = useState<StockTrackingItem[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fillItemsList = () => {
      const res: StockTrackingItem[] = [];
      orderDetails.items.forEach((item) => {
        res.push({
          item_id: item.item.id,
          itemName: item.item.name_en, // TODO localize
          attributes: item.item.attributes
            ? item.item.attributes
                .map((attr) => attr.attr_value_en + '-' + attr.attr_value_ar)
                .join(', ')
            : 'None',
          price: item.item.price,
          in_stock: false,
        });
      });
      setOrderItems(res);
    };
    fillItemsList();
  }, [orderDetails.items]);

  const handleCheckboxChange = (item: StockTrackingItem) => {
    setOrderItems((prevOrderItems) => {
      return prevOrderItems.map((prevItem) => {
        if (prevItem.item_id === item.item_id) {
          // Toggle the stock value for the clicked item
          return {
            ...prevItem,
            in_stock: !prevItem.in_stock,
          };
        }
        return prevItem;
      });
    });
  };

  const submit = () => {
    const payload = {
      data: {
        items: orderItems.map((item) => ({
          item_id: item.item_id,
          in_stock: item.in_stock,
          price: Number.parseFloat(item.price),
        })),
      },
      success: () => {
        setOrderItems([]);
        setModal(false);
        navigate('/orders/current-orders', { replace: true });
      },
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  return (
    <>
      <Button style={{ width: '100%' }} onClick={() => setModal(true)}>
        {text}
      </Button>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered
        size="xl"
        dialogClassName="barcode-tracking-modal"
      >
        <Modal.Header style={{ paddingBottom: '0px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <img
              src={successIcon_path}
              alt="icon"
              width={100}
              height={100}
              style={{ margin: 'auto' }}
            />
            <p
              style={{ padding: '5px', fontSize: '15px', textAlign: 'center' }}
            >
              Silal Captain is on his way
              <br /> to collect the order!
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row justify-content-between">
            <Modal.Title>Do you still have these items in stock?</Modal.Title>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <p className="desc light-description py-1">
                  To prevent undesired events for your next customers, please
                  uncheck items that you don’t have in stock
                </p>
              </div>
            </div>
            <div
              style={{
                height: '250px',
                overflowY: 'auto',
                borderRadius: '5px',
              }}
            >
              <Table bordered hover>
                <thead style={{ backgroundColor: '#E5EAEB' }}>
                  <tr>
                    <th>Item name</th>
                    <th>Attributes</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((item) => (
                    <tr
                      key={item.item_id}
                      style={{
                        backgroundColor: '#FAFAFA',
                        borderRadius: '8px',
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={item.in_stock}
                          onChange={() => handleCheckboxChange(item)}
                          style={{
                            border: '2px solid #4C6870',
                            borderRadius: '3px',
                          }}
                        />
                        {'  '}
                        {item.itemName}
                      </td>
                      <td>{item.attributes}</td>
                      <td>{formatPrice(item.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button className="m-0 w-25" onClick={submit}>
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StockTrackingModal;
