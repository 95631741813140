import styled from 'styled-components';
import { Fonts } from 'silal_app_base_react/src/config/theme';
export const ReceivedOrderStyled = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-bottom: 20px;
  transition: 0.2s all;
  &:hover {
    transform: scale(1.02);
  }
  a {
    text-decoration: none;
  }
  .between {
    margin-bottom: 18px;
    h1 {
      font-size: 15px;
      color: #ccd4d6;
      margin: 0;
      span {
        color: #002733;
        margin: 0 0 0 5px;
      }
    }
    h2 {
      font-size: 15px;
      text-align: right;
      color: #002733;
      margin: 0;
    }
  }
  .order-details {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    span:first-of-type {
      min-width: 10px;
      display: block;
    }
    span {
      font-size: 16px;
      color: #002733;
    }
    img {
      margin: 0 7px;
    }
  }
  .more {
    font-weight: 500;
    font-size: 12px;
    color: #05ae4b;
    background: rgba(90, 179, 168, 0.15);
    border-radius: 4px;
    padding: 2px 5px;
    margin-bottom: 10px;
    margin-left: 34px;
    cursor: pointer;
  }
  .clock-icon {
    padding: 3px 11px;
    background: #f2a341;
    border-radius: 0px 0px 5px 5px;
    height: 33px;
    width: 50px;
    margin: -10px 10px 0 0;
  }
`;

export const AcceptedOrderStyled = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-bottom: 20px;
  transition: 0.2s all;
  &:hover {
    transform: scale(1.02);
  }
  a {
    text-decoration: none;
  }
  .between {
    margin-bottom: 18px;
    h1 {
      font-size: 15px;
      color: #ccd4d6;
      margin: 0;
      span {
        color: #002733;
        margin: 0 0 0 5px;
      }
    }
    h2 {
      font-size: 15px;
      text-align: right;
      color: #002733;
      margin: 0;
    }
  }
  .icon-div {
    border-radius: 5px;
    height: 44px;
    min-width: 44px;
    width: 44px;
    margin-right: 10px;
    &.blue {
      background: #deebff;
    }
    &.purple {
      background: #eae6ff;
    }
  }
  h3 {
    font-weight: normal;
    font-size: 17px;
    letter-spacing: -0.408px;
    color: #000000;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
  }
  .clock-icon {
    padding: 3px 11px;
    background: #f2a341;
    border-radius: 0px 0px 5px 5px;
    height: 33px;
    width: 50px;
    margin: -10px 10px 0 0;
  }
`;

export const PickupOrderStyled = styled.div`
  background: #e5eaeb;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  transition: 0.2s all;
  &:hover {
    transform: scale(1.03);
  }
  h6 {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.408px;
    margin: 0;
  }
  p {
    font-weight: normal;
    font-size: 15px;
    color: #4c6870;
    margin: 0;
  }
`;

export const PickupCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #4c6870;
  height: calc(100vh - 100px);
  min-height: 450px;
  .body {
    padding: 20px;
    height: calc(100% - 65px);
    overflow-y: auto;
  }
  .bottom {
    background: #05ae4b;
    border-radius: 0px 0px 5px 5px;
    height: 65px;
    width: 100%;
    padding: 12px 20px;
    h2 {
      font-weight: bold;
      font-size: 15px;
      font-family: ${Fonts.secondary};
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 5px;
    }
    h3 {
      font-family: ${Fonts.secondary};
      font-weight: normal;
      font-size: 15px;
      color: #ffffff;
      margin: 0;
    }
    svg {
      margin-right: 8px;
    }
  }
`;

export const PreOrderStyled = styled(ReceivedOrderStyled)`
  .between {
    margin-bottom: 20px;
    h2 {
      background: rgba(242, 163, 65, 0.15);
      border-radius: 4px;
      padding: 5px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.408px;
      text-transform: uppercase;
      color: #f2a341;
    }
  }
  button {
    margin-top: 15px;
    &:first-of-type {
      flex: 1;
      max-width: 200px;
      margin-right: 5px;
    }
    &:last-of-type {
      flex: 2;
    }
  }
`;
export const PreOrderStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 76px;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 12px;
  text-align: center;
  h1 {
    font-family: ${Fonts.primary};
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.408px;
    color: #002733;
    margin: 0;
  }
  p {
    font-family: ${Fonts.secondary};
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    color: #4c6870;
    margin: 0;
  }
`;
