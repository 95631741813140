import { Col, Row } from 'react-bootstrap';
import { DateRangePicker } from 'rsuite';
import GlobalStyle from 'styles/global_styled';
import { TableStyled } from 'styles/style';
import { SearchWindow } from 'components/show_full_elements';
import TopNav from '../components/profile_navbar';
import DataTable from 'react-data-table-component';
export const LedgerHistory = () => {
  const columns = [
    {
      name: 'Order ID',
      selector: (row: any) => row.order,
    },
    {
      name: 'Customer ID',
      selector: (row: any) => row.customer,
    },
    {
      name: 'Order Date',
      selector: (row: any) => row.date,
    },
    {
      name: 'Payment',
      selector: (row: any) => row.payment,
    },
    {
      name: 'Amount',
      selector: (row: any) => row.amonut,
    },
    {
      name: 'Balance',
      selector: (row: any) => row.balance,
    },
  ];

  const data = [
    {
      id: 1,
      order: '#723DN2',
      customer: '2384',
      date: '312.12.2022',
      payment: <span className="stock-status credit">Credit card</span>,
      amonut: '$ 13.90',
      balance: '$ 1272.20',
    },
    {
      id: 2,
      order: '#723DN2',
      customer: '2384',
      date: '312.12.2022',
      payment: <span className="stock-status bank">Bank wires</span>,
      amonut: '$ 13.90',
      balance: '$ 1272.20',
    },
    {
      id: 3,
      order: '#723DN2',
      customer: '2384',
      date: '312.12.2022',
      payment: <span className="stock-status credit">Credit card</span>,
      amonut: '$ 13.90',
      balance: '$ 1272.20',
    },
    {
      id: 4,
      order: '#723DN2',
      customer: '2384',
      date: '312.12.2022',
      payment: <span className="stock-status credit">Credit card</span>,
      amonut: '$ 13.90',
      balance: '$ 1272.20',
    },
    {
      id: 5,
      order: '#723DN2',
      customer: '2384',
      date: '312.12.2022',
      payment: <span className="stock-status credit">Credit card</span>,
      amonut: '$ 13.90',
      balance: '$ 1272.20',
    },
  ];
  return (
    <>
      <GlobalStyle />
      <div className="d-flex">
        <div className="w-100 px-3">
          <TopNav />
          <Row>
            <Col lg={5} md={6}>
              <SearchWindow height="36px" />
            </Col>
            <Col lg={7} md={6} className="end">
              <div className="archive-range">
                <DateRangePicker
                  className="archive-table-range"
                  placement="bottomEnd"
                  showOneCalendar
                  format="dd MMMM yy"
                  placeholder="dd mm yy"
                  character="&#160;&#160;-&#160;&#160;"
                />
              </div>
            </Col>
          </Row>
          <TableStyled>
            <DataTable columns={columns} data={data} />
          </TableStyled>
        </div>
      </div>
    </>
  );
};
