import { ChangeEvent, useState } from 'react';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Backbutton from 'pages/auth/components/back_button';
import Wrapper from './create_new_password_screen.styled';
// import icons
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import * as userAction from 'store/user/user_actions';
import Spinner from 'silal_app_base_react/src/components/spinner';
import Error from 'silal_app_base_react/src/components/error';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { toast } from 'react-toastify';
import SellerRepository from 'data/repositories/seller_repository';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button } from '@nextui-org/react';

function CreateNewPasswordScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading } = useSelector(getCurrentUserState);
  const [showPassword] = useState(true);
  const [showPassword2] = useState(true);
  const [errors, setErrors] = useState({
    pass: '',
    cpass: '',
  });
  const [password, setPassword] = useState({
    pass: '',
    cpass: '',
  });
  const [error] = useState('');

  const dispatch = useDispatch();
  // password validation states
  const [char, setChar] = useState(false);
  const [letter, setLetter] = useState(false);
  const [number, setNumber] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [attemptedToSubmit, setAttemptedToSubmit] = useState(false);

  function handleRoute() {
    if (location.state === 'signUpEmailVerification') {
      navigate('/seller-info', { state: 'createNewPassword' });
    } else if (location.state === 'resetpasswordemailverify') {
      navigate('/login', { state: 'createNewPassword' });
    } else {
      navigate('/seller-info', { state: 'createNewPassword' });
    }
  }
  // password validation
  function handleKeyup() {
    // characters validation 8 -- 20
    const v = password.pass.length;
    if (v >= 8 && v <= 20) {
      setChar(true);
    } else {
      setChar(false);
    }
    // letter validation upper or lower case
    const l = /[a-zA-z]/g;
    if (password.pass.match(l)) {
      setLetter(true);
    } else {
      setLetter(false);
    }
    // number validation 0-9
    const n = /[0-9]/g;
    if (password.pass.match(n)) {
      setNumber(true);
    } else {
      setNumber(false);
    }
    // special characters validation
    const s = /[!@#$%^&*)(+=._-]/g;
    if (password.pass.match(s)) {
      setSpecialChar(true);
    } else {
      setSpecialChar(false);
    }
  }

  const handleValidations = () => {
    const errFields = {
      pass: '',
      cpass: '',
    };
    let error = false;

    Object.keys(password).map(function (keyName) {
      const value = password[keyName as keyof typeof password];

      if (keyName === 'pass') {
        if (!value) {
          errFields.pass = 'ⓘ Password is required';
          error = true;
        }
      }

      if (keyName === 'cpass') {
        if (!value) {
          errFields.cpass = 'ⓘ Confirm password is required';
          error = true;
        }
      }
      return null;
    });
    setErrors(errFields);
    return error;
  };

  // setting the input value to the state
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setAttemptedToSubmit(false);
    setPassword({ ...password, [e.target.id]: e.target.value });
  }

  const handleSkip = () => {
    navigate('/seller-info', { state: 'createnewpassword' });
  };

  const createNewPassword = async () => {
    setAttemptedToSubmit(true);

    if (handleValidations()) {
      return;
    }

    let error = false;

    if (!char) {
      toast.warn('Password must be 8 to 20 characters');
      error = true;
    }
    if (!letter) {
      toast.warn('Password must contain letters');
      error = true;
    }
    if (!number) {
      toast.warn('Password must contain numbers');
      error = true;
    }
    if (!specialChar) {
      toast.warn('Password must contain special characters');
      error = true;
    }

    if (error) {
      return false;
    }

    if (password.pass !== password.cpass) {
      toast.error('Password does not match');
      return;
    }

    await SellerRepository.addPassword(password.pass, password.cpass).then(
      (res) => {
        if (!res) {
          return;
        }
        dispatch(
          userAction.actionGetSetUserProfile({
            success: () => handleRoute(),
          }),
        );
      },
    );
  };

  return (
    <BackgroundContainer>
      <Wrapper>
        <div className="top-row">
          <div className="title flex flex-row justify-center items-center">
            <Backbutton primaryIcon={true} />
            <h2 className="primary-font-font mx-auto">Create a new password</h2>
          </div>
          <FormControl
            labelValue="Password"
            htmlFor="pass"
            input={true}
            type={showPassword ? 'password' : 'text'}
            onChange={handleChange}
            onKeyUp={handleKeyup}
            placeholder="Enter password"
            passwordType={true}
          />
          {errors.pass ? <Error text={errors.pass} /> : ' '}
          <FormControl
            labelValue="Confirm Password"
            htmlFor="cpass"
            input={true}
            type={showPassword2 ? 'password' : 'text'}
            onChange={handleChange}
            placeholder="Enter password"
            passwordType={true}
          />
          {errors.cpass ? <Error text={errors.cpass} /> : ' '}
          <div className="password-restriction">
            <h3 style={{ fontFamily: Fonts.secondary }}>
              Your password should contain:
            </h3>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={char ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !char
                    ? 'not-contains'
                    : char
                      ? 'contains'
                      : ''
                }
              >
                8 to 20 characters
              </span>
            </p>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={letter ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !letter
                    ? 'not-contains'
                    : letter
                      ? 'contains'
                      : ''
                }
              >
                Upper and lower case letters
              </span>
            </p>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={number ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !number
                    ? 'not-contains'
                    : number
                      ? 'contains'
                      : ''
                }
              >
                Numbers
              </span>
            </p>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={specialChar ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !specialChar
                    ? 'not-contains'
                    : specialChar
                      ? 'contains'
                      : ''
                }
              >
                Special characters
              </span>
            </p>
          </div>
          <div>
            {error !== '' ? <span className="text-danger">{error}</span> : null}
          </div>
          <div className="form-submit">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  onClick={handleSkip}
                  color="success"
                  variant="flat"
                  className="mt-3 w-full"
                >
                  No, I prefer passwordless login
                </Button>
                <Button
                  onClick={createNewPassword}
                  className="mt-2 w-full"
                  color={
                    !char || !letter || !number || !specialChar
                      ? 'default'
                      : 'success'
                  }
                  isDisabled={!char || !letter || !number || !specialChar}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default CreateNewPasswordScreen;
