import { motion } from 'framer-motion';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import * as userAction from 'store/user/user_actions';
import Spinner from 'silal_app_base_react/src/components/spinner';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Backbutton from 'pages/auth/components/back_button';
import CountrySelectForm from 'components/select_country_form';
import Error from 'silal_app_base_react/src/components/error';
import Wrapper from './login_screen.styled';
import Button from 'silal_app_base_react/src/components/buttons';
import { toast } from 'react-toastify';
import { CurrentContries } from 'core/constants/constants';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { Country } from 'data/types/general';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button as ButtonNext } from '@nextui-org/react';

function Loginpage() {
  const { user } = useSelector(getCurrentUserState);

  const [phoneNo, setPhoneNo] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNumForm, setShowNumForm] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const [selectedCountry, setSelectedCountry] = useState<Country>(
    CurrentContries[0],
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading } = useSelector(getCurrentUserState);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state?.email);
      setShowNumForm(false);
    }
  }, []);

  // animation phone number field
  const animate1 = {
    x: [-150, 0],
    opacity: [0, 1],
    transition: { duration: 0.4 },
  };

  // animation email input field
  const animate2 = {
    x: [150, 0],
    opacity: [0, 1],
    transition: { duration: 0.4 },
  };

  const sendLoginOtp = async () => {
    if (!phoneNo) {
      toast.warn('Please enter your Phone number.');
      return;
    }

    if (
      phoneNo.length < 9 ||
      phoneNo.length > 10 ||
      !FormValidators.isPhoneNumber(phoneNo)
    ) {
      toast.warn('Please enter a valid Phone number.');
      return;
    }
    await AuthenticationRepository.loginSendPhoneOTP(
      selectedCountry.value + phoneNo,
    ).then((res) => {
      if (!res) return;
      toast.success(res.msg);
      navigate('/phone-otp-verification', {
        state: {
          phoneNo: selectedCountry.value + phoneNo,
          previousPage: 'login',
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  const loginWithEmailPassword = async () => {
    setErrors({
      email: '',
      password: '',
    });

    const errFields = {
      email: '',
      password: '',
    };
    if (!email) {
      if (!email) {
        errFields.email = 'ⓘ Email is Required.';
      }
      if (!FormValidators.isEmail(email)) {
        errFields.email = 'ⓘ Incorrect Email.';
      }
    }
    if (!password) {
      errFields.password = 'ⓘ Password is Required.';
    }

    if (errFields.email || errFields.password) {
      return setErrors(errFields);
    }

    const data = {
      email,
      password,
    };
    const payload = {
      data,
      success: async () => {
        if (user?.enabled_mfa) {
          navigate('/mfa');
        }
      },
      failure: (error: any) => {
        toast.error(error);
      },
    };

    dispatch(userAction.actionLoginWithEmailPassword(payload));
  };

  const handleEmailOtp = async () => {
    setErrors({
      email: '',
      password: '',
    });

    const errFields = {
      email: '',
      password: '',
    };
    if (!email) {
      errFields.email = 'ⓘ Email is Required.';
    } else if (!FormValidators.isEmail(email)) {
      errFields.email = 'ⓘ Incorrect Email.';
    }

    if (errFields.email) {
      return setErrors(errFields);
    }

    await AuthenticationRepository.loginSendEmailOTP(email).then((res) => {
      if (!res) return;
      toast.success(res.msg);
      navigate('/email-otp-verification', {
        state: {
          previousPage: 'login',
          email: email,
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  function BottomSection() {
    return (
      <div className="bottom-row">
        <div className="form-submit">
          {loading ? (
            <Spinner />
          ) : (
            <div>
              {showNumForm ? (
                <ButtonNext
                  className={'w-100'}
                  color="success"
                  onClick={sendLoginOtp}
                >
                  Continue
                </ButtonNext>
              ) : (
                <div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <ButtonNext
                        className={'w-100 mt-0 mb-2 h-11'}
                        onClick={handleEmailOtp}
                        color="success"
                        variant="flat"
                      >
                        Use Email OTP Instead
                      </ButtonNext>
                      <ButtonNext
                        className={'w-100 h-11'}
                        onClick={loginWithEmailPassword}
                        color="success"
                      >
                        Log in
                      </ButtonNext>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {!showNumForm && (
          <div className="forgot-pass-text">
            <p>
              Trouble signing in?
              <Link to="/forgot-password">Forgot password</Link>
            </p>
          </div>
        )}
      </div>
    );
  }
  return (
    <BackgroundContainer>
      <motion.div
        initial={{ opacity: 0 }}
        animate={showNumForm ? animate1 : animate2}
      >
        <Wrapper>
          <div className="top-row">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                baselineShift: 'center',
              }}
            >
              <div className="go-back-button">
                <Backbutton primaryIcon={true} url="/welcome" />
              </div>
              <div className="title">
                <h4>Log In</h4>
              </div>
              <div />
            </div>
            <div className="buttonGroups">
              <Button
                text="Phone Number"
                onClick={() => {
                  setShowNumForm(true);
                }}
                color={!showNumForm ? 'grey' : '#fff'}
                bg={!showNumForm ? 'transparent' : Colors.greenMain}
                style={{
                  fontSize: '14px',
                }}
              />
              <Button
                text="Email"
                onClick={() => {
                  setShowNumForm(false);
                }}
                bg={showNumForm ? 'transparent' : Colors.greenMain}
                color={showNumForm ? 'grey' : '#fff'}
                style={{
                  fontSize: '14px',
                }}
              />
            </div>
            <div className="form">
              {showNumForm ? (
                <div className="form-control-mobile">
                  <FormControl
                    labelValue="Phone Number"
                    htmlFor="PhoneNumber"
                  />
                  <div
                    className="row"
                    style={{
                      border: 'none',
                    }}
                  >
                    <div className="col1 col">
                      <CountrySelectForm
                        value={selectedCountry}
                        setSelectedCountry={(value) =>
                          setSelectedCountry(value)
                        }
                      />
                    </div>
                    <div className="col2 col px-0">
                      <FormControl
                        style={{
                          margin: '0px',
                          width: '95%',
                        }}
                        input={true}
                        type="text"
                        htmlFor="PhoneNumber"
                        maxLength={10}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setPhoneNo(e.target.value);
                        }}
                        placeholder="0541234567"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      color: 'grey',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {phoneNo.length + '/10'}
                  </div>
                  {BottomSection()}
                </div>
              ) : (
                <div className="form-control-email">
                  <div className="row">
                    <FormControl
                      labelValue="Email"
                      htmlFor="email"
                      input={true}
                      type="text"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      placeholder="email.example@gmail.com"
                    />
                    {errors.email ? <Error text={errors.email} /> : ''}
                  </div>
                  <div className="row">
                    <div className="password">
                      <FormControl
                        labelValue="Password"
                        htmlFor="password"
                        input={true}
                        type={showPass ? 'text' : 'password'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPassword(e.target.value)
                        }
                        placeholder="Enter password"
                        passwordType={true}
                        onClick={() => setShowPass(!showPass)}
                      />
                      {errors.password ? <Error text={errors.password} /> : ''}
                    </div>
                    {BottomSection()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      </motion.div>
    </BackgroundContainer>
  );
}

export default Loginpage;
