import { Route, Routes } from 'react-router-dom';
import SellerInfoFormScreen from 'pages/auth/screens/seller_info_form/seller_info_form_screen';
import SetupEmailScreen from 'pages/auth/screens/signup/email_signup_screen';
import CreateNewPasswordScreen from 'pages/auth/screens/create_new_password/create_new_password_screen';

// import SignUpPassword from 'pages/auth/screens/signup/setup_password_form_screen';
// import {
//    Page404,
//   NotFoundPage,
// } from 'pages/404/page_404_screen';
import { AuhtneticationPagesHandler } from 'routes/handlers/authentication_pages_handler';
import EmailLoginOTPScreen from 'pages/auth/screens/otp/email_otp_screen';

const AuhtorizedViewRouter = () => {
  return (
    <AuhtneticationPagesHandler>
      <Routes>
        {/* Seller Info */}
        <Route path="/">
          <Route index element={<SetupEmailScreen />} />
          <Route path="seller-info" element={<SellerInfoFormScreen />} />
        </Route>

        {/* Setup Email */}
        <Route path="/signup-email" element={<SetupEmailScreen />} />

        {/* Login Otp Email */}
        <Route
          path="/email-otp-verification"
          element={<EmailLoginOTPScreen />}
        />

        {/* Create New Password */}
        <Route path="/create-password" element={<CreateNewPasswordScreen />} />

        {/* 404 */}
        {/* <Route path="/404" element={<Page404 time={3} />} /> */}
        <Route path="*" element={<SetupEmailScreen />} />
      </Routes>
    </AuhtneticationPagesHandler>
  );
};

export default AuhtorizedViewRouter;
