import { ChangeEvent, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { useNavigate } from 'react-router-dom';

import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import CountrySelectForm from 'components/select_country_form';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Backbutton from 'pages/auth/components/back_button';
import Error from 'silal_app_base_react/src/components/error';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { BackgroundContainer } from 'pages/auth/components/background_container';

function PhoneSignupScreen() {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState('');
  const [error, setError] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({
    label: `IL +972`,
    value: '+972',
    default: true,
  });

  const animate1 = {
    x: [-100, 0],
    opacity: [0, 1],
    transition: { duration: 0.5 },
  };

  useEffect(() => {}, []);

  const setPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue(e.target.value);
  };

  const sendToOtp = async () => {
    if (!formValue) {
      setError('Please enter a phone number');
      return;
    }
    if (!FormValidators.isPhoneNumber(formValue)) {
      setError('Please enter a valid phone number 05XXXXXXXX');
      return;
    }
    await AuthenticationRepository.signupSendPhoneOTP(
      selectedCountry.value + formValue,
    ).then((res) => {
      if (!res) return;
      navigate('/phone-otp-verification', {
        state: {
          phoneNo: selectedCountry.value + formValue,
          previousPage: 'signup',
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  return (
    <BackgroundContainer>
      <Wrapper>
        <div className="top-row">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              baselineShift: 'center',
            }}
          >
            <div className="go-back-button">
              <Backbutton primaryIcon={true} />
            </div>
            <div className="title" style={{ marginTop: '16px' }}>
              <h4>Sign Up</h4>
            </div>
            <div />
          </div>
          <div className="text-center">
            <Button className="methodTabButton">Phone Number</Button>
            <div className="form">
              <motion.div className="form-control-mobile" animate={animate1}>
                <FormControl
                  labelValue="Phone Number"
                  htmlFor="PhoneNumber"
                  style={{
                    margin: '0px',
                  }}
                />
                <div
                  className="row "
                  style={{
                    border: 'none',
                  }}
                >
                  <div className="col1 col">
                    <CountrySelectForm
                      value={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                    />
                  </div>
                  <div className="col2 col  mx-0 px-1">
                    <FormControl
                      style={{ border: 'none', margin: '0px' }}
                      input={true}
                      value={formValue}
                      type="number"
                      htmlFor="PhoneNumber"
                      maxLength={10}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > 10) return;
                        setPhone(e);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: 'grey',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {formValue.length + '/10'}
                  </div>
                </div>
                <div>{error ? <Error text={error} /> : ' '}</div>
                <div className="d-flex justify-content-center align-items-center my-4">
                  <Button onClick={sendToOtp} className="submitButton next">
                    Next
                  </Button>
                </div>
              </motion.div>
            </div>
            <div>
              <h6>
                By signing up, you agree to the{' '}
                <span className="colored">Terms of Service</span> and
                <span className="colored"> Privacy Policy </span>
              </h6>
            </div>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default PhoneSignupScreen;

const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: auto;
  .top-row {
    .title {
      padding: 25px 0;
      h1 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form-control-mobile {
        margin-top: 20px;
        label {
          font-size: 15px;
          font-weight: 700;
          color: #4c6870;
        }
        .row {
          border: 1px solid #ededed;
          border-radius: 10px;
          margin-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .col1 {
            max-width: 130px;
            width: 100%;
            .value-container {
              border-right: none;
              border-radius: 5px 0px 0px 5px;
            }
          }
          .col2 {
            input {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }
      }
      /* email form design  */
      .form-control-email {
        .row {
          margin-top: 20px;
          label {
            font-size: 15px;
            font-weight: 700;
            color: #4c6870;
          }
        }
      }
    }
  }

  .next {
    margin-top: 200px;
  }
  .methodTabButton {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 60%;
  }
  .submitButton {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 100%;
    margin-top: 50px;
  }
  h6 {
    margin: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #4c7061;
  }
  .colored {
    font-weight: 500;

    color: #05ae4b;
  }
`;
