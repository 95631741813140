import { Route, Routes } from 'react-router-dom';
import Loginpage from 'pages/auth/screens/login/login_screen';
import PhoneSignupScreen from 'pages/auth/screens/signup/phone_signup_screen';
import PhoneLoginOTPScreen from 'pages/auth/screens/otp/phone_otp_screen';
import LoginSignupSwitch from 'pages/auth/screens/login_signup_switch/login_signup_switch';
import Resetpass from 'pages/auth/screens/reset_password/reset_password_screen';
import EmailLoginOTPScreen from 'pages/auth/screens/otp/email_otp_screen';

import SplashScreen from 'pages/auth/screens/splash/splash_screen';

import { Page404, NotFoundPage } from 'pages/404/page_404_screen';
import { AuhtneticationPagesHandler } from 'routes/handlers/authentication_pages_handler';
import { SilalLandingIndex } from 'pages/landing';
import { SilalLandingAbout } from 'pages/landing/abouts';

const UnauthenticatedViewRouter = () => {
  return (
    <AuhtneticationPagesHandler>
      <Routes>
        {/* Splash */}
        <Route path="/" element={<SplashScreen />} />

        {/* Index */}
        <Route path="/landing" element={<SilalLandingIndex />} />

        {/* About */}
        <Route path="/about" element={<SilalLandingAbout />} />

        {/* LoginSignupSwitch */}
        <Route path="/welcome" element={<LoginSignupSwitch />} />

        {/* Login */}
        <Route path="/login" element={<Loginpage />} />

        {/* Sign up phone */}
        <Route path="/signup" element={<PhoneSignupScreen />} />

        {/* Otp Phone */}
        <Route
          path="/phone-otp-verification"
          element={<PhoneLoginOTPScreen />}
        />

        {/* Login Otp Email */}
        <Route
          path="/email-otp-verification"
          element={<EmailLoginOTPScreen />}
        />

        {/* Reset password */}
        <Route path="/forgot-password" element={<Resetpass />} />

        {/* 404 */}
        <Route path="/404" element={<Page404 path="/welcome" time={5} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuhtneticationPagesHandler>
  );
};

export default UnauthenticatedViewRouter;
