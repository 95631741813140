import { Form } from 'react-bootstrap';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

type FormInputProps = {
  label: string;
  type: string;
  onChange?: Function;
  asterik?: string;
  placeholder?: string;
  optional?: string;
  bold?: string;
  value?: string;
  name?: string;
  style?: React.CSSProperties;
};

function FormInput({
  label,
  type,
  asterik,
  placeholder,
  optional,
  bold,
  onChange,
  value,
  name,
  style,
}: FormInputProps) {
  return (
    <FormStyle>
      <Form.Group className="mb-3">
        <Form.Label className={`label  ${bold}`}>
          {label} <span className="asterik ml-1">{asterik}</span>{' '}
          <span className="optional">{optional}</span>
        </Form.Label>
        <Form.Control
          name={name}
          className="control"
          type={type}
          value={value}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          placeholder={placeholder}
          style={style}
        />
      </Form.Group>
    </FormStyle>
  );
}

export default FormInput;

const FormStyle = styled.div`
  .form-control {
    width: 100%;
  }
  .control {
    padding: 13px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
  }
  .asterik {
    color: red;
  }
  .label {
    font-family: ${Fonts.secondary};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
  }
  .optional {
    color: #ccd4d6;
  }
  .bold {
    // font-size:
  }
`;
