import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 40vw;
  border-radius: 30px;
  background: #ffffff;
  .inner-content {
    align-items: center;
    justify-content: center;
  }
  .box {
    background: #05ae4b;
    border-radius: 30px;
    margin: auto;
  }
  h6 {
    padding-top: 80px;
    font-weight: 700;
    font-size: 25px;
    color: #05ae4b;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #002733;
    padding: 30px;
  }
`;
