import { Select, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import {
  ExpandComponentWrapper,
  HeadingStyled,
  TableStyled,
} from 'styles/style';
import {
  LISTING_STATUS,
  LISTING_STATUS_COLORS,
} from 'silal_app_base_react/src/config/constants';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import ListingsRepository from 'data/repositories/listings_repository';
import { getCurrentStore } from 'core/hooks/use_selector';

import {
  backArrowIcon_path,
  externalLinkIcon_path,
  productPlaceholder_path,
  notFoundIllustration_path,
} from 'assets/index';
import {
  CURRENT_CURRENCY,
  LISTING_STATUS_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  fetchCategoryListings,
  getExpandableTableBody,
  getExpandableTableHeaders,
  getMinMaxPrice,
} from '../functions/products_functions';
import {
  ListingItem,
  Listing,
} from 'silal_app_base_react/src/data/types/listings';
import { toast } from 'react-toastify';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { RootState } from 'data/types/state';

const customStyles = {
  headCells: {
    style: {
      fontSize: '13px',
      fontWeight: 700,
    },
    table: {
      style: {
        width: '100%',
      },
    },
  },
};

const ProductsSubmitted = () => {
  const { categoryId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStore = useSelector(getCurrentStore);
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [items, setListings] = useState<Listing[]>([]);

  const [currentRow, setCurrentRow] = useState<Listing>();
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [fullListing, setFullListing] = useState<Listing>();
  const [loading, setLoading] = useState(false);

  const [filteredItems, setFilteredItems] = useState<Listing[]>([]); // TODO fix any
  const [options] = useState([
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.pending_review as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.pending_review,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.listed as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.listed,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.freezed_by_management as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.freezed_by_management,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.freezed_by_seller as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.freezed_by_seller,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.rejected as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.rejected,
    },
  ]);

  useEffect(() => {
    if (categoryId && isNaN(parseInt(categoryId!))) {
      window.location.replace('/products');
      toast.warn('Invalid category id');
      return;
    }
    if (!location.state) {
      fetchCategoryListings(
        setListings,
        currentStore.id,
        categoryId,
        setLoading,
      );
      return;
    }
    setListings(location.state);
  }, [location]);

  useEffect(() => {
    if (currentRow) {
      getListing(currentRow.id);
    }
  }, [currentRow]);

  useEffect(() => {
    // TODO fix, empties on refresh
    const filteredItems = () => {
      const filteredItems = items
        ?.filter((item) => {
          if (selectedFilters.size === 0) return true; // Return true for items without a filter
          const status = item.listing_status_num;
          if (
            selectedFilters.has(LISTING_STATUS.listed) &&
            status == LISTING_STATUS.listed
          )
            return true;
          if (
            selectedFilters.has(LISTING_STATUS.freezed_by_management) &&
            status == LISTING_STATUS.freezed_by_management
          )
            return true;

          if (
            selectedFilters.has(LISTING_STATUS.freezed_by_seller) &&
            status == LISTING_STATUS.freezed_by_seller
          )
            return true;

          if (
            selectedFilters.has(LISTING_STATUS.rejected) &&
            status == LISTING_STATUS.rejected
          )
            return true;

          if (
            selectedFilters.has(LISTING_STATUS.pending_review) &&
            status == LISTING_STATUS.pending_review
          )
            return true;

          return false; // Return false for items that don't meet any conditions
        })
        .sort((b, a) => {
          return moment(a.date_created).unix() - moment(b.date_created).unix();
        });
      return filteredItems?.filter((item) => {
        if (searchQuery === '') return true; // Return true for items without a search query
        if (
          item.name_en.toLowerCase().match(searchQuery.toLowerCase()) ||
          item.name_ar.toLowerCase().match(searchQuery.toLowerCase()) ||
          item.id.toString().match(searchQuery.toLowerCase())
        ) {
          return true;
        }
        return false; // Return false for items that don't match search
      });
    };
    setFilteredItems(filteredItems);
  }, [items, selectedFilters, searchQuery]);

  const getListing = async (listing_id: number) => {
    await ListingsRepository.getListingWithID(currentStore.id, listing_id).then(
      (listing) => {
        if (!listing) return;
        setFullListing(listing!);
      },
    );
  };
  const columns = [
    {
      name: 'Photo',
      width: '10%',
      selector: (row: Listing) =>
        row.images.length > 0 ? (
          <div style={{ width: '60px', height: '60px' }}>
            <ShowImage
              image={row.images.find((img) => img.id === row?.cover_image_id)}
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        ) : (
          <img src={productPlaceholder_path} alt="mobile small" />
        ),
    },
    {
      name: 'Item name',
      width: '20%',
      selector: (row: Listing) => row.name_en, // TODO localize
    },
    {
      name: 'Listing ID',
      width: '10%',
      selector: (row: Listing) => row.id,
    },
    {
      name: 'Category',
      width: '10%',
      selector: (row: Listing) =>
        minimalCategoriesList?.find((x) => x.id === row.category_id)?.name_en,
    },
    {
      name: 'Variations',
      width: '10%',
      selector: (row: Listing) => row.items.length,
    },
    {
      name: 'Submitted on',
      width: '10%',
      sortAble: true,
      sortFunction: (a: Listing, b: Listing) =>
        moment(a.date_created).unix() - moment(b.date_created).unix(),
      selector: (row: Listing) =>
        formateServerDateToLocaleDate(row.date_created),
    },
    {
      name: 'Price',
      width: '10%',
      selector: (row: Listing) => {
        if (row.items?.length > 0) {
          return CURRENT_CURRENCY + getMinMaxPrice(row.items);
        } else {
          return CURRENT_CURRENCY + 0;
        }
      },
    },
    {
      name: 'Status',
      width: '10%',
      selector: (row: Listing) => {
        return (
          <p
            className="p-1"
            style={{
              fontSize: '12px',
              borderRadius: '5px',
              color: 'white',
              backgroundColor:
                LISTING_STATUS_COLORS[
                  row.listing_status_num as keyof typeof LISTING_STATUS_COLORS
                ],
            }}
          >
            {
              LISTING_STATUS_TYPES[
                row.listing_status_num as keyof typeof LISTING_STATUS_TYPES
              ]
            }
          </p>
        );
      },
    },
    {
      name: 'Edit',
      width: '5%',
      cell: (row: Listing) => (
        <Link
          to={`/products/listing-details/${row.id}`}
          target="_blank"
          state={row}
        >
          <img src={externalLinkIcon_path} alt="route to listing page" />
        </Link>
      ),
    },
  ];

  const tagRender = (props: any) => {
    const { label, value } = props;
    const onClose = () => {
      const tmp = selectedFilters;
      tmp.delete(value);
      setSelectedFilters(tmp);
    };
    return (
      <Tag
        color={
          LISTING_STATUS_COLORS[value as keyof typeof LISTING_STATUS_COLORS]
        }
        // onMouseDown={onPreventMouseDown}
        closable={false}
        onClose={onClose}
        style={{
          marginRight: 3,
          borderRadius: '5px',
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleChangeInStock = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        // TODO fix any
        if (response && response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems = items.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem) => dataItem.id === updatedItem.id,
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });

          setListings(newItems.filter((i) => i));
        }
      },
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  return (
    <Wrapper>
      <>
        <div className="d-flex align-items-center">
          <button onClick={() => navigate(-1)} className="back-arrow ">
            <img src={backArrowIcon_path} />
          </button>
          <HeadingStyled
            style={{
              fontSize: '25px',
              fontWeight: '700',
            }}
          >
            Submitted
          </HeadingStyled>
          <div className="d-flex w-100  justify-content-center ">
            <SearchBar className="mx-5 mt-4">
              <Form
                as="input"
                onChange={(e: any) => setSearchQuery(e.target.value)} // TODO fix any
                type="text"
                className="bg-white"
                placeholder="Search"
              />
              <Search className="icon-label position-absolute" size={17} />
            </SearchBar>
          </div>
          <div className="d-flex">
            <p className="dark-clr secondary-font px-3">
              Here you can find the listings you’ve submitted and currently
              under review by Silal team.
            </p>
          </div>
        </div>
        <Row>
          <Col md={1}>
            <p
              className="dark-clr secondary-font text-center py-1"
              style={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              Status
            </p>
          </Col>
          <Col md={3}>
            <Select
              mode="multiple"
              placeholder={'All'}
              tagRender={tagRender}
              onChange={(val: any) => {
                setSelectedFilters(new Set(val));
              }}
              style={{
                width: '100%',
              }}
              options={options}
            />
          </Col>
        </Row>

        <div className="template-section mt-4">
          <div className="">
            <TableStyled>
              {filteredItems?.length === 0 ? (
                <div className="no-orders">
                  {loading ? (
                    <>
                      <Spinner />
                      <h3>Loading from server...</h3>
                    </>
                  ) : (
                    <>
                      <img src={notFoundIllustration_path} alt="no-orders" />
                      <h3>No submitted listings were found</h3>
                    </>
                  )}
                </div>
              ) : (
                <DataTable
                  columns={columns as any}
                  data={filteredItems}
                  // data={stateData}
                  customStyles={customStyles}
                  pagination
                  paginationPerPage={20}
                  expandableRows
                  expandOnRowClicked
                  expandableRowExpanded={(row) => row === currentRow}
                  onRowExpandToggled={(_, row) => setCurrentRow(row)}
                  expandableRowsComponent={ExpandedComponent(
                    handleChangeInStock,
                    fullListing,
                  )}
                  expandableRowsComponentProps={{
                    someTitleProp: 'someTitleProp',
                  }}
                />
              )}
            </TableStyled>
          </div>
        </div>
      </>
    </Wrapper>
  );
};

const ExpandedComponent = (
  handleChangeInStock: any,
  fullListing: Listing | undefined,
) => {
  const ExpandedComponentWrapper = ({ data }: { data: Listing }) => {
    return (
      <ExpandComponentWrapper>
        <div className="">
          <Table borderless className="table">
            {getExpandableTableHeaders(data)}
            {getExpandableTableBody(data, handleChangeInStock)}
          </Table>
          {fullListing && getSilalResponse(fullListing)}
        </div>
      </ExpandComponentWrapper>
    );
  };

  // Add the displayName property to the inner component function
  ExpandedComponentWrapper.displayName = 'ExpandedComponent';

  return ExpandedComponentWrapper;
};

const getSilalResponse = (fullListing: Listing) => {
  if (fullListing?.request?.response?.msg) {
    return (
      <Row className="silal-response">
        <Col md={2} className="p-2">
          <p className="bold">Silal quality team comment:</p>
        </Col>
        <Col md={10} className="p-2">
          <p className="message">{fullListing.request.response.msg}</p>
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
};

export default ProductsSubmitted;

const SearchBar = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 26px;
  input {
    padding-left: 40px;
    width: 100%;
    padding-right: 20px;
    border: 1px solid rgba(204, 212, 214, 0.5);
    border-radius: 5px;
    height: 36px;
    &,
    &::placeholder {
      font-weight: 500;
      font-size: 13px;
      color: #b3bec2;
    }
  }
  .icon-label {
    color: #b3bec2;
    left: 0;
    top: 9px;
    left: 13px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  padding: 1rem 0;
  .search-result-section {
    .accordian-wrapper {
      background: #fff;
      padding: 1rem;
      border-radius: 5px;
    }
  }

  .silal-response {
    background: var(--app-background-color);
    min-height: 100px;
    font-weight: bold;
    border-radius: 10px;
    .bold {
    }
    .message {
      color: #abb7bd;
    }
  }
`;
