import { combineReducers } from 'redux';
import { Images } from 'silal_app_base_react/src/store/images/image_library_reducer';
import orders from './orders/orders_reducer';
import products from './products/products_reducer';
import store from './store/store_reducer';
import user from './user/user_reducer';
import CategoriesBase from 'silal_app_base_react/src/store/categories/categories_reducer';

const rootReducer = combineReducers({
  CategoriesBase,
  user,
  store,
  products,
  Images,
  orders,
});

export default rootReducer;
