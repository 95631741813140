import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  formatOrderDateTime,
  getLatestStatus,
} from 'silal_app_base_react/src/pages/orders_archive/functions/order_functions';
import { toast } from 'react-toastify';
import { backArrowIcon_path, editIcon_path } from 'assets/index';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { CardStyled, Heading } from 'styles/style';
import {
  OrderListItem,
  OrderPaymentMethodTag,
  OrderTimeline,
} from 'silal_app_base_react/src/pages/orders_archive/components/shared_components';
import { OrderList } from 'silal_app_base_react/src/pages/orders_archive/components/shared_components.styled';
import {
  CustomerDetails,
  ShiftDetails,
} from '../components/full_order_components';
import {
  OrderIdMain,
  OrderListMain,
} from '../components/full_order_components.styled';
import OrdersRepository from 'data/repositories/orders_repository';
import { PaymentMethodTag } from 'silal_app_base_react/src/pages/orders_archive/components/shared_components.styled';
import {
  ORDER_STATUS,
  OrderDetails as OrderDetailsType,
} from 'silal_app_base_react/src/data/types/orders';
import { getCurrentStoreState } from 'core/hooks/use_selector';
import { AcceptOrderModal } from '../modals/accept_order_modal';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { PackageEditingModal } from 'silal_app_base_react/src/components/package_editing_modal';
import {
  DELIVERY_SHIPMENT_STATUS,
  Package,
} from 'silal_app_base_react/src/data/types/shipping';
import { PopupWithOneInput } from 'silal_app_base_react/src/components/popups';
import { Button, Divider, Spacer } from '@nextui-org/react';
import { shiftTypeFormatStartAndEndDateTime } from 'silal_app_base_react/src/data/types/shifts';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { FaPencil } from 'react-icons/fa6';

const OrderDetails = () => {
  const navigate = useNavigate();
  const { currentStore } = useSelector(getCurrentStoreState);

  const { orderId } = useParams();
  const [order, setOrder] = useState<OrderDetailsType>();
  const [loader, setLoader] = useState(false);
  const [error] = useState('');
  const [barcodeModalShow, setBarcodeModalShow] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleCloseCancelModal = () => setShowCancelModal(false);
  const handleShowCancelModal = () => setShowCancelModal(true);

  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = async () => {
    setLoader(true);
    // Check if orderId is a valid integer string
    if (!orderId || !Number.isInteger(parseInt(orderId))) {
      setLoader(false);
      toast.warn(`Order ID is not valid, ID: ${orderId}`, {
        delay: 500,
      });
      navigate('/orders/current-orders');
      return;
    }
    const res = await OrdersRepository.getOrderDetailsByID({
      store_id: currentStore.id,
      order_id: parseInt(orderId),
    });
    setLoader(false);
    if (!res) return;
    setOrder(res);
  };

  const cancelOrder = async (reason: string) => {
    if (!orderId || !Number.isInteger(parseInt(orderId))) {
      setLoader(false);
      toast.warn(`Order ID is not valid, ID: ${orderId}`, {
        delay: 500,
      });
      navigate('/orders/current-orders');
      return;
    }
    if (!reason || reason === '') {
      toast.warn('Please enter a reason for rejecting the order');
      return;
    }
    await OrdersRepository.rejectOrderByID(
      currentStore.id,
      parseInt(orderId),
      reason,
    ).then((res) => {
      if (!res) return;
      handleCloseCancelModal();
      navigate('/orders/current-orders');
    });
  };

  const acceptOrderSubmit = async () => {
    setLoader(true);
    await OrdersRepository.acceptOrderByID(order!.store_id, order!.id).then(
      (res) => {
        setLoader(false);
        if (!res) return;
        navigate('/orders/current-orders');
      },
    );
  };
  return (
    <>
      <OrderIdMain>
        <button onClick={() => navigate(-1)}>
          <img src={backArrowIcon_path} />
        </button>
        <h1 className="mx-2">Order ID #{orderId}</h1>
        {loader && <Spinner flexible={true} width={40} height={40} />}
      </OrderIdMain>
      {error && (
        <Row>
          <Col lg={2} />
          <Col
            lg={8}
            className="mt-2 bg-white text-bg-info d-flex align-items-center"
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSficWfBNkMr2ix2jbvrwtV9Pl-cSbOExf-kED7kXTGy-fPa0ULMpGF5S3D_inV5x9LYPY&usqp=CAU"
              width={'60%'}
              alt="error"
            />
            <h3>{error}</h3>
          </Col>
          <Col lg={2} />
        </Row>
      )}
      {order && (
        <Row>
          <Col lg={8}>
            <OrderListMain>
              <div className="between flex-wrap">
                <div className="d-flex align-items-center">
                  <h1>
                    Order items <span>({order.items.length})</span>
                  </h1>
                  <PaymentMethodTag style={{ marginBottom: '20px' }}>
                    {OrderPaymentMethodTag(order.payment_type)}
                  </PaymentMethodTag>
                </div>
                <h2 className="lato">{formatOrderDateTime(order)}</h2>
              </div>
              <OrderList>
                <div className="head lato">
                  <h3 className="item-width">Item</h3>
                  <h3 className="col-width">ID</h3>
                  <h3 className="col-width">Q-ty</h3>
                  <h3 className="col-width">Price</h3>
                  <h3 className="col-width right">Item price</h3>
                </div>

                {order.items.map((item, index) => {
                  return <OrderListItem key={index} orderItem={item} />;
                })}
              </OrderList>
              <Divider
                style={{
                  marginTop: '20px',
                  color: Colors.blueAlternate,
                }}
              />
              <div className="bottom">
                <Spacer y={1} />{' '}
                <div className="between">
                  <h5>Items Total</h5>
                  <h5>{formatPrice(order.items_total)}</h5>
                </div>{' '}
                <Spacer y={1} />{' '}
                <div className="between">
                  <h5>Store Discount</h5>
                  <div className="flex flex-col">
                    <h5
                      style={{
                        color: Colors.grey,
                      }}
                    >
                      -{' '}
                      {order.discount_lines.map((discount) =>
                        formatPrice(discount.amount_deducted_from_store),
                      )}
                    </h5>
                  </div>
                </div>{' '}
                <Spacer y={1} />{' '}
                <div className="between">
                  <h5>Refunds</h5>
                  <h5
                    style={{
                      color: Colors.grey,
                    }}
                  >
                    - {formatPrice(order.order_ledger.amount_refunded)}
                  </h5>
                </div>{' '}
                <Spacer y={1} />{' '}
                <div className="between">
                  <h5>Silal Fee</h5>
                  <h5
                    style={{
                      color: Colors.grey,
                    }}
                  >
                    - {formatPrice(order.silal_fees_total)}
                  </h5>
                </div>{' '}
                <Spacer y={1} />{' '}
                <div className="between">
                  <h4>Store Recieves</h4>
                  <h4>{formatPrice(order.store_recieves)}</h4>
                </div>
              </div>
            </OrderListMain>
          </Col>
          <Col lg={4}>
            <CardStyled
              style={{
                padding: '16px',
              }}
            >
              <Heading
                style={{
                  marginLeft: '0',
                }}
              >
                Delivery Details
              </Heading>
              <ShiftDetails
                orderPlacement={formateServerDateToLocaleDate(
                  order.placing_date,
                )}
                orderPickup={
                  order.shipments.filter(
                    (shipment) =>
                      shipment.status ===
                      DELIVERY_SHIPMENT_STATUS.ready_for_pickup,
                  ).length > 0
                    ? shiftTypeFormatStartAndEndDateTime(
                        order.shipments.filter(
                          (shipment) =>
                            shipment.status ===
                            DELIVERY_SHIPMENT_STATUS.ready_for_pickup,
                        )[0].last_mile_shift,
                      )
                    : 'Mark As Ready/Reschedule'
                }
                shipmentDelivery={shiftTypeFormatStartAndEndDateTime(
                  order.last_mile_shift,
                )}
              />
              <Heading
                style={{
                  marginLeft: '0',
                  marginTop: '22px',
                }}
              >
                Customer Details
              </Heading>
              <CustomerDetails
                number1={order?.customer_id} // TODO connect to API or change
                // number2={order?.customer_id} // TODO connect to API or change
                address={
                  order?.destination_address?.address_line_1 +
                  ', ' +
                  order?.destination_address?.town?.name_en +
                  ', ' +
                  order?.destination_address?.city?.name_en
                } // TODO localize
                // distance="15 MIN (CHANGE MEEEE)" // TODO connect to API or change
              />
              {order.status === ORDER_STATUS.ship__in_store__ready &&
                order?.shipments[0]?.packages && (
                  <>
                    <div
                      className="d-flex"
                      style={{
                        alignItems: 'center',
                        marginTop: '12px',
                      }}
                    >
                      <Heading
                        style={{
                          margin: '0',
                        }}
                      >
                        Tracking Codes
                      </Heading>
                      <Button
                        isIconOnly
                        color="success"
                        variant="light"
                        onClick={() => setBarcodeModalShow(true)}
                      >
                        <FaPencil />
                      </Button>
                    </div>
                    <div className="tracking_code_div">
                      {order?.shipments[0]?.packages?.map((ele, index) => (
                        <h5 key={index} className="text-success">
                          {ele.tracking_code}
                        </h5>
                      ))}
                    </div>
                  </>
                )}
            </CardStyled>
            <CardStyled
              style={{
                padding: '16px',
              }}
            >
              <OrderTimeline
                orderActions={order.order_actions}
                customHeader={
                  <Heading
                    style={{
                      marginLeft: '0',
                    }}
                  >
                    Timeline
                  </Heading>
                }
              />
            </CardStyled>
            <div className="d-flex">
              {getLatestStatus(order.order_actions)?.action ===
                ORDER_STATUS.requested && (
                <>
                  <Button
                    className="me-2 px-2"
                    color={'danger'}
                    variant="flat"
                    onClick={handleShowCancelModal}
                  >
                    Reject order
                  </Button>
                  <Button
                    className="me-2 px-1 w-full flex-1"
                    onClick={acceptOrderSubmit}
                    color="success"
                  >
                    {loader ? (
                      <Spinner
                        flexible={true}
                        className={'d-flex justify-content-center'}
                      />
                    ) : (
                      'Accept order'
                    )}
                  </Button>
                </>
              )}
              {getLatestStatus(order.order_actions)?.action ===
                ORDER_STATUS.accepted && (
                <AcceptOrderModal
                  text={'Ready for pickup'}
                  order={order}
                  style={{
                    width: '100%',
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      )}

      {order && (
        <PackageEditingModal
          open={barcodeModalShow}
          headerTitle={`Connect tracking barcodes to order #${order.id}`}
          onButtonOneClick={() => {
            setBarcodeModalShow(false);
            return;
          }}
          bodyText="To make it easier for couriers to identitfy the order and for you, use the Labels ID paper we sent you to label this order with a BARCODE so you can easily track it in the future. To add a package enter the label ID manually below or press the camera icon to scan. To add a package enter the label ID manually below or press the camera icon to scan"
          buttonOneText="Cancel"
          onButtonTwoClick={async (packages: Package[]) => {
            const x = await OrdersRepository.markOrderAsReadyByID(
              currentStore.id,
              order.id,
              packages,
            );
            if (x) {
              await getOrderDetail();
              setBarcodeModalShow(false);
              return true;
            }
            return false;
          }}
          buttonTwoText="Submit"
          orderDetails={order}
        />
      )}
      <PopupWithOneInput
        open={showCancelModal}
        headerTitle="Reject order"
        inputOneLabel="Reason for rejection"
        inputOnePlaceholder="Type here..."
        inputOneAsTextAreaInsteadOfSingleInput
        onButtonOneClick={handleCloseCancelModal}
        onButtonTwoClick={async (reason: string) => {
          await cancelOrder(reason);
        }}
        buttonOneText="Cancel"
        buttonTwoText="Reject"
        confirmAction
        buttonTwoColor="danger"
      />
    </>
  );
};

export default OrderDetails;
