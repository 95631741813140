import { put, takeLatest } from '@redux-saga/core/effects';
import * as StoreActions from '../store/store_actions';
import {
  actionSetToken,
  actionSetUser,
  actionGetSetUserProfile,
  GET_SET_USER_PROFILE,
  LOGIN_WITH_EMAIL_PASSWORD,
  LOGIN_OR_VERIFY_PHONE_OTP,
  LOGIN_OR_VERIFY_EMAIL_OTP,
  SIGNUP_EMAIL_VERIFY_OTP,
  SIGNUP_PHONE_VERIFY_OTP,
  DELETE_EMAIL,
} from './user_actions';

import { ErrorHandler } from 'silal_app_base_react/src/config/errors/error_handler';
import SellerRepository from 'data/repositories/seller_repository';
import AuthenticationRepository from 'data/repositories/authentication_repository';

export function* sagaLoginEmailPassword() {
  function* loginWithEmailPassword({ payload }) {
    const bearer = yield AuthenticationRepository.loginEmailVerifyPassword(
      payload.data.email,
      payload.data.password,
    );
    if (!bearer) return false; // No bearer (token)
    yield put(actionSetToken(bearer)); // Set token
    yield put(actionGetSetUserProfile()); // Get & Set user profile
    yield put(StoreActions.actionGetManagedStores()); // Get & Set managed stores
    payload.success();
  }
  return yield takeLatest(LOGIN_WITH_EMAIL_PASSWORD, loginWithEmailPassword);
}

export function* sagaLoginOrVerifyEmailOtp() {
  function* loginOrVerifyEmailOtp({ payload }) {
    const bearer = yield AuthenticationRepository.loginEmailVerifyOTP(
      payload.data.email,
      payload.data.otp,
    );
    if (!bearer) return; // No bearer (token)
    yield put(actionSetToken(bearer)); // Set token
    yield put(actionGetSetUserProfile()); // Get & Set user profile
    yield put(StoreActions.actionGetManagedStores()); // Get & Set managed stores
  }
  return yield takeLatest(LOGIN_OR_VERIFY_EMAIL_OTP, loginOrVerifyEmailOtp);
}

export function* sagaLoginOrVerifyPhoneOtp() {
  function* loginOrVerifyPhoneOtp({ payload }) {
    const bearer = yield AuthenticationRepository.loginPhoneVerifyOTP(
      payload.data.phone,
      payload.data.otp,
    );
    if (!bearer) return; // No bearer (token)
    yield put(actionSetToken(bearer)); // Set token
    yield put(actionGetSetUserProfile()); // Get & Set user profile
    yield put(StoreActions.actionGetManagedStores()); // Get & Set managed stores
  }

  return yield takeLatest(LOGIN_OR_VERIFY_PHONE_OTP, loginOrVerifyPhoneOtp);
}

// ? Signup
// ! sagaSignupEmailVerifyOtp currently unused as user MUST have phone first, the Phone sagaSignupPhoneVerifyOtp is used normally
export function* sagaSignupEmailVerifyOtp() {
  function* signupEmailVerifyOtp({ payload }) {
    try {
      const bearer = yield AuthenticationRepository.signupVerifyEmailOTP(
        payload.data.email,
        payload.data.otp,
      );
      if (!bearer) return; // No response
      yield put(actionSetToken(bearer));
      payload.success();
    } catch (error) {
      const errObj = ErrorHandler(error);
      payload?.failure(errObj);
    }
  }
  return yield takeLatest(SIGNUP_EMAIL_VERIFY_OTP, signupEmailVerifyOtp);
}

export function* sagaSignupPhoneVerifyOtp() {
  function* signupPhoneVerifyOtp({ payload }) {
    try {
      const bearer = yield AuthenticationRepository.signupVerifyPhoneOTP(
        payload.data.phone,
        payload.data.otp,
      );
      if (!bearer) return; // No response
      yield put(actionSetToken(bearer));
      payload.success();
    } catch (error) {
      const errObj = ErrorHandler(error);
      payload?.failure(errObj);
    }
  }
  return yield takeLatest(SIGNUP_PHONE_VERIFY_OTP, signupPhoneVerifyOtp);
}

// !
export function* sagaGetSetUserProfile() {
  function* getSetUserProfile({ payload }) {
    try {
      const response = yield SellerRepository.getUserInformation();
      if (!response) throw new Error('No response');
      yield put(actionSetUser(response));
      if (payload?.success) payload.success();
    } catch (error) {
      payload?.failure();
    }
  }
  return yield takeLatest(GET_SET_USER_PROFILE, getSetUserProfile);
}

export function* sagaDeleteEmail() {
  function* deleteEmail() {
    yield SellerRepository.deleteEmail();
  }
  return yield takeLatest(DELETE_EMAIL, deleteEmail);
}
