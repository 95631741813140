import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled, { createGlobalStyle } from 'styled-components';

export const Content = styled.div`
  //   max-width: 1440px;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  > div {
    background: var(--color-light);
    color: var(--color-dark);
    position: sticky;
    top: 0;
    height: 100vh;
  }
  main {
    padding: 25px;
    background: rgb(244, 247, 248); /* #fafafa */
  }
`;

export const GlobalStyle = createGlobalStyle`
.theme-clr {
    color: var(--app-color-dark);
}
button, a {
    cursor: pointer;
}
.uppercase {
    text-transform: uppercase;
}
.d-flex {
    display: flex;
}
.d-block {
    display: block;
}

body {
    margin: 0;
    padding: 0;
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        -webkit-font-smoothing: antialiased;
        text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
    }
}
h1 {
    font-size: 40px;
    font-family: ${Fonts.primary}, sans-serif;
}
h2 {
    font-size: 35px;
    font-family: ${Fonts.primary}, sans-serif;
}
h3 {
    font-size: 30px;
    font-family: ${Fonts.primary}, sans-serif;
}
h4 {
    font-size: 25px;
    font-family: ${Fonts.primary}, sans-serif;
}
img {
    max-width: 100%;
    /* height: auto; */
}
a {
    text-decoration: none;
    font-size: 16px;
} 
`;
