import LargeLetter from 'assets/images/svg/LargeLetter';
import Parcel from 'assets/images/svg/Parcel';
import SmallLetter from 'assets/images/svg/SmallLetter';
import SmallParcel from 'assets/images/svg/SmallParcel';
import { PackageTrackingType } from 'silal_app_base_react/src/data/types/shipping';

export const MINUTES_BEFORE_SHOWING_ORDER_LATE_ICON = 30;
export const DEFAULT_PACKAGE_WEIGHT = 1; // gram
export const DEFAULT_PACKAGE_HEIGHT = 30; // cm
export const DEFAULT_PACKAGE_WIDTH = 30; // cm
export const DEFAULT_PACKAGE_LENGTH = 30; // cm

const small_letter: PackageTrackingType = {
  name: 'Small Letter',
  width: 24,
  height: 5,
  length: 16.5,
  image: <SmallLetter active={false} width="25px" height="30px" />,
};

const large_letter: PackageTrackingType = {
  name: 'Large Letter',
  width: 35,
  height: 12,
  length: 25,
  image: <LargeLetter active={false} width="25px" height="30px" />,
};
const small_parcel: PackageTrackingType = {
  name: 'Small Parcel',
  width: 45,
  height: 16,
  length: 35,
  image: <SmallParcel active={false} width="25px" height="30px" />,
};
const parcel: PackageTrackingType = {
  name: 'Parcel',
  width: 45,
  height: 45,
  length: 45,
  image: <Parcel active={false} width="25px" height="30px" />,
};

const custom: PackageTrackingType = {
  name: 'Custom',
  width: 300,
  height: 300,
  length: 300,
  image: null,
};

export function determinePackageType(
  width: number,
  height: number,
  length: number,
) {
  if (width === 24 && height === 5 && length === 16.5) {
    return small_letter;
  } else if (width === 35 && height === 12 && length === 25) {
    return large_letter;
  } else if (width === 45 && height === 16 && length === 35) {
    return small_parcel;
  } else if (width === 45 && height === 45 && length === 45) {
    return parcel;
  } else {
    return custom;
  }
}

export const all_packages_list = [
  { ...small_letter },
  { ...large_letter },
  { ...small_parcel },
  { ...parcel },
  { ...custom },
];
