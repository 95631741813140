import { useEffect, useState } from 'react';
import CountdownTimer from 'pages/auth/components/countdown_timer';
import Backbutton from 'pages/auth/components/back_button';
import Countdown from 'react-countdown';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import * as userAction from 'store/user/user_actions';
import { toast } from 'react-toastify';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import {
  EMAIL_OTP_CODE_LENGTH,
  OTP_EXPIRATION_TIME_IN_SECONDS,
} from 'silal_app_base_react/src/config/constants';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button } from '@nextui-org/react';

function EmailLoginOTPScreen() {
  const [targetedTime, setTargetedTime] = useState(5000);
  const [currentTime, setCurrentTime] = useState(+new Date());
  const [email, setEmail] = useState('');
  const [otp, setotp] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!location?.state?.email) {
      navigate('/signup-email');
    } else setEmail(location.state.email);
  }, []);

  const handleOtpChange = (otpValue: string) => {
    setotp(otpValue);
    if (otpValue.length === EMAIL_OTP_CODE_LENGTH) {
      verifyEmailOTP({ otpValue });
    }
  };

  const verifyEmailOTP = ({ otpValue }: { otpValue?: string }) => {
    const otpToSend = otpValue || otp;
    if (!otpToSend) {
      toast.error('Please enter OTP code before proceeding.');
      return;
    } else if (otpToSend.length < EMAIL_OTP_CODE_LENGTH) {
      toast.error(
        `Please enter the ${EMAIL_OTP_CODE_LENGTH} digit OTP code you received.`,
      );
      return;
    }
    const data = { email: location.state.email, otp: otpToSend };
    dispatch(userAction.actionLoginOrVerifyEmailOtp({ data }));
  };

  async function resendCode() {
    const now = Date.now();
    if (now > currentTime + targetedTime) {
      await AuthenticationRepository.loginSendEmailOTP(email).then((res) => {
        if (!res || res.http_status_code === 429) {
          toast.warning(res.msg);
          return;
        }
        toast.success(res.msg);
        setTargetedTime(OTP_EXPIRATION_TIME_IN_SECONDS * 1000);
        setCurrentTime(Date.now());
      });
    }
    return false;
  }

  function handleBack() {
    navigate('/login', { state: { email: email } });
  }

  return (
    <BackgroundContainer>
      <Wrapper>
        <div className="top-row">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              baselineShift: 'center',
            }}
          >
            <div className="go-back-button">
              <Backbutton primaryIcon={true} />
            </div>
            <div className="title">Authentication</div>
            <div />
          </div>
          <div className="otp-code ">
            <h2 className="primary-font-font">OTP code</h2>
            <p style={{ fontFamily: Fonts.secondary }}>
              We have sent the code verification to your <br /> email{' '}
              <strong>{email}</strong>
            </p>
          </div>
          <div>
            <OtpInput
              containerStyle={'pincode-input-container'}
              inputStyle={'pincode-input-text'}
              value={otp}
              onChange={handleOtpChange}
              numInputs={EMAIL_OTP_CODE_LENGTH}
              renderInput={(inputProps: any) => (
                <input {...inputProps} style={{ fontFamily: Fonts.primary }} />
              )}
            />
          </div>
          <div className="resend-otp">
            <button onClick={resendCode}>
              <Countdown
                key={currentTime}
                date={currentTime + targetedTime}
                renderer={CountdownTimer}
              />
            </button>
          </div>
        </div>
        <div className="bottom-row">
          <div className="form-submit d-flex flex-column align-items-center justify-content-center">
            {location.state.previousPage === 'login' && (
              <Button
                className="my-1"
                onClick={handleBack}
                color="success"
                variant="light"
              >
                Use password instead?
              </Button>
            )}
            <Button
              onClick={() => {
                verifyEmailOTP({});
              }}
              className="w-3/5"
              color="success"
            >
              Submit
            </Button>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default EmailLoginOTPScreen;

const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: auto;
  .pincode-input-container {
    justify-content: center;
    .pincode-input-text {
      text-align: center;
      padding: 0 !important;
      margin: 0 5px !important;
      border: 1px solid rgb(205, 205, 208);
      width: 45px !important;
      height: 45px !important;
      border-radius: 5px;
      background: rgba(241, 242, 243, 0.5) !important;
      font-family: ${Fonts.primary};
      font-weight: 500;
      font-size: 25px;
      line-height: 22px;
      color: #4c6870;
    }
  }
  .top-row {
    .title {
      padding: 25px 0;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
    }
    .otp-code {
      text-align: center;
      h2 {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      p {
        font-size: 17px;
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 50px;
      }
    }
    .resend-otp {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      button {
        font-size: 15px;
        font-weight: 500;
        color: #4c6870;
        border: none;
        background: transparent;
        padding-bottom: 3px;
        border-bottom: 1px solid #000000;
        cursor: pointer;
      }
    }
  }
  .bottom-row {
    text-align: center;
    position: absolute;
    bottom: 68px;
    width: 100%;
    position: static;
    margin-top: 50px;
  }
`;
