import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import MapBox from './mapbox';
import Button from 'silal_app_base_react/src/components/buttons';
import { Input } from '@nextui-org/react';

type MapModalProps = {
  show: boolean;
  onHide: any;
  setFormData: any;
  formData: any;
  initial_coords: any;
};

const MapModal = ({
  show,
  onHide,
  setFormData,
  formData,
  initial_coords,
}: MapModalProps) => {
  const [coords, setCoords] = useState(initial_coords);

  useEffect(() => {
    setCoords(initial_coords);
  }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      className="map-modal"
    >
      <Modal.Body>
        <div style={{ height: '100%', width: '100%' }}>
          <MapBox
            coords={coords}
            setCoords={setCoords}
            setFormData={setFormData}
            formData={formData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            <Button
              secondaryButton
              onClick={() => {
                setCoords(initial_coords);
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    lat: initial_coords.lat,
                    lng: initial_coords.lng,
                  },
                });
              }}
            >
              Reset
            </Button>
            <div style={{ height: '10px' }} />

            <Button
              secondaryButton
              onClick={() => {
                if ('geolocation' in navigator) {
                  // Get the user's current location
                  navigator.geolocation.getCurrentPosition(function (position) {
                    // The user's latitude and longitude are in position.coords.latitude and position.coords.longitude
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setCoords({
                      lat: latitude,
                      lng: longitude,
                    });
                    setFormData({
                      ...formData,
                      address: {
                        ...formData.address,
                        lat: latitude,
                        lng: longitude,
                      },
                    });
                  });
                }
              }}
            >
              Get Current Location
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p>
              Click on map and then click Save, current coordinations:{' '}
              {coords.lat}, {coords.lng},
            </p>
            <p>
              You can also drag the marker to your location, or input the
              coordinations below
            </p>{' '}
            <div style={{ height: '10px' }} />
            <Input
              value={coords.lat}
              label="Latitude"
              labelPlacement="outside-left"
              type="number"
              onChange={(e) => {
                setCoords({
                  ...coords,
                  lat: e.target.value,
                });
              }}
            />
            <div style={{ height: '10px' }} />
            <Input
              value={coords.lng}
              type="number"
              label="Longitude"
              labelPlacement="outside-left"
              onChange={(e) => {
                setCoords({
                  ...coords,
                  lat: e.target.value,
                });
              }}
            />
          </div>
          <div>
            <Button
              primary
              onClick={() => {
                onHide();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MapModal;
