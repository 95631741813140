import { Country } from 'data/types/general';

export const CurrentRegions = [
  {
    label: 'Jerusalem District',
    value: 1,
  },
  {
    label: 'West Bank',
    value: 2,
  },
];

export const CurrentContries: Country[] = [
  {
    label: 'IL +972',
    value: '+972',
    default: true,
  },
];
