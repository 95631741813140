import { useEffect, useState } from 'react';
import { Search } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Heading, HeadingStyled } from 'styles/style';
import { backArrowIcon_path, silal_logo_green } from 'assets/index';
import { LISTING_TYPES } from 'silal_app_base_react/src/config/constants';
import {
  Listing,
  SolrTemplateResponse,
} from 'silal_app_base_react/src/data/types/listings';
import ListingsRepository from 'data/repositories/listings_repository';
import { getCurrentStoreState } from 'core/hooks/use_selector';
import { fetchCategoryListings } from '../functions/products_functions';

import { Colors } from 'silal_app_base_react/src/config/theme';
import { RootState } from 'data/types/state';
import Lightbox from 'react-image-lightbox';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { Button, Checkbox, Chip, Input, Spacer } from '@nextui-org/react';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { Table } from 'antd';
import {
  SilalExpandable,
  Expandable,
} from 'silal_app_base_react/src/pages/templates/components/search_components';
import { FaTrashCan } from 'react-icons/fa6';

const ProductsTemplates = () => {
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [showSilalTemplates, setShowSilalTemplates] = useState(true);
  const [showStoreTemplates, setShowStoreTemplates] = useState(true);
  const [templates, setTemplates] = useState<Listing[]>([]);
  const [publicTemplates, setPublicTemplates] = useState<
    SolrTemplateResponse[]
  >([]);
  const [image, setImage] = useState<
    | ImageType
    | {
        public_s3_url: string;
        alt: string;
      }
    | null
  >();
  const { currentStore } = useSelector(getCurrentStoreState);
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!minimalCategoriesList) dispatch(actionGetCategoriesMinimalList());
  }, []);

  // % PERF global state to save/cache products
  useEffect(() => {
    if (!location.state) {
      fetchCategoryListings(
        (templates) =>
          setTemplates(
            templates?.filter(
              (l: any) => l?.listing_type_value === LISTING_TYPES.template,
            ),
          ),
        currentStore.id,
        categoryId,
        () => {},
      );
      return;
    }
    setTemplates(
      location?.state?.listings?.filter(
        (l: any) => l?.listing_type_value === LISTING_TYPES.template,
      ) ?? [],
    );
  }, [location.state, categoryId, currentStore.id]);

  useEffect(() => {
    if (!minimalCategoriesList) {
      dispatch(actionGetCategoriesMinimalList());
    }
  }, [minimalCategoriesList]);

  useEffect(() => {
    async function fetchManagementTemplates() {
      await SolrRepository.searchManagementSilalOwnedTemplates({
        query: searchQuery,
      }).then((res: any) => {
        if (!res) return;
        setPublicTemplates(res);
      });
    }
    if (showSilalTemplates) fetchManagementTemplates();
  }, [searchQuery, showSilalTemplates]);

  const filteredStoreTemplates = () => {
    return !templates
      ? []
      : searchQuery === ''
        ? templates
        : templates
            ?.filter(
              (item) => item.listing_type_value === LISTING_TYPES.template,
            )
            ?.filter(
              (item) =>
                item.name_en
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                item.name_ar
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                item.id.toString().includes(searchQuery),
            );
  };

  const filteredSilalTemplates = () => {
    return publicTemplates;
  };

  const handleDelete = async (listing_id: number) => {
    await ListingsRepository.deleteListingWithID(
      currentStore.id,
      listing_id,
    ).then((res) => {
      if (!res) return;
      setTemplates(templates?.filter((x) => x.id !== listing_id));
    });

    // dispatch(actionDeleteListing(payload));
  };

  const UseSilalTemplateButton = ({ item }: { item: SolrTemplateResponse }) => {
    return (
      <Button
        color="success"
        className="mt-2"
        onClick={async () => {
          const listing = await SharedRepository.getSilalTemplateDetailsById(
            parseInt(item.id),
          );
          if (!listing) return;
          // Store the state in localStorage
          const state = {
            ...listing,
            edit: false,
            prevScreen: 'template',
          };
          localStorage.setItem('templateState', JSON.stringify(state));
          // Open the new tab using Link's target="_blank" approach
          const newWindow = window.open(`/create-new-item`, '_blank');
          if (newWindow) newWindow.focus(); // Focus the new tab if it's successfully opened
        }}
      >
        Use template
      </Button>
    );
  };

  const UseStoreTemplateButton = ({ item }: { item: Listing }) => {
    return (
      <>
        <Button
          color="success"
          className="mt-2"
          variant="light"
          onClick={async () => {
            navigate('/create-new-item', {
              state: {
                ...item,
                edit: true,
                prevScreen: 'template',
              },
            });
          }}
        >
          Edit template
        </Button>
        <Button
          color="success"
          className="mt-2"
          onClick={async () => {
            // Store the state for using the template in localStorage
            const state = {
              ...item,
              edit: false,
              prevScreen: 'template',
            };
            localStorage.setItem('templateState', JSON.stringify(state));

            // Open the new tab with the URL for using the template
            const newWindow = window.open('/create-new-item', '_blank');
            if (newWindow) newWindow.focus(); // Focus the new tab if it's successfully opened
          }}
        >
          Use template
        </Button>
        <Button
          color="danger"
          variant="flat"
          className="mt-2 ml-3"
          isIconOnly
          onClick={() => handleDelete(item.id)}
        >
          <FaTrashCan />
        </Button>
      </>
    );
  };

  interface TemplateData {
    key: React.Key;
    name_en: string;
    name_ar: string;
    is_silal_owned: boolean;
    images: { public_s3_url: string; alt: string }[];
    description: string;
    attribute_text?: string; // For Silal-specific attributes
    category_id: number;
    item: Listing | SolrTemplateResponse;
  }

  const unifiedTemplates: TemplateData[] = [
    ...(showStoreTemplates
      ? filteredStoreTemplates().map((item) => ({
          key: item.id,
          name_en: item.name_en,
          name_ar: item.name_ar,
          is_silal_owned: item.is_silal_owned,
          images: item.images,
          description: item.description || '',
          category_id: item.category_id,
          item,
        }))
      : []),
    ...(showSilalTemplates
      ? filteredSilalTemplates().map((item) => ({
          key: item.id,
          name_en: item.name_en,
          name_ar: item.name_ar,
          is_silal_owned: item.is_silal_owned,
          images: [
            { public_s3_url: item.image_public_url, alt: item.image_alts },
          ],
          description: item.attribute_text_en,
          category_id: item.category_id,
          item: item,
        }))
      : []),
  ];

  const columns = [
    {
      title: 'Name (EN/AR)',
      dataIndex: 'name_en',
      key: 'name_en',
      render: (_: string, record: TemplateData) => (
        <div
          className="flex items-center"
          style={{
            fontSize: '15px',
          }}
        >
          <ShowImage
            imageSrc={record.images[0].public_s3_url}
            style={{
              width: '120px',
              height: '120px',
              borderRadius: '5px',
              marginRight: '1rem',
            }}
            onClick={() => setImage(record.images[0])}
          />
          #{record.key.toString()} {record.name_en} <br /> {record.name_ar}
        </div>
      ),
    },
    {
      title: 'Category',
      key: 'category',
      render: (_: string, record: TemplateData) => {
        const category = minimalCategoriesList?.find(
          (category) => category.id === record.item?.category_id,
        );
        return <>{category?.name_en + ' | ' + category?.name_ar}</>;
      },
    },
    {
      title: 'By',
      key: 'source',
      sorter: (a: TemplateData, _: TemplateData) => (a.is_silal_owned ? 1 : -1),
      render: (_: string, record: TemplateData) => (
        <>
          {record.is_silal_owned ? (
            <img src={silal_logo_green} width={30} height={30} />
          ) : (
            <img
              src={currentStore.logo_img?.public_s3_url}
              width={30}
              height={30}
            />
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: TemplateData) =>
        !record.is_silal_owned ? (
          <UseStoreTemplateButton item={record.item as Listing} />
        ) : (
          <UseSilalTemplateButton item={record.item as SolrTemplateResponse} />
        ),
    },
  ];

  const StoreExpandable = ({ item }: { item: Listing }) => {
    return Expandable({
      images: item.images,
      num_of_images_in_template: item.images.length,
      num_of_variations: item.items.length,
      rich_text_description_en: item.rich_text_description_en,
      rich_text_description_ar: item.rich_text_description_ar,
      attributes: item.attributes,
      cover_image_id: item.cover_image_id,
      setImage,
    });
  };

  return (
    <Wrapper>
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={image.alt}
          mainSrcThumbnail={image.public_s3_url}
          onCloseRequest={() => setImage(null)}
        />
      )}
      <>
        <div className="d-flex align-items-center">
          <button onClick={() => navigate('/products')} className="back-arrow">
            <img src={backArrowIcon_path} />
          </button>
          <HeadingStyled
            style={{
              fontSize: '25px',
              fontWeight: '700',
            }}
          >
            Add new item
          </HeadingStyled>
        </div>
        <div className="d-flex">
          <p className="dark-clr secondary-font px-5">
            Adding an item through existing templates will make it easier for
            you to list all relevant details, specification and variations for
            your listing, resulting in easier and faster listing process. To
            start, search the item you’ll be adding and select the template that
            suits you.Don’t worry, if no template is suitable you can create one
            of your own!
          </p>
        </div>
        <div className="d-flex justify-between">
          <div className="d-flex" />
          <Input
            isClearable
            color="default"
            size="lg"
            className="mt-2 max-w-5xl align-self-center justify-content-center"
            placeholder="Search for templates or drafts in this category"
            startContent={<Search />}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <Checkbox
            isSelected={showStoreTemplates}
            onValueChange={(isSelected) => {
              setShowStoreTemplates(isSelected);
            }}
          >
            Store templates
          </Checkbox>
          <Checkbox
            isSelected={showSilalTemplates}
            onValueChange={(isSelected) => {
              setShowSilalTemplates(isSelected);
            }}
          >
            Silal templates
          </Checkbox>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <HeadingStyled
              style={{
                fontSize: '15px',
                fontWeight: '600',
                color: '#979797',
                margin: ' 0 0 .5rem 0 ',
              }}
            >
              Didn’t find what you need?
            </HeadingStyled>
            <Link
              to={{
                pathname: '/create-new-item',
              }}
            >
              <Button color="success">Create your own!</Button>
            </Link>
          </div>
        </div>
        <div className="d-flex w-100 justify-content-center" />

        <div className="draft-section">
          <Heading>Templates</Heading>
          <Table<TemplateData>
            columns={columns}
            pagination={{
              defaultPageSize: 50,
              pageSizeOptions: ['50', '100', '500', '1000'],
              position: ['topRight', 'bottomRight'],
            }}
            expandable={{
              expandedRowRender: (record) =>
                !record.is_silal_owned ? (
                  <StoreExpandable item={record.item as Listing} />
                ) : (
                  <SilalExpandable
                    item={record.item as SolrTemplateResponse}
                    setImage={setImage}
                  />
                ),

              // rowExpandable: (record) => !!record.description, // Expand only if there is a description
            }}
            dataSource={unifiedTemplates}
          />
        </div>
      </>
    </Wrapper>
  );
};

export default ProductsTemplates;

const Wrapper = styled.div`
  padding: 0.5rem 0;
  p {
    margin: 0;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
  .sectionTitle {
    font-weight: 600;
  }
  .search-result-section {
    .accordian-wrapper {
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      width: 98%;
      background: #fff;
      padding: 1rem;
      border-radius: 5px;
    }
  }
`;
