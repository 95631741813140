import styled from 'styled-components';
import {
  // FilterIcon,
  // XIcon,
  // SearchIcon,
  // PeakHoursIcon,
  // peakHoursIcon_path,
  filterIcon_path,
  xIcon_path,
  searchIcon_path,
} from 'assets/index';
import { Fonts } from 'silal_app_base_react/src/config/theme';

type DashboardHeadingProps = {
  heading: string;
  // hours: string;
  location: string;
};
const DashboardHeading = ({
  heading,
  // hours,
  location,
}: DashboardHeadingProps) => {
  return (
    <RestHeadStyled>
      <div className="d-flex align-items-center">
        <h1 className="dark-clr">{heading}</h1>
        {/* <span className="f-medium">
          <img src={peakHoursIcon_path} />
          {hours}
        </span> */}
      </div>
      <p className="f-medium" style={{ fontFamily: Fonts.secondary }}>
        {location}
      </p>
    </RestHeadStyled>
  );
};

const RestHeadStyled = styled.div`
  h1 {
    font-weight: bold;
    font-size: 25px;
    color: #002733;
    margin: 0 12px 5px 0;
  }
  span {
    svg {
      margin-right: 6px;
    }
    background: rgba(226, 9, 9, 0.15);
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 12px;
    text-transform: uppercase;
    color: #e20909;
  }
  p {
    font-size: 13px;
    color: #809399;
    margin-bottom: 20px;
  }
`;

type TableSpanProps = {
  text: string;
  className: string;
};
const TableSpan = ({ text, className }: TableSpanProps) => {
  return <TableSpanStyled className={className}>{text}</TableSpanStyled>;
};

const TableSpanStyled = styled.span`
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 11px;
  min-height: 17px;
  display: block;
  &.bank {
    background: #fffae6;
    color: #ff8b00;
  }
  &.credit {
    background: #e6fcff;
    color: #008da6;
  }
  &.delivery {
    background: #0065ff;
    color: white;
  }
  &.cash {
    background: #e3fcef;
    color: #006644;
  }
  &.takeaway {
    background: #6554c0;
    color: white;
  }
  &.completed {
    background: #36b37e;
    color: white;
  }
  &.cancelled {
    background: #ff5630;
    color: white;
  }
  &.theme-clr {
    background-color: var(--theme-clr);
    color: white;
  }
`;

type FilterBtnProps = {
  click: () => void;
};
const FilterBtn = ({ click }: FilterBtnProps) => {
  return (
    <FilterBtnStyled onClick={click}>
      <img src={filterIcon_path} />
      Filters
    </FilterBtnStyled>
  );
};

const FilterBtnStyled = styled.button`
  display: flex;
  align-items: center;
  padding: 0 27px;
  height: 40px;
  font-weight: 500;
  font-size: 15px;
  color: #4c6870;
  background-color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  svg {
    margin-right: 13px;
  }
`;

type DetailTextProps = {
  title: string;
  detail: string | number;
  classes?: string;
  htmlDetails?: {
    __html: string;
  };
  dir?: string;
};
const DetailText = ({
  title,
  detail,
  classes,
  htmlDetails,
  dir = 'ltr',
}: DetailTextProps) => {
  return (
    <DetailTextStyled className={classes}>
      <div className="row w-100">
        <div className="col-md-4">
          <span className="title">{title}</span>
        </div>
        <div className={`col-md-8`} dir={dir}>
          {htmlDetails !== undefined ? (
            <div className="detail" dangerouslySetInnerHTML={htmlDetails} />
          ) : (
            <span className="detail">{detail}</span>
          )}
        </div>
      </div>
    </DetailTextStyled>
  );
};

export const DetailTextStyled = styled.div`
  padding: 5px 0;
  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 10px;
    min-width: 105px;
    display: block;
    color: var(--dark-grey) !important;
  }
  .detail {
    color: #002733;
    img {
      max-width: 100%;
    }
  }
`;

type QtyDetailProps = {
  qty: string;
  detail: string;
};
const QtyDetail = ({ qty, detail }: QtyDetailProps) => {
  return (
    <QtyDetailStyled>
      <span>{qty}</span>
      <img src={xIcon_path} />
      <span>{detail}</span>
    </QtyDetailStyled>
  );
};

const QtyDetailStyled = styled.div`
  display: flex;
  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: -0.408px;
    color: #002733;
    margin: 0 0 10px 0;
    &:last-of-type {
      flex: 1;
    }
  }
  svg {
    margin: 6px 13px 10px 13px;
  }
`;

type SearchWindowProps = {
  className?: string;
  ID?: string;
  height?: string;
  change?: () => void;
};

const SearchWindow = ({ className, ID, height, change }: SearchWindowProps) => {
  return (
    <SearchWindowStyled className={className} height={height}>
      <img src={searchIcon_path} />
      <input type="text" id={ID} placeholder="Search" onChange={change} />
    </SearchWindowStyled>
  );
};

type SearchWindowStyledProps = {
  height?: string;
};
const SearchWindowStyled = styled.div<SearchWindowStyledProps>`
  width: 100%;
  height: ${(props) => props.height || '30px'};
  padding: 7px 0 7px 10px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid rgba(204, 212, 214, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  svg {
    width: 17px;
    height: 17px;
  }
  input {
    border: none;
    padding-left: 11px;
    height: 28px;
    flex: 1;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    color: #002733;
    &::placeholder {
      color: #b3bec2;
    }
  }
`;

export {
  DashboardHeading,
  TableSpan,
  FilterBtn,
  DetailText,
  QtyDetail,
  SearchWindow,
};
