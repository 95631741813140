import * as StoreActions from './store_actions';

const init = {
  error: '',
  stores: [],
  currentStore: null,
};
export default (state = init, action: any) => {
  switch (action.type) {
    case StoreActions.SET_MANAGED_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    case StoreActions.GET_STORE_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        currentStore: action.payload,
      };
    case StoreActions.LOGOUT_STORE:
      return {};

    default:
      return state;
  }
};
