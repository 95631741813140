import { Col, Row } from 'react-bootstrap';
import { attachmentIcon_path } from 'assets/index';
import GlobalStyle from 'styles/global_styled';
import {
  CardStyled,
  Description,
  HeadingStyled,
  Input,
  Label,
  SimpleNav,
  Textarea,
} from 'styles/style';
import { AttachedFiles } from './components';
import Button from 'silal_app_base_react/src/components/buttons/index';
const ContactUsPage = () => {
  return (
    <>
      <GlobalStyle />
      <div className="d-flex ">
        <div className="px-3">
          <SimpleNav>
            <h1>Contact Silal</h1>
          </SimpleNav>
          <Row className="mt-4">
            <Col lg={12} md={8}>
              <CardStyled>
                <HeadingStyled
                  className="primary-font"
                  style={{
                    fontSize: '19px',
                    fontWeight: '700',
                    margin: '0 0 10px 0',
                  }}
                >
                  Let’s solve the problem
                </HeadingStyled>
                <Description className="mb-20">
                  It looks like you have a problem with our system. We are here
                  to help you, so please get in touch with us.
                </Description>
                <Label>Contact Phone/Email</Label>
                <Input type="text" />
                <Label>Subject</Label>
                <Input type="text" />
                <Label>Your message</Label>
                <Textarea />
                <AttachedFiles name="Name_of_image.jpeg" />
                <div className="d-flex">
                  <Button
                    bg="#05AE4B33"
                    color="#05AE4B"
                    className="hover-fill me-2 position-relative"
                  >
                    <img src={attachmentIcon_path} />
                    Attach
                    <input type="file" className="file-input-none" />
                  </Button>
                  <Button className="flex-1 ms-1">Submit</Button>
                </div>
              </CardStyled>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
