import { useEffect, useState } from 'react';
import Select from 'react-select';
import { timeOptions } from 'core/constants/working_hours';
import { WeekDay } from 'data/types/general';
import { StoreSignUpForm } from 'data/types/store';
type QuestionaireWorkingHoursProps = {
  day: WeekDay;
  formData: StoreSignUpForm;
  enabledWorkDaysCount: number;
  handleWorkingTimes: (key: string, value: string) => void;
  setEnabledWorkDaysCount: (count: number) => void;
};

export default function QuestionaireWorkingHours({
  day,
  formData,
  enabledWorkDaysCount,
  handleWorkingTimes,
  setEnabledWorkDaysCount,
}: QuestionaireWorkingHoursProps) {
  const [isEnabled, setIsEnabled] = useState(true);
  const [start, setStart] = useState(timeOptions[16] ?? timeOptions[0]);
  const [end, setEnd] = useState(timeOptions[44] ?? timeOptions[1]);
  const [startProps, setStartProps] = useState({});
  const [endProps, setEndProps] = useState({});

  useEffect(() => {
    handleWorkingTimes(day.start_key, start.value);
    handleWorkingTimes(day.end_key, end.value);
  }, [isEnabled]);

  useEffect(() => {
    if (formData[day.start_key as keyof StoreSignUpForm]) {
      setIsEnabled(true);
      const start = timeOptions.find(
        (time) =>
          time.value === formData[day.start_key as keyof StoreSignUpForm],
      );
      setStart(start ?? timeOptions[0]);

      setStartProps({
        value: start,
        defaultValue: start,
        defaultInputValue: start,
      });
    }
    if (formData[day.end_key as keyof StoreSignUpForm]) {
      setIsEnabled(true);
      const end = timeOptions.find(
        (time) => time.value === formData[day.end_key as keyof StoreSignUpForm],
      );

      setEnd(end ?? timeOptions[1]);
      setEndProps({
        value: end,
        defaultValue: end,
        defaultInputValue: end,
      });
    }
  }, []);

  const getOptions = () => {
    return timeOptions.filter((time) => {
      if (
        Number(time.value.split(':')[0]) - 1 >=
          Number(start.value.split(':')[0]) ||
        time.value === '23:59'
      ) {
        return time;
      }
      return null;
    });
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="col-lg-1 d-flex justify-content-start align-items-start">
        <div className=" form-check CheckBox mt-2">
          <input
            onChange={(checked) => {
              setIsEnabled(checked.target.checked);
              if (checked.target.checked) {
                setEnabledWorkDaysCount(enabledWorkDaysCount + 1);
                setStart(timeOptions[16] ?? timeOptions[0]);
                setEnd(timeOptions[23] ?? timeOptions[1]);
              } else {
                setEnabledWorkDaysCount(enabledWorkDaysCount - 1);
                setEnd(start);
              }
            }}
            className="padd form-check-input"
            type="checkbox"
            {...startProps}
            checked={isEnabled && start !== end}
            id="flexCheckDefault"
          />
        </div>
        <h6 className="p-0 ml-2 mt-2">{day.name_en}</h6> {/* TODO localize */}
      </div>
      <div className="col-lg-5 d-flex">
        <h6>From</h6>

        <div className="area">
          <Select
            isDisabled={!isEnabled || start === end}
            options={timeOptions.filter((time) => {
              if (time.value !== '23:59') return time.value;
              return null;
            })}
            name={'workingTimeStart'}
            placeholder="10:00 AM"
            value={start}
            className="hours-select"
            {...startProps}
            defaultValue={start}
            onChange={(val) => {
              if (!val) return;
              handleWorkingTimes(day.start_key, val.value);
              setStart(val);
              setStartProps({
                value: val,
                defaultValue: val,
                defaultInputValue: val,
              });
              if (val.value === '23:00' || val.value === '23:30') {
                setEnd(timeOptions[44] ?? timeOptions[1]);
              } else if (end.value.split(':')[0] < val.value.split(':')[0]) {
                setEnd(
                  timeOptions[
                    timeOptions.findIndex((time) => time.value === val.value) +
                      1
                  ] ?? timeOptions[1],
                );
              }
            }}
          />
        </div>
      </div>
      <div className="col-lg-5 d-flex">
        <h6>till</h6>
        <div className="area">
          <Select
            name="workingTimeEnd"
            isDisabled={!isEnabled || start === end}
            options={getOptions()}
            placeholder="10:00 AM"
            className="hours-select"
            {...endProps}
            value={end}
            defaultValue={end}
            onChange={(val) => {
              if (!val) return;
              handleWorkingTimes(day.end_key, val.value);
              setEnd(val);
            }}
          />
        </div>
      </div>
    </div>
  );
}
