import { CurrentContries } from 'core/constants/constants';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Country } from 'data/types/general';

type CountrySelectFormProps = {
  setSelectedCountry: (country: Country) => void;
  value: Country;
};
export default function CountrySelectForm({
  setSelectedCountry,
  value,
}: CountrySelectFormProps) {
  const [countries, setCountries] = useState<Country[]>(CurrentContries);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    setCountries([
      {
        label: `IL +972`,
        value: '+972',
        default: value.value === '+972' ? true : false,
      },
    ]);
  };
  return (
    <Wrapper>
      <Select
        defaultValue={countries[0]}
        onChange={(country) => {
          if (country !== null) {
            setSelectedCountry(country);
          }
        }}
        options={countries}
        placeholder={value.value}
        className="CountryCodeSelect"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .CountryCodeSelect {
    width: 100%;
    border: none;
  }
`;
