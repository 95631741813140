const init = {};
import * as ProductsActions from './products_actions';

export default (state = init, action: any) => {
  switch (action.type) {
    case ProductsActions.LOGOUT_LISTINGS:
      return {};
    default:
      return state;
  }
};
