import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  max-width: 425px;
  width: 100%;
  height: fit-content;
  padding: 0px 25px 25px 25px;
  border-radius: 10px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: auto;
  .top-row {
    text-align: center;
    .title {
      padding: 25px 0;
      margin-top: 15px;
      font-size: 17px;
      font-weight: bold;
    }
    .form {
      margin-top: 20px;
      label {
        font-size: 15px;
        font-weight: 700;
        color: #4c6870;
      }
    }
  }
  .bottom-row {
    text-align: center;
    position: absolute;
    bottom: 68px;
    width: 100%;
    position: static;
    margin-top: 40px;
  }
`;

export default Wrapper;
