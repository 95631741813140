import { Form } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FlexContainer, HeadingStyled, NavStyled } from 'styles/style';
import { backArrowIcon_path } from 'assets/index';
import {
  Listing,
  SolrTemplateResponse,
} from 'silal_app_base_react/src/data/types/listings';
import Button from 'silal_app_base_react/src/components/buttons/index';
import { FormEvent } from 'react';
type NavProps = {
  subcategory?: string;
  listings?: Listing[];
  publicTemplates?: SolrTemplateResponse[];
  setSearch: (search: string) => void;
};
const Nav = ({
  subcategory,
  listings,
  publicTemplates,
  setSearch,
}: NavProps) => {
  const navigate = useNavigate();
  return (
    <>
      <FlexContainer>
        <NavStyled>
          <div className="d-flex">
            {!subcategory ? (
              <div />
            ) : (
              <button onClick={() => navigate(-1)} className="back-arrow">
                <img src={backArrowIcon_path} />
              </button>
            )}
          </div>
        </NavStyled>
        <HeadingStyled
          style={{
            fontSize: '25px',
            fontWeight: '700',
          }}
        >
          {subcategory ? subcategory : 'Categories'}
        </HeadingStyled>
        <SearchBar className="mx-5">
          <Form
            as="input"
            type="text"
            className="bg-white"
            placeholder="Search"
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setSearch(e.currentTarget.value)
            }
          />
          <Search className="icon-label position-absolute" size={17} />
        </SearchBar>
        <Link
          to={{
            pathname: '/products/templates',
          }}
          state={{
            listings: listings,
            publicTemplates,
          }}
          className="ms-auto d-block"
        >
          <Button className="w-230">Add new item</Button>
        </Link>
      </FlexContainer>
    </>
  );
};

const SearchBar = styled.div`
  width: 440px;
  position: relative;
  input {
    padding-left: 40px;
    width: 100%;
    padding-right: 20px;
    border: 1px solid rgba(204, 212, 214, 0.5);
    border-radius: 5px;
    height: 36px;
    &,
    &::placeholder {
      font-weight: 500;
      font-size: 13px;
      color: #b3bec2;
    }
  }
  .icon-label {
    color: #b3bec2;
    left: 0;
    top: 9px;
    left: 13px;
  }
`;

export default Nav;
