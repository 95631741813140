import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { TableStyled } from 'styles/style';
import Nav from '../components/navbar';
import DataTable from 'react-data-table-component';
import { ExpandedComponent } from 'pages/products/products_page';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from 'silal_app_base_react/src/components/spinner';
import {
  CURRENT_CURRENCY,
  LISTING_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  externalLinkIcon_path,
  productPlaceholder_path,
  notFoundIllustration_path,
} from 'assets/index';
import { getMinMaxPrice } from 'pages/products/functions/products_functions';
import { Listing } from 'silal_app_base_react/src/data/types/listings';
import { getCurrentStore } from 'core/hooks/use_selector';
import ListingsRepository from 'data/repositories/listings_repository';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';

const OutOfStockScreen = () => {
  const [items, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(true);
  const [publicListings, setPublicListings] = useState<Listing[]>([]);
  const currentStore = useSelector(getCurrentStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const publicListing = () => {
    setPublicListings(
      items.filter(
        (x: Listing) => x.listing_type_value === LISTING_TYPES.public_listing,
      ),
    );
  };
  useEffect(() => {
    publicListing();
  }, [items]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: '13px',
        fontWeight: 700,
      },
    },
  };

  const columns = [
    {
      name: 'Photo',
      cell: (row: Listing) =>
        row.images.length > 0 ? (
          <div style={{ width: '65px', height: '65px' }}>
            <ShowImage
              image={row.images.find((img) => img.id === row?.cover_image_id)}
              style={{ width: '65px', height: '65px', objectFit: 'scale-down' }}
            />
          </div>
        ) : (
          <img src={productPlaceholder_path} alt="mobile small" />
        ),
    },
    {
      name: 'Item name',
      width: '180px',
      selector: (row: Listing) => row.name_en,
    },
    {
      name: 'Listing ID',
      width: '100px',
      selector: (row: Listing) => row.id,
    },
    {
      name: 'Variations',
      width: '100px',
      selector: (row: Listing) => row.items.length.toString(), // Convert to string
    },
    {
      name: 'Last purchase',
      width: '150px',
      selector: (row: Listing) =>
        formateServerDateToLocaleDate(row.last_purchase_date),
    },
    {
      name: 'Current orders',
      selector: (row: Listing) => row.current_orders?.toString() ?? '0', // Convert to string
    },
    {
      name: 'Price',
      selector: (row: Listing) => {
        if (row.items?.length > 0) {
          return CURRENT_CURRENCY + getMinMaxPrice(row.items);
        } else {
          return CURRENT_CURRENCY + '0'; // Convert to string
        }
      },
    },
    {
      name: 'Edit',
      width: '50px',
      cell: (row: Listing) => (
        <div
          onClick={() => {
            navigate(`/products/listing-details/${row.id}`, {
              state: row,
              // previousPage: '/products',
            });
          }}
        >
          <img src={externalLinkIcon_path} alt="route to listing page" />
        </div>
      ),
    },
  ];

  // % PERF global state to save/cache products
  useEffect(() => {
    async function fetchSubCategories() {
      await ListingsRepository.paginateListingsDetails({
        store_id: currentStore.id,
        page: 1,
        per_page: 500,
      }).then((res) => {
        if (!res) return;
        setListings(
          res!.filter(
            (row: Listing) =>
              row.listing_type_value === LISTING_TYPES.public_listing &&
              row.items.filter((item) => !item.in_stock).length > 0,
          ),
        );
        setLoading(false);
      });
    }
    fetchSubCategories();
  }, [currentStore.id]);

  const getOutOfStockListingsOnly = (listings: Listing[]) => {
    const outOfStockListings = listings.filter((listing) => {
      const items = listing.items;
      const outOfStockItems = items.filter((item) => !item.in_stock);
      return outOfStockItems.length > 0;
    });
    return outOfStockListings;
  };

  const handleChangeInStockAndPrice = (item: any, data: any) => {
    // TODO fix any
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        if (!response) return;
        if (response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems = publicListings.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem: any) => dataItem.id === updatedItem.id, // TODO fix any
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });
          setListings(getOutOfStockListingsOnly(newItems));
        }
      },
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30%',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <Nav
          // items={items}
          //  subcategory={items?.category}
          />

          <Row className="pt-3" style={{ overflowX: 'auto' }}>
            <TableStyled>
              {items.length === 0 ? (
                <div className="no-orders">
                  <img src={notFoundIllustration_path} alt="no-orders" />
                  <h3>No products were found</h3>
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={items}
                  customStyles={customStyles}
                  pagination
                  paginationPerPage={30}
                  expandableRows
                  expandOnRowClicked
                  expandableRowsComponent={ExpandedComponent(
                    handleChangeInStockAndPrice,
                  )}
                  expandableRowsComponentProps={{
                    someTitleProp: 'someTitleProp',
                  }}
                  // selectableRows
                />
              )}
            </TableStyled>
          </Row>
        </div>
      )}
    </div>
  );
};

export default OutOfStockScreen;
