export const UPDATE_STORE = 'UPDATE_STORE';

export const GET_MANAGED_STORES = 'GET_MANAGED_STORES';
export const SET_MANAGED_STORES = 'SET_MANAGED_STORES';

export const GET_STORE_INFO_BY_ID_SUCCESS = 'GET_STORE_INFO_BY_ID_SUCCESS';
export const GET_STORE_INFO_BY_ID = 'GET_STORE_INFO_BY_ID';

export const LOGOUT_STORE = 'LOGOUT_STORE';

export const actionUpdateStore = (payload: any) => ({
  type: UPDATE_STORE,
  payload,
});

export const actionGetManagedStores = (payload?: any) => ({
  type: GET_MANAGED_STORES,
  payload,
});

export const actionSetManagedStores = (payload: any) => ({
  type: SET_MANAGED_STORES,
  payload,
});

export const actionGetStoreInfoByID = (payload?: any) => ({
  type: GET_STORE_INFO_BY_ID,
  payload,
});

export const actionGetStoreInfoByIDSuccess = (payload: any) => ({
  type: GET_STORE_INFO_BY_ID_SUCCESS,
  payload,
});

export const actionLogoutStore = () => ({
  type: LOGOUT_STORE,
});
