import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { getAppropriateSidebar } from 'routes/functions/permissions_functions';
import Sider from 'antd/es/layout/Sider';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import Wrapper from './sidebar.styled';
import { usePersistState } from 'silal_app_base_react/src/hooks/usePersistState';

const Sidebar = () => {
  const navigate = useNavigate();
  const menu = getAppropriateSidebar().filter(Boolean);
  const [selected, setSelected] = usePersistState(
    (menu[0] as any).id,
    'selectedMenu',
  );
  const [collapsed, setCollapsed] = useState(false);

  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      setSelected(menu[0].id);
    }
  }, []);

  const items: MenuItem[] = menu.map((item: any) => {
    return getItem(
      item.title,
      item.id,
      <img width={20} height={20} src={item.icon} alt="icon" />,
    );
  });

  const go = (e: any) => {
    for (const item of menu) {
      if (item && item.id === e.key) {
        navigate(item.path);
        setSelected(e.key);
        return;
      }
    }
  };

  return (
    <Wrapper>
      <Sider
        collapsible
        collapsed={collapsed}
        theme="light"
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo">
          <h3
            style={{
              fontFamily: Fonts.primary,
            }}
          >
            {collapsed ? 'Silal' : 'Silal Merchant'}
          </h3>
        </div>
        <Menu
          theme="light"
          color="green"
          defaultSelectedKeys={[(menu[0] as any).id]}
          selectedKeys={[selected]}
          mode="inline"
          items={items}
          style={{
            fontFamily: Fonts.primary,
            fontWeight: 500,
          }}
          onClick={(e) => go(e)}
          onSelect={(e) => go(e)}
        />
      </Sider>
    </Wrapper>
  );
};

export default Sidebar;
