import { logoutIcon_path } from 'assets/index';
import SellerRepository from 'data/repositories/seller_repository';
import { StoreState, UserState } from 'data/types/state';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { LOCAL_STORAGE_TOKEN_KEY } from 'silal_app_base_react/src/config/constants';
import { actionLogoutImages } from 'silal_app_base_react/src/store/images/image_library_actions';
import { actionLogoutCategories } from 'silal_app_base_react/src/store/categories/categories_actions';
import { actionLogoutOrders } from 'store/orders/orders_actions';
import { actionLogoutListings } from 'store/products/products_actions';
import { actionLogoutStore } from 'store/store/store_actions';
import { actionLogoutUser } from 'store/user/user_actions';

const isUserAuhtorized = (state: UserState): boolean => {
  const storedToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (storedToken && state.token && storedToken === state.token) {
    return true;
  } else {
    return false;
  }
};

const isUserSemiAuthenticated = (state: UserState): boolean => {
  const storedToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (
    storedToken &&
    state.user?.id &&
    state.user?.first_name &&
    state.user?.last_name &&
    state.user?.id_number &&
    state.token &&
    storedToken === state.token
  ) {
    return true;
  } else {
    return false;
  }
};

function isUserFullyAuthenticated({
  userState,
  currentStore,
}: {
  userState: UserState;
  currentStore: StoreState['currentStore'];
}): boolean {
  return Boolean(
    userState.user?.id &&
      currentStore?.id &&
      isUserSemiAuthenticated(userState),
  );
}

const LogoutIconButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <button
      className="m-3 logout-btn"
      onClick={() => fullLogout(dispatch, navigate)}
    >
      <img className="p-1" src={logoutIcon_path} alt="logout-btn" />
      Logout
    </button>
  );
};

export const fullLogout = (dispatch: Dispatch, navigate: NavigateFunction) => {
  localStorage.clear();
  dispatch(actionLogoutStore());
  dispatch(actionLogoutUser());
  dispatch(actionLogoutImages());
  dispatch(actionLogoutCategories());
  dispatch(actionLogoutOrders());
  dispatch(actionLogoutListings());
  navigate('/welcome');
};

export const fullLogoutFromAll = async (
  dispatch: Dispatch,
  navigate: NavigateFunction,
) => {
  await SellerRepository.universalLogout().then((res) => {
    if (!res) toast.warn('Error logging out');
    localStorage.clear();
    dispatch(actionLogoutStore());
    dispatch(actionLogoutUser());
    dispatch(actionLogoutImages());
    dispatch(actionLogoutOrders());
    dispatch(actionLogoutListings());
    navigate('/welcome');
  });
};

export {
  isUserAuhtorized,
  isUserSemiAuthenticated,
  isUserFullyAuthenticated,
  LogoutIconButton,
};
