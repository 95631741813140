import { useState, useEffect } from 'react';
import Wrapper from './splash_screen.styled';
import { silal_logo_white } from 'assets/index';
// import hooks
function Splashpage() {
  const [boot, setBoot] = useState(true);

  useEffect(() => {
    const goto = setTimeout(() => {
      setBoot(false);
      window.location.href = '/landing.html';
    }, 3000);
    return () => {
      clearTimeout(goto);
    };
  }, []);

  return (
    <Wrapper>
      <div className="title">
        <img src={silal_logo_white} alt="logo" className="whitebox" />
        <div className="header">
          <h2>Silal</h2>
          <p>Merchants</p>
        </div>
      </div>
    </Wrapper>
  );
}

export default Splashpage;
