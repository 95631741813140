type CountdowntimerProps = {
  minutes: number;
  seconds: number;
  completed: boolean;
};
export default function Countdowntimer({
  minutes,
  seconds,
  completed,
}: CountdowntimerProps) {
  return (
    <div>
      {completed ? (
        'Resend code'
      ) : (
        <span>
          Resend code {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )}
    </div>
  );
}
