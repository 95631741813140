import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { ServerException } from 'silal_app_base_react/src/config/errors/server_exceptions';
import { ApiRepository } from 'silal_app_base_react/src/data/repositories/base_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { toast } from 'react-toastify';
import { AUTH_API_BASE } from 'silal_app_base_react/src/config/constants';
class AuthenticationApiRepository extends ApiRepository {
  private static instance: AuthenticationApiRepository | null = null;
  constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): AuthenticationApiRepository {
    if (!AuthenticationApiRepository.instance) {
      AuthenticationApiRepository.instance = new AuthenticationApiRepository(
        axiosInstance,
      );
    }
    return AuthenticationApiRepository.instance;
  }

  // ? Send OTP functions, used only on representation layer

  loginSendPhoneOTP = async (phone: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', '/login/phone/send_otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            phone: phone,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 429) {
        throw new ServerException(res.data);
      }

      return res.data;
    } catch (e: any) {
      toast.error(e.res.error || (e.message as string));
      return false;
    }
  };

  loginSendEmailOTP = async (email: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', '/login/email/send_otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            email: email,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 429) {
        throw new ServerException(res.data);
      }

      return res.data;
    } catch (e: any) {
      toast.error(e.res.error || (e.message as string));
      return false;
    }
  };

  signupSendEmailOTP = async (email: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/sign_up/email/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            email: email,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201 && res.status !== 202) {
        throw new ServerException(res.data);
      }
      toast.success(res.data.msg);
      return res.data;
    } catch (e: any) {
      toast.error(
        e?.res?.error ||
          e?.message ||
          'Unknown error occured in sending signup email OTP',
      );
      return false;
    }
  };

  signupSendPhoneOTP = async (phone: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/sign_up/phone/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            phone: phone,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );

      if (res.status !== 200 && res.status !== 201 && res.status !== 202) {
        throw new ServerException(res.data);
      }
      toast.success(res.data.msg);
      return res.data;
    } catch (e: any) {
      toast.error(
        e?.res?.error ||
          e?.message ||
          'Unknown error occured in sending signup phone OTP',
      );
      return false;
    }
  };

  // ? Login functions, used only bloc layer (redux)

  loginEmailVerifyPassword = async (email: string, password: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/login/email_pass/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            email: email,
            password: password,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }

      return res.data.bearer as string;
    } catch (e: any) {
      toast.error(
        (e?.message as string) ||
          'Unknown error occured in login via email and password',
      );
      return false;
    }
  };

  loginEmailVerifyOTP = async (email: string, otp: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/login/email/otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            email: email,
            otp: otp,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }

      return res.data.bearer as string;
    } catch (e: any) {
      toast.error(e.message as string);
      return false;
    }
  };

  loginPhoneVerifyOTP = async (phone: string, otp: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/login/phone/otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            phone: phone,
            otp: otp,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }

      return res.data.bearer as string;
    } catch (e: any) {
      toast.error(e.message as string);
      return false;
    }
  };

  // ? Signup functions, used only bloc layer (redux)
  // ! signupVerifyEmailOTP currently unused as user MUST have phone first, the Phone signupVerifyPhoneOTP is used normally
  signupVerifyEmailOTP = async (email: string, otp: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/sign_up/email/otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            email: email,
            otp: otp,
          },
        }),
        {
          noSuccessMsg: true,
        },
      );
      if (res.status !== 200) {
        throw new ServerException(res.data);
      }

      return res.data.bearer as string;
    } catch (e: any) {
      toast.error(e.message as string);
      return false;
    }
  };

  signupVerifyPhoneOTP = async (phone: string, otp: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/sign_up/phone/otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            phone: phone,
            otp: otp,
          },
        }),
        {
          noSuccessMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }

      return res.data.bearer as string;
    } catch (e: any) {
      toast.error(e.message as string);
      return false;
    }
  };
}

const AuthenticationRepository =
  AuthenticationApiRepository.getInstance(sellerAxiosInstance);
export default AuthenticationRepository;
