import moment from 'moment';
import { MINUTES_BEFORE_SHOWING_ORDER_LATE_ICON } from 'core/constants/shipping';
import {
  DateTimeFormat,
  ISRAEL_TIMEZONE,
} from 'silal_app_base_react/src/config/constants';
import momentZ from 'moment-timezone';
import { OrderAction } from 'silal_app_base_react/src/data/types/orders';
import { getLatestStatus } from 'silal_app_base_react/src/pages/orders_archive/functions/order_functions';

function diff_minutes(dt2: Date, dt1: Date) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export const getLatestStatusDate = (actions: OrderAction[]) => {
  const lastStatus = getLatestStatus(actions);
  return lastStatus?.action_date;
};

export const checkOrderLateOrNot = (actions: OrderAction[]) => {
  const now = new Date();
  const last_status_date = getLatestStatusDate(actions);
  return diff_minutes(now, new Date(last_status_date ?? '')) >
    MINUTES_BEFORE_SHOWING_ORDER_LATE_ICON
    ? true
    : false;
};

export const getOrderTimestamp = (action_date: string) => {
  const timeZonedTime = momentZ
    .utc(action_date, DateTimeFormat)
    .tz(ISRAEL_TIMEZONE);
  return (
    moment(timeZonedTime).fromNow() + ' - ' + moment(timeZonedTime).calendar()
  );
};
