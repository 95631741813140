import { UserData } from 'data/types/state';
import * as UserActions from './user_actions';
import { LOCAL_STORAGE_TOKEN_KEY } from 'silal_app_base_react/src/config/constants';

const init = {
  user: null as UserData | null,
  token: null as string | null,
};
export default (state = init, action: any) => {
  switch (action.type) {
    case UserActions.SET_TOKEN:
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, action.payload);
      return { ...state, token: action.payload };
    case UserActions.SET_USER:
      return { ...state, user: action.payload };
    case UserActions.LOGOUT_USER:
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
      return {};
    default:
      return state;
  }
};
