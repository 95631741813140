import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  cycleIcon_path,
  noOrdersIllustration_path,
  notFoundGhostIllustration_path,
} from 'assets/index';
import OrderEstimationTimeModal from '../archive/order_estimation_modal';
import { CardStyled, Heading } from 'styles/style';
import {
  AcceptedOrder,
  PickupOrder,
  ReceivedOrder,
} from '../components/current_orders_components';
import { PickupCard } from '../components/current_orders_components.styled';
import { ORDER_STATUS } from 'silal_app_base_react/src/data/types/orders';
import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';
import { getCurrentOrdersState } from 'core/hooks/use_selector';
import { actionGetAllOrders } from 'store/orders/orders_actions';
import { Fonts } from 'silal_app_base_react/src/config/theme';

const CurrentOrder = () => {
  const { orders } = useSelector(getCurrentOrdersState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionGetAllOrders([]));
  }, []);
  const filterOrders = useMemo(() => {
    function sortOrders(a: OrderBrief, b: OrderBrief, index: number) {
      return (
        new Date(
          a.order_actions.find((i) => i.action === index)?.action_date ?? '',
        ).getTime() -
        new Date(
          b.order_actions.find((i) => i.action === index)?.action_date ?? '',
        ).getTime()
      );
    }
    const received = orders
      ?.filter((i) => i.status === ORDER_STATUS.requested)
      .sort((a, b) => sortOrders(a, b, ORDER_STATUS.requested))
      .reverse();
    const accepted = orders
      ?.filter((i) => i.status === ORDER_STATUS.accepted)
      .sort((a, b) => sortOrders(a, b, ORDER_STATUS.accepted))
      .reverse();
    const readyToShip = orders
      ?.filter(
        (i) =>
          i.status === ORDER_STATUS.ship__at_silal__ready ||
          i.status === ORDER_STATUS.ship__in_store__ready,
      )
      .sort((a, b) =>
        sortOrders(
          a,
          b,
          ORDER_STATUS.ship__at_silal__ready ||
            ORDER_STATUS.ship__in_store__ready,
        ),
      )
      .reverse();
    const shippedOrders = orders
      ?.filter(
        (i) =>
          i.status === ORDER_STATUS.ship__in_transit__to_silal ||
          i.status === ORDER_STATUS.ship__in_transit__to_customer,
      )
      ?.sort((a, b) =>
        sortOrders(
          a,
          b,
          ORDER_STATUS.ship__in_transit__to_silal ||
            ORDER_STATUS.ship__in_transit__to_customer,
        ),
      )
      .reverse();

    return {
      received,
      accepted,
      readyToShip,
      shippedOrders,
    };
  }, [orders]);

  const [modalShow, setModalShow] = useState(false);
  return (
    orders !== undefined && (
      <>
        <OrderEstimationTimeModal
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
        {orders ? (
          <Row
            style={{
              maxHeight: '90vh',
            }}
          >
            <Col lg={5} md={12}>
              <CardStyled
                className="bg-grey current-order-card-height "
                style={{
                  display: !filterOrders.received.length ? 'flex' : '',
                }}
              >
                <Heading>Received</Heading>
                {filterOrders.received?.map((order) => {
                  return (
                    <ReceivedOrder
                      key={order.id}
                      orderDetail={order}
                      // clockIcon={true}
                    />
                  );
                })}
                {!filterOrders.received.length && EmptyRevievedOrders}
              </CardStyled>
            </Col>
            <Col lg={5} md={12}>
              <CardStyled
                className="bg-grey current-order-card-height"
                style={{
                  display: !filterOrders.accepted.length ? 'flex' : '',
                }}
              >
                <Heading>Accepted</Heading>
                {filterOrders.accepted?.map((order) => {
                  return (
                    <AcceptedOrder
                      key={order.id}
                      order={order}
                      // showEstimationModal={setModalShow}
                    />
                  );
                })}
                {!filterOrders.accepted.length && EmptyAcceptedOrders}
              </CardStyled>
            </Col>
            <Col lg={2} md={12}>
              <PickupCard>
                <div className="body">
                  <Heading className="text-white">Ready for pickup</Heading>
                  <Row>
                    {filterOrders.readyToShip.map((order) => {
                      return (
                        <Col lg={12} sm={4} key={order.id}>
                          <PickupOrder order={order} />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <div className="bottom center">
                  <img src={cycleIcon_path} style={{ padding: '5px' }} />
                  <div>
                    <h2>on-way</h2>
                    <h3>{filterOrders.shippedOrders.length} orders</h3>
                  </div>
                </div>
              </PickupCard>
            </Col>
          </Row>
        ) : (
          <Spinner />
        )}
      </>
    )
  );
};

const EmptyRevievedOrders = (
  <div
    className="bg-transparent rounded d-flex flex-column align-items-center justify-content-center"
    style={{
      fontFamily: Fonts.primary,
    }}
  >
    <img src={notFoundGhostIllustration_path} width={'70%'} alt="no-orders" />
    <h4 className="pt-3 text-center font-weight-bold">No Recieved Orders</h4>
    <h6 className="p-1 text-center font-weight-bold">
      There are no new orders right now, any new ones will appear right here.
    </h6>
  </div>
);
const EmptyAcceptedOrders = (
  <div
    className="bg-transparent rounded d-flex flex-column align-items-center justify-content-center"
    style={{
      fontFamily: Fonts.primary,
    }}
  >
    <img src={noOrdersIllustration_path} width={'40%'} alt="no-orders" />
    <h4 className="pt-3 text-center font-weight-bold">No Accepted Orders</h4>
    <h6 className="p-1 text-center font-weight-bold">
      Take a break, there are not orders that need preparing right now.
    </h6>
  </div>
);

export default CurrentOrder;
