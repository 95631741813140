export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';

export const LOGIN_WITH_EMAIL_PASSWORD = 'LOGIN_WITH_EMAIL_PASSWORD';
export const LOGIN_OR_VERIFY_EMAIL_OTP = 'LOGIN_OR_VERIFY_EMAIL_OTP';
export const LOGIN_OR_VERIFY_PHONE_OTP = 'LOGIN_OR_VERIFY_PHONE_OTP';

export const SIGNUP_EMAIL_VERIFY_OTP = 'SIGNUP_EMAIL_VERIFY_OTP';
export const SIGNUP_PHONE_VERIFY_OTP = 'SIGNUP_PHONE_VERIFY_OTP';

export const GET_SET_USER_PROFILE = 'GET_SET_USER_PROFILE';
export const DELETE_EMAIL = 'DELETE_EMAIL';

export const LOGOUT_USER = 'LOGOUT_USER';

// ? Setters: Token and User
export const actionSetToken = (payload: any) => ({
  type: SET_TOKEN,
  payload,
});

export const actionSetUser = (payload: any) => ({
  type: SET_USER,
  payload,
});

// ? Personal Information
export const actionGetSetUserProfile = (payload?: any) => ({
  type: GET_SET_USER_PROFILE,
  payload,
});

export const actionDeleteEmail = () => ({
  type: DELETE_EMAIL,
});

// ? Signup
// Email Signup OTP Verification
// ! Currently unused as user MUST have phone first, the Phone Signup OTP Verification is used normally.
export const actionSignupEmailVerifyOtp = (payload: any) => ({
  type: SIGNUP_EMAIL_VERIFY_OTP,
  payload,
});

// Phone Signup OTP Verification
export const actionSignupPhoneVerifyOtp = (payload: any) => ({
  type: SIGNUP_PHONE_VERIFY_OTP,
  payload,
});

// ? Login
// Email Login Password Verification
export const actionLoginWithEmailPassword = (payload: any) => ({
  type: LOGIN_WITH_EMAIL_PASSWORD,
  payload,
});

// Email Login OTP Verification
export const actionLoginOrVerifyEmailOtp = (payload: any) => ({
  type: LOGIN_OR_VERIFY_EMAIL_OTP,
  payload,
});

// Phone Login OTP Verification
export const actionLoginOrVerifyPhoneOtp = (payload: any) => ({
  type: LOGIN_OR_VERIFY_PHONE_OTP,
  payload,
});

// ? Logout
export const actionLogoutUser = () => ({
  type: LOGOUT_USER,
});
