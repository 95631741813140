import { getCurrentStore } from 'core/hooks/use_selector';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { GET_ORDERS, actionSetCurrentOrders } from './orders_actions';

import OrdersRepository from 'data/repositories/orders_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';

export function* sagaGetAllOrders() {
  function* getAllOrders(action: any) {
    const currentStore: StoreDetails = yield select(getCurrentStore);
    const res: OrderBrief[] = yield call(
      //TODO this shit doesnt even exist?
      OrdersRepository.getStoreOrdersOfStatus,
      currentStore.id,
      action.payload,
    );
    if (!res) return false;
    yield put(actionSetCurrentOrders(res));
  }
  yield takeEvery(GET_ORDERS, getAllOrders);
}
