export const FILES_MESSAGES = {
  CoverImageUpdated: 'Cover Image Updated Successfully!',
  OwnerIDImageUpdated: 'ID Image Updated Successfully!',
  BusinessBankStatementUpdated: 'Business Bank Statement Updated Successfully!',
  TaxCertificateUpdated: 'Tax Certificate Updated Successfully!',
  DASCertificateUpdated: 'DAS Certificate Updated Successfully!',
};
export const LISTINGS_MESSAGES = {
  ListingCreated: 'Listing Created Successfully!',
  ListingDeleted: 'Listing Deleted Successfully!',
  ListingUpdated: 'Listing Updated Successfully!',
  ListingFrozen: 'Listing Frozen Successfully!',
  ListingUnfrozen: 'Listing Unfrozen Successfully!',
};
export const STORES_MESSAGES = {
  StoreCreated: 'Store Created Successfully!',
  StoreUpdated: 'Store Updated Successfully!',
  StoreDeleted: 'Store Deleted Successfully!',
  MerchantAccessRevoked: 'Merchant Access Revoked Successfully!',
  MerchantAccessGranted: 'Merchant Access Granted Successfully!',
};
export const SELLER_MESSAGES = {
  MerchantCreated: 'Merchant Created Successfully!',
  MerchantUpdated: 'Merchant Updated Successfully!',
  MerchantDeleted: 'Merchant Deleted Successfully!',
  UniversalLogout: 'Logged Out From All Sessions Successfully!',
  EmailDeleted: 'Email Deleted Successfully!',
};
