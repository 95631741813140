import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { StoreTransaction } from 'silal_app_base_react/src/data/types/transactions';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
class TransactionsApiRepository extends AuthenticatedApiRepository {
  private static instance: TransactionsApiRepository | null = null;
  constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): TransactionsApiRepository {
    if (!TransactionsApiRepository.instance) {
      TransactionsApiRepository.instance = new TransactionsApiRepository(
        axiosInstance,
      );
    }
    return TransactionsApiRepository.instance;
  }

  getStoreLedger = async (store_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/transactions/ledger/json/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      return res.data.json_ledger as StoreTransaction[];
    } catch (e: any) {
      return false;
    }
  };
}

const TransactionsRepository = TransactionsApiRepository.getInstance(
  sellerAxiosInstance as any,
);
export default TransactionsRepository;
