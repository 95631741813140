import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  useLocation,
  NavigateOptions,
  useParams,
} from 'react-router-dom';

import ShowImage from 'silal_app_base_react/src/components/show_image';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { Heading, TableStyled } from 'styles/style';
import { CategoryComponent } from '../components/index';
import Nav from '../components/products_header';
import {
  notFoundIllustration_path,
  externalLinkIcon_path,
  productPlaceholder_path,
} from 'assets/index';
import {
  LISTING_STATUS_COLORS,
  LISTING_STATUS_TYPES,
  LISTING_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  ListingItem,
  Listing,
  SolrTemplateResponse,
} from 'silal_app_base_react/src/data/types/listings';
import { ExpandedComponent } from '../products_page';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import { Category } from 'silal_app_base_react/src/data/types/categories';
import { getCurrentStoreState } from 'core/hooks/use_selector';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { fetchCategoryListings } from '../functions/products_functions';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { RootState } from 'data/types/state';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
const SubcategoryScreen = () => {
  const { categoryId } = useParams();

  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );

  const location = useLocation();
  const navigate = useNavigate();
  const { currentStore } = useSelector(getCurrentStoreState);
  const [listings, setListings] = useState<Listing[]>([]);
  const [publicTemplates, setPublicTemplates] = useState<
    SolrTemplateResponse[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!minimalCategoriesList) dispatch(actionGetCategoriesMinimalList());
  }, []);

  useEffect(() => {
    async function fetchManagementTemplates() {
      await SolrRepository.searchManagementSilalOwnedTemplates({
        query: search,
      }).then((res: any) => {
        if (!res) return;
        const resX = res
          .filter((x: any) => x.category_id.toString() === categoryId)
          .map((x: any) => {
            return {
              ...x,
              listing_type_value: LISTING_TYPES.template,
            };
          });
        setLoading(false);
        setPublicTemplates(resX);
      });
    }
    fetchCategoryListings(setListings, currentStore.id, categoryId, setLoading);

    fetchManagementTemplates();
  }, [categoryId, currentStore]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: '13px',
        fontWeight: 700,
      },
    },
  };

  const columns = [
    {
      name: 'Photo',
      width: '10%',
      selector: (row: Listing) =>
        row.images.length > 0 ? (
          <div style={{ width: '60px', height: '60px' }}>
            <ShowImage
              image={row.images.find((img) => img.id === row?.cover_image_id)}
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        ) : (
          // <img src={row.images[0].url} alt={row.images[0].alt} />
          <img src={productPlaceholder_path} />
        ),
    },
    {
      name: 'Item name',
      width: '15%',
      selector: (row: Listing) => row.name_en, // TODO localize
    },
    {
      name: 'Listing ID',
      width: '10%',
      selector: (row: Listing) => row.id,
    },
    {
      name: 'Variations',
      width: '10%',
      selector: (row: Listing) => row.items.length,
    },
    {
      name: 'Last purchase',
      width: '10%',
      selector: (row: Listing) =>
        formateServerDateToLocaleDate(row.last_purchase_date),
    },
    {
      name: 'Current orders',
      width: '10%',
      selector: (row: Listing) => row.current_orders,
    },
    {
      name: 'Price',
      width: '10%',

      selector: (row: Listing) => formatPrice(row.items[0].price),
    },
    {
      name: 'Status',
      width: '10%',

      selector: (row: Listing) => {
        return (
          <p
            className="p-1"
            style={{
              fontSize: '12px',
              borderRadius: '5px',
              color: 'white',
              backgroundColor:
                LISTING_STATUS_COLORS[
                  row.listing_status_num as keyof typeof LISTING_STATUS_COLORS
                ],
            }}
          >
            {
              LISTING_STATUS_TYPES[
                row.listing_status_num as keyof typeof LISTING_STATUS_TYPES
              ]
            }
          </p>
        );
      },
    },
    {
      name: 'Edit',
      width: '10%',
      cell: (row: Listing) => (
        <div
          onClick={() => {
            // TODO removed previousPage: '/products'
            const navigationOptions: NavigateOptions = {
              state: row,
            };
            navigate(`/products/listing-details/${row.id}`, navigationOptions);
          }}
        >
          <img src={externalLinkIcon_path} alt="route to listing page" />
        </div>
      ),
    },
  ];

  const handleChangeInStockAndPrice = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        if (!response) return;
        if (response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems: Listing[] | undefined = listings?.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem) => dataItem.id === updatedItem.id,
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });
          setListings(newItems?.filter((i) => i));
        }
      },
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  const filteredItems = () => {
    const normalizedSearch = search.toLowerCase();

    if (search !== '') {
      return listings.filter(
        (item) =>
          item.name_en.toLowerCase().includes(normalizedSearch) ||
          item.name_ar.toLowerCase().includes(normalizedSearch),
      );
    }

    return listings;
  };

  return (
    <>
      <Nav
        listings={listings}
        publicTemplates={publicTemplates}
        subcategory={
          minimalCategoriesList.find((x) => x.id === parseInt(categoryId!))
            ?.name_en
        }
        setSearch={setSearch}
      />
      <Row>
        <Row className="pt-3">
          <Col lg={12} md={12} sm={12}>
            <Heading>General</Heading>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <CategoryComponent
              link={`/products/${categoryId}/templates`}
              state={{
                publicTemplates: publicTemplates.filter(
                  (x) => x.category_id === parseInt(categoryId!),
                ),
                listings: listings.filter(
                  (x) => x.category_id === parseInt(categoryId!),
                ),
              }}
              categoryName="Templates"
              items_length={publicTemplates?.length}
              className="grey"
            />
          </Col>
          <Col lg={3} md={4} sm={6}>
            <CategoryComponent
              link={`/products/${categoryId}/submitted`}
              state={listings?.filter(
                (x) =>
                  x.listing_type_value === LISTING_TYPES.public_listing &&
                  x.category_id === parseInt(categoryId!),
              )}
              categoryName="Submitted"
              items_length={
                listings?.filter(
                  (x) => x.listing_type_value === LISTING_TYPES.public_listing,
                )?.length ?? 0
              }
              className="grey"
            />
          </Col>
        </Row>
        {loading ? (
          <Spinner />
        ) : (
          <TableStyled>
            <h3 className="h3">Products</h3>
            {filteredItems()?.length === 0 ? (
              <div className="no-orders">
                {loading ? (
                  <>
                    <Spinner />
                    <h3>Loading from server...</h3>
                  </>
                ) : (
                  <>
                    <img src={notFoundIllustration_path} alt="no-orders" />
                    <h3>No products were found</h3>
                  </>
                )}
              </div>
            ) : (
              <DataTable
                columns={columns as any}
                data={filteredItems()}
                customStyles={customStyles}
                pagination
                paginationPerPage={20}
                expandableRows
                expandOnRowClicked
                expandableRowsComponent={ExpandedComponent(
                  handleChangeInStockAndPrice,
                )}
                expandableRowsComponentProps={{
                  someTitleProp: 'someTitleProp',
                }}
                // selectableRows
              />
            )}
          </TableStyled>
        )}
      </Row>
    </>
  );
};

export default SubcategoryScreen;
