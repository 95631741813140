import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { imageDarkIcon_path, xIcon_path } from 'assets/index';

const AttachedFiles = ({ name }: { name: string }) => {
  return (
    <AttachedFilesSyled>
      <div className="d-flex">
        <img src={imageDarkIcon_path} />
        <p>{name}</p>
      </div>
      <img src={xIcon_path} />
    </AttachedFilesSyled>
  );
};

const AttachedFilesSyled = styled.div`
  background: #f2f4f5;
  border-radius: 8px;
  padding: 12px 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 8px;
  }
  p {
    font-family: ${Fonts.secondary};
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.408px;
    color: #4c6870;
    margin: 0;
  }
  .del {
    cursor: pointer;
    path {
      fill: #fb5c5c;
    }
  }
`;

export { AttachedFiles };
