import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import {
  //  deleteIcon_path,
  rightArrowIcon_path,
} from 'assets/index';
import GlobalStyle from 'styles/global_styled';
import {
  CardStyled,
  HeadingStyled,
  Radio,
  SimpleNav,
  ThemeModal,
} from 'styles/style';
import Button from 'silal_app_base_react/src/components/buttons/index';
import LanguageCanvas from './modals/language_modal';
import { SettingBtn, Wrapper } from './settings_page.styled';
import Prefrences from './flows/security_preferences_flow';
import InviteWorkForce from './flows/invite_work_force_flow';
import { backArrowIcon_path } from 'assets/index';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fullLogout,
  fullLogoutFromAll,
} from 'routes/functions/routes_functions';
import { actionLogoutStore } from 'store/store/store_actions';
import {
  actionSetLastFetchedSilalImages,
  actionSetLastFetchedStoreImages,
} from 'silal_app_base_react/src/store/images/image_library_actions';
import { toast } from 'react-toastify';

const SettingsPage = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [preOrder, setPreOrder] = useState(false);
  const [securityPrefrences, setSecurityPrefrences] = useState(false);
  const [canvasShow, setCanvasShow] = useState(false);
  const [language, setLanguage] = useState('English');
  const [workForce, setWorkForce] = useState(false);
  const [logoutType, setLogoutType] = useState(0);

  // const [showDelModal, setShowDelModal] = useState(false);
  // const handleCloseDelModal = () => setShowDelModal(false);
  // const handleShowDelModal = () => setShowDelModal(true);
  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
    setLogoutType(0);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="d-flex">
        <div />
        <div className="px-3">
          <SimpleNav>
            <h1>Settings</h1>
          </SimpleNav>
          <div className="mt-5">
            {workForce ? (
              <InviteWorkForce back={setWorkForce} />
            ) : (
              <>
                {securityPrefrences ? (
                  <Prefrences back={setSecurityPrefrences} />
                ) : (
                  <>
                    {preOrder ? (
                      <>
                        <div className="d-flex mb-3">
                          <button
                            type="button"
                            className="back-arrow"
                            onClick={() => setPreOrder(false)}
                          >
                            <img src={backArrowIcon_path} />
                          </button>
                          <HeadingStyled
                            className="primary-font mb-0"
                            style={{
                              fontSize: '19px',
                              fontWeight: '700',
                            }}
                          >
                            Pre-order settings
                          </HeadingStyled>
                        </div>
                        <Row>
                          <Col>
                            <CardStyled>
                              <HeadingStyled
                                className="secondary-font mb-1 pre"
                                style={{
                                  fontSize: '15px',
                                  fontWeight: '700',
                                }}
                              >
                                Allow pre-orders
                              </HeadingStyled>
                              <Radio>
                                Always
                                <input type="radio" name="pre-orders" />
                                <span className="checkmark" />
                              </Radio>
                              <Radio>
                                Never
                                <input type="radio" name="pre-orders" />
                                <span className="checkmark" />
                              </Radio>
                            </CardStyled>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {/*                      
                        <SettingBtn
                          className="between"
                          onClick={() => setPreOrder(true)}
                        >
                          Pre-order settings
                          <img src={rightArrowIcon_path} className="arrow" />
                        </SettingBtn>
                        <SettingBtn
                          className="between"
                          onClick={() => setCanvasShow(true)}
                        >
                          Language
                          <div>
                            <span>{language}</span>
                            <img src={rightArrowIcon_path} className="arrow" />
                          </div>
                        </SettingBtn>
                        <SettingBtn
                          className="between"
                          onClick={() => setSecurityPrefrences(true)}
                        >
                          Security Prefrences
                          <div>
                            <img src={rightArrowIcon_path} className="arrow" />
                          </div>
                        </SettingBtn>
                    */}
                        <SettingBtn
                          className="between"
                          onClick={() => setWorkForce(true)}
                        >
                          Invite workforce
                        </SettingBtn>

                        <div style={{ margin: '0 0 30px 0' }} />
                        <SettingBtn
                          className="between red"
                          onClick={() => {
                            toast.warn('Clearing Caches...');
                            dispatch(actionSetLastFetchedSilalImages(0));
                            dispatch(actionSetLastFetchedStoreImages(0));

                            // Wait 3 seconds then toast
                            setTimeout(() => {
                              toast.success('Caches Cleared');
                            }, 3000);
                          }}
                        >
                          Clear Caches
                          <img src={rightArrowIcon_path} className="arrow" />
                        </SettingBtn>

                        <SettingBtn
                          className="between red"
                          onClick={() => {
                            dispatch(actionLogoutStore());
                            navigate('/choose-business');
                          }}
                        >
                          Return to choose business page
                          <img src={rightArrowIcon_path} className="arrow" />
                        </SettingBtn>
                        <SettingBtn
                          className="between red"
                          onClick={() => {
                            setLogoutType(1);
                            setShowLogoutModal(true);
                          }}
                        >
                          Log out
                          <img src={rightArrowIcon_path} className="arrow" />
                        </SettingBtn>

                        <SettingBtn
                          className="between red"
                          onClick={() => {
                            setLogoutType(2);
                            setShowLogoutModal(true);
                          }}
                        >
                          Log out from all devices
                          <img src={rightArrowIcon_path} className="arrow" />
                        </SettingBtn>
                        {/* <SettingBtn
                          className="between red"
                          onClick={handleShowDelModal}
                        >
                          <div className="d-flex align-items-center">
                            <img src={deleteIcon_path} />
                            Delete account
                          </div>
                          <img src={rightArrowIcon_path} className="arrow" />
                        </SettingBtn> */}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {/* <Modal show={showDelModal} onHide={handleCloseDelModal} centered>
            <ThemeModal>
              <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text">
                  This action is irrevocable. You can’t restore your account.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex w-100">
                  <Col lg={6} className="pe-2">
                    <Button
                      className="grey w-100"
                      onClick={handleCloseDelModal}
                      text="Cancel"
                    />
                  </Col>
                  <Col lg={6} className="ps-2">
                    <Button
                      className="w-100 delete"
                      onClick={handleCloseDelModal}
                      text="Yes, delete"
                    />
                  </Col>
                </div>
              </Modal.Footer>
            </ThemeModal>
          </Modal> */}
          <Modal
            show={showLogoutModal}
            onHide={handleCloseLogoutModal}
            centered
          >
            <ThemeModal>
              <Modal.Header className="justify-content-center">
                <Modal.Title>Log out from Silal Merchant?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text text-center">
                  {
                    // eslint-disable-next-line no-nested-ternary
                    logoutType === 1
                      ? 'You will be logged out of your Silal account.'
                      : logoutType === 2
                        ? 'You will be logged out of your Silal account on all devices.'
                        : ''
                  }
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex w-100">
                  <Col lg={6} className="pe-2">
                    <Button
                      className="w-100"
                      onClick={handleCloseLogoutModal}
                      text="Cancel"
                      color="grey"
                      bg="rgb(204, 212, 214)"
                    />
                  </Col>
                  <Col lg={6} className="ps-2">
                    <Button
                      className="w-100 delete"
                      onClick={() => {
                        if (logoutType === 1) {
                          fullLogout(dispatch, navigate);
                        }
                        if (logoutType === 2) {
                          fullLogoutFromAll(dispatch, navigate);
                        }
                      }}
                      text="Log out"
                      bg={Colors.brightRed}
                      color={Colors.white}
                    />
                  </Col>
                </div>
              </Modal.Footer>
            </ThemeModal>
          </Modal>
          <LanguageCanvas
            canvasShow={canvasShow}
            setCanvasShow={setCanvasShow}
            language={language}
            setLanguage={setLanguage}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default SettingsPage;
