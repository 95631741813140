import { ImageType } from 'silal_app_base_react/src/data/types/files';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import {
  ServerException,
  ServerResponse,
} from 'silal_app_base_react/src/config/errors/server_exceptions';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { toast } from 'react-toastify';
import { FILES_MESSAGES } from 'data/constants/messages';
type UploadFileProps = {
  image_id: number;
  store_id: number;
};

export type SellerFilesResponse = {
  files: ImageType[];
  total: number;
  page: number;
  pages: number;
  per_page: number;
  http_status: number;
};
class SellerFilesApiRepository extends AuthenticatedApiRepository {
  private static instance: SellerFilesApiRepository | null = null;
  private constructor(axiosInstance: any) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: any): SellerFilesApiRepository {
    if (!SellerFilesApiRepository.instance) {
      SellerFilesApiRepository.instance = new SellerFilesApiRepository(
        axiosInstance,
      );
    }
    return SellerFilesApiRepository.instance;
  }

  getStoreImages = async (store_id: number, page = 1, limit = 20) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/files/all/paginate/?per_page=${limit}&page=${page}`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data as SellerFilesResponse;
    } catch (e) {
      return false;
    }
  };

  setImageAsLogo = async ({ image_id, store_id }: UploadFileProps) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/files/association/store_logo/${image_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              alt: `store_logo_${store_id}_${image_id}`,
              is_public: true,
            },
          },
        ),
      );
      return res.status === 202;
    } catch (e) {
      return false;
    }
  };

  setImageAsStoreCover = async ({ image_id, store_id }: UploadFileProps) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/files/association/store_cover/${image_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              alt: `store_cover_${store_id}_${image_id}`,
              is_public: true,
            },
          },
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: FILES_MESSAGES.CoverImageUpdated,
        },
      );

      return res.status === 202;
    } catch (e) {
      return false;
    }
  };

  setImageAsBusinessBankStatement = async ({
    image_id,
    store_id,
  }: UploadFileProps) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/files/association/business_bank_statement_id/${image_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              alt: `business_bank_statement_${store_id}_${image_id}`,
              is_public: false,
            },
          },
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: FILES_MESSAGES.BusinessBankStatementUpdated,
        },
      );
      return res.status === 202;
    } catch (e) {
      return false;
    }
  };

  setImageAsOwnerId = async ({ image_id, store_id }: UploadFileProps) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/files/association/store_business_owner_id_image/${image_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              alt: `store_business_owner_id_image_${store_id}_${image_id}`,
              is_public: false,
            },
          },
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: FILES_MESSAGES.OwnerIDImageUpdated,
        },
      );
      return res.status === 202;
    } catch (e) {
      return false;
    }
  };

  setImageAsTaxCertificate = async ({
    image_id,
    store_id,
  }: UploadFileProps) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/files/association/tax_certification/${image_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              alt: `tax_certification_${store_id}_${image_id}`,
              is_public: false,
            },
          },
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: FILES_MESSAGES.TaxCertificateUpdated,
        },
      );

      return res.status === 202;
    } catch (e) {
      return false;
    }
  };

  setImageAsDASCertificate = async ({
    image_id,
    store_id,
  }: UploadFileProps) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/files/association/das_certification/${image_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              alt: `das_certification_${store_id}_${image_id}`,
              is_public: false,
            },
          },
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: FILES_MESSAGES.DASCertificateUpdated,
        },
      );
      return res.status === 202;
    } catch (e) {
      return false;
    }
  };

  removeStoreImages = async ({
    images,
    store_id,
  }: {
    images: ImageType[];
    store_id: number;
  }) => {
    const removeSingleImage = async ({
      image,
      store_id,
    }: {
      image: ImageType;
      store_id: number;
    }) => {
      try {
        const res = await this.sendRequest(
          'DELETE',
          `/stores/${store_id}/files/${image.id}/`,
        );
        if (res.status !== 204) {
          throw new ServerException(res.data.response.data as ServerResponse);
        }
        return { deleted: true, id: image.id };
      } catch (e: any) {
        const baseError = e?.response?.data?.error || '';
        let fullError = baseError;
        if (e?.response?.data?.listings_associated) {
          fullError += ': ';
          fullError += e.response.data.listings_associated.join(', ');
        }

        if (e?.response?.data?.categories_using_image) {
          fullError += ': ';
          fullError += e.response.data.categories_using_image.map(
            (e: any) => e.id,
          );
        }
        return {
          deleted: false,
          id: image.id,
          error: fullError === '' ? 'Something went wrong' : fullError,
        };
      }
    };
    const requests = images?.map(async (image) =>
      removeSingleImage({
        image: image,
        store_id: store_id,
      }),
    );

    return Promise.all(requests);
  };

  addAltsToStoreImages = async ({
    images,
    store_id,
  }: {
    images: ImageType[];
    store_id: number;
  }) => {
    const updateImage = async ({ image }: { image: ImageType }) => {
      try {
        const res = await FeedbackToastHandler(
          this.sendRequest(
            'PUT',
            `/stores/${store_id}/files/${image.id}/finished_uploading/`,
            {
              baseApiUrl: API_BASE,
              data: {
                alt: image.alt,
              },
            },
          ),
          {
            customSuccessMsg: 'Image alt updated successfully',
          },
        );
        if (res.status !== 202) {
          throw new ServerException(res.data.response.data as ServerResponse);
        }
        return true;
      } catch (e: any) {
        return { ...e.response?.data, id: image.id };
      }
    };
    const requests = images?.map((image) => {
      return updateImage({ image: image });
    });
    return await Promise.all(requests);
  };

  getStoreFileById = async (store_id: number, file_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/stores/${store_id}/files/get_file/${file_id}/`,
        ),
        {
          noSuccessMsg: true,
          noPendingMsg: true,
        },
      );
      if (res.status !== 200) {
        throw new ServerException(res.data);
      }
      return res.data.file as ImageType;
    } catch (error: any) {
      toast.error(error.message);
      return false;
    }
  };
}

const SellerFilesRepository =
  SellerFilesApiRepository.getInstance(sellerAxiosInstance);

export { SellerFilesRepository };
